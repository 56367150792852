<template>
    <v-hover v-slot:default="{ hover }">
      <v-card
        tile
        outlined
        :class="hover ? 'elevation-3' : ''"
      >
        <v-card-text>
          <v-row>
            <v-col
              class="text-center"
            >
              <img
                v-if="teamMember.pic"
                :src="s3 + teamMember.pic.name"
                height="200"
                max-width="100%"
              />
              <v-icon
                v-else
                size="200"
              >
                fas fa-user
              </v-icon>
            </v-col>
          </v-row>
          <v-card-text
            class="primary--text card-title teammember-container-content"
          >
            <v-row dense>
              <v-col class="card-title">
                {{teamMember.name}}
              </v-col>
            </v-row>
            <v-row
              dense
              v-if="teamMember.phone || teamMember.email || teamMember.position"
            >
              <v-col v-if="teamMember.position" cols="12" class="item-body pretext" v-html="(teamMember.position)"></v-col>
              <v-col v-if="teamMember.email" cols="12" class="item-body pretext" v-html="(teamMember.email)"></v-col>
              <v-col v-if="teamMember.phone" cols="12" class="item-body pretext" v-html="(teamMember.phone)"></v-col>
            </v-row>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TeamMemberContainer',
  props: [
    'teamMember'
  ],
  computed: {
    ...mapGetters([
      'ac',
      's3'
    ])
  }
}
</script>

<style scoped>
  .teammember-container-content {
    box-sizing: border-box;
  }
</style>
