<template>
  <div>
    <v-container>
      <v-form v-model="formValid">
        <v-card>
          <v-card-title>
            Stimmabgabe
          </v-card-title>
          <template v-if="error">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-alert color="error" dark>
                    {{error}}
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
          <template v-else-if="alreadyVoted">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-alert color="success" dark>
                    Vielen Dank für deine Stimme!
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
          <template v-else>
            <v-card-text>
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-alert tile outlined color="primary">
                  Hallo {{computedDelegateName}},<br><br>
                  herzlich Willkommen zur nachfolgenden Abstimmung!<br><br>
                  <v-sheet
                    v-if="voting"
                    color="#eee"
                    tile
                    class="pa-3 mb-5"
                  >
                    <v-row>
                      <v-col class="title">
                        {{voting ? voting.title : ''}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-html="$sanitize(voting.description)"></v-col>
                    </v-row>
                  </v-sheet>
                  Bitte beantworte jede der unten stehenden Fragen und drücke anschließend auf abstimmen.<br>
                  Bei Fragen kannst du dich gerne jederzeit an uns wenden.<br><br>
                  Dein Stadtjugendring
                  </v-alert>
                </v-col>
              </v-row>
              <template
                v-if="voting"
              >
                <v-row
                  dense
                  v-for="(question, i) in voting.questions"
                  :key="i"
                >
                  <v-sheet
                    color="#eee"
                    width="100%"
                    class="pa-3 ma-1 mt-3"
                    tile
                  >
                    <v-col class="title" cols="12">
                    {{question.title}}
                    </v-col>
                    <v-col v-if="question.description && question.description !== ''" cols="12">
                      {{question.description}}
                    </v-col>
                    <v-col v-if="question.links && question.links.length > 0" cols="12">
                      <v-row dense>
                        <v-col
                          v-for="(link, j) in question.links"
                          :key="j"
                          cols="12">
                          <a
                            target="_blank"
                            :href="link.url">
                            <v-icon x-small color="primary" class="mr-1">fas fa-external-link-alt</v-icon>
                            {{link.text ? link.text : link.url}}
                          </a>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-radio-group
                        dense
                        color="secondary"
                        :rules="[v => v !== '' && v !== undefined || 'Bitte auswählen']"
                        v-model="votes[question._id]"
                      >
                        <v-radio
                          color="success"
                          label="Ja"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          color="error"
                          label="Nein"
                          :value="0"
                        ></v-radio>
                        <v-radio
                          color="primary"
                          label="Enthaltung"
                          :value="-1"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-sheet>
                  <v-col class="py-2" :key="i + '_divider'" v-if="!$vuetify.breakpoint.mdAndUp && i < voting.questions.length -1">
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
            <v-card-actions class="px-4 pb-4">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  block
                  :disabled="!formValid || sendingVote"
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="sendVote()"
                  :loading="sendingVote"
                >
                  Abstimmen
                </v-btn>
              </v-hover>
            </v-card-actions>
          </template>
        </v-card>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Delegates',
  data: () => ({
    voting: undefined,
    delegateCode: undefined,
    error: false,
    votes: {},
    formValid: false,
    voter: undefined,
    sendingVote: false,
    alreadyVoted: false
  }),
  mounted () {
    this.adaptParams()
  },
  methods: {
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('votings', {
      getVoting: 'get'
    }),
    ...mapActions('votes', {
      patchVotes: 'patch'
    }),
    async sendVote () {
      this.sendingVote = true
      try {
        await this.patchVotes([this.voting._id, { type: 'votes', organisation: this.voter, delegate: { code: this.delegateCode }, votes: this.votes }, {}])
        const result = await this.getVoting([this.$route.params.voting, { delegateCode: this.$route.params.delegateCode }])
        this.$set(this.voting, result)
        this.alreadyVoted = true
      } catch (e) {
        if (e.name === 'NotAcceptable') {
          this.alreadyVoted = true
        } else {
          this.error = 'Ein Fehler ist aufgetreten. Bitte lade die Seite neu und versuche es nochmals. Sollte das nicht klappen, wende dich bitte direkt an uns.'
          console.log(e)
        }
      }
      this.sendingVote = false
    },
    async adaptParams () {
      if (this.$route.params.voting && this.$route.params.delegateCode) {
        this.delegateCode = this.$route.params.delegateCode
        try {
          this.voting = await this.getVoting([this.$route.params.voting, { delegateCode: this.$route.params.delegateCode }])
          if (!this.voting) {
            this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
            return
          }
          for (const code of this.voting.codes) {
            for (const delegateCode of code.delegateCodes) {
              if (delegateCode.code === this.delegateCode) {
                if (this.voting.type === 'members') {
                  this.voter = code.organisation
                } else {
                  this.voter = {
                    name: this.voting.custom.name,
                    email: this.voting.custom.email,
                    votes: this.voting.custom.votes
                  }
                }
                if (delegateCode.vote.date) {
                  this.alreadyVoted = true
                }
              }
            }
          }
          if (this.$moment().isAfter(this.$moment(this.voting.dueDate).endOf('day'))) {
            this.error = 'Die Abstimmung ist bereits beendet.'
            return
          }
          if (!this.voter) {
            this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
            return
          }
          this.votes = {}
          for (let i = 0; i < this.voting.questions.length; i++) {
            this.votes[this.voting.questions[i]._id] = ''
          }
        } catch (e) {
          if (e.name === 'NotFound') {
            this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
          } else if (e.name === 'NotAcceptable') {
            this.alreadyVoted = true
          } else {
            console.log(e)
            this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
          }
        }
      } else {
        this.error = true
      }
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ]),
    computedDelegateName () {
      let name = ''
      if (this.voting) {
        for (const code of this.voting.codes) {
          for (const delegateCode of code.delegateCodes) {
            if (delegateCode.code === this.delegateCode) {
              name = delegateCode.name
            }
          }
        }
      }
      return name
    }
  }
}
</script>
