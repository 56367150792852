<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="birthday"
        label="Geburtsdatum"
        outlined
        dense
        color="secondary"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="[rules.required]"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="birthday"
      :active-picker.sync="activePicker"
      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
      min="1900-01-01"
      @change="save"
      color="secondary"
      first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BirthdayPicker',
  props: [
    'date'
  ],
  data () {
    return {
      activePicker: null,
      data: null,
      menu: false,
      birthday: undefined
    }
  },
  mounted () {
    this.birthday = this.date
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
      this.$emit('input', this.birthday)
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ])
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }
}
</script>
