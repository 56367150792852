<template>
  <v-container
    class="pa-0 ma-0"
  >
    <v-hover v-slot:default="{ hover }">
      <v-card
        tile
        outlined
        :class="hover ? 'elevation-3' : ''"
      >
        <v-card-text>
          <v-row
            class="mx-1 clickable"
            @click="showOrganisation(getOrganisation(news.organisation))"
          >
            <v-col
              cols="6"
              class="primary--text card-text"
              align-self="center"
            >
              {{getOrganisation(news.organisation).name}}
            </v-col>
            <v-col
              cols="6"
              class="text-right"
              align-self="center"
            >
              <img
                height="50px"
                :src="computedServerUri + '/pics/' + news.organisation + '/logo/' + getOrganisation(news.organisation).pic"
              />
            </v-col>
          </v-row>
          <v-carousel
            class="flat-carousel"
            height="auto"
            v-if="news.pics.length > 0"
            cycle
            hide-delimiter-background
            hide-delimiters
            :show-arrows="news.pics.length > 1"
            :show-arrows-on-hover="news.pics.length > 1"
          >
            <v-avatar
              v-if="news.permanent && (news.pics.length > 0)"
              color="warning"
              class="rotate permanent-avatar ml-3 mt-3 pa-3 white--text caption"
              size="60"
            >
              DAUER
              HAFT
            </v-avatar>
            <v-carousel-item
              v-for="(pic, i) in news.pics"
              :key="i"
            >
              <v-img
                :src="computedServerUri + '/pics/' + news.organisation + '/news/' + pic"
                :aspect-ratio="16/9"
              />
            </v-carousel-item>
          </v-carousel>
          <v-card-text
            class="primary--text card-title news-container-content"
          >
            <v-row
              dense
            >
              <v-col>
                <span class="card-text">{{$moment(news.updatedAt).format('DD.MM.YYYY')}}</span>&nbsp;
                <span class="card-text regular">{{$moment(news.updatedAt).format('HH:mm')}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="card-title">
                {{news.title}}
                <span class="ml-1" v-if="news.permanent && (news.pics.length === 0)" />
                <span class="warning--text" v-if="news.permanent && (news.pics.length === 0)">DAUERHAFT</span>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col cols="12" class="item-body pretext" v-html="$sanitize(news.info)"></v-col>
              <v-col cols="12" v-if="news.link && news.link !== ''" class="item-body pretext"><a target="_blank" :href="news.link">{{news.link}}</a></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            class="my-0"
            v-if="
              $route.name === 'offers' &&
              user &&
              ac &&
              (
                ac.can(user.role).updateAny('offer').granted ||
                (
                  ac.can(user.role).updateOwn('offer').granted &&
                  user.organisation === news.organisation
                )
              )
            "
          >
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              icon
              @click="$router.push($route.path + '/editor/' +  news._id)"
            >
              <v-icon small>fas fa-edit</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NewsContainer',
  props: [
    'news'
  ],
  methods: {
    showOrganisation (organisation) {
      if (organisation.type === 'member' || organisation.type === 'institution') {
        this.$router.push('/mitglieder/' + organisation._id)
      } else if (organisation.link) {
        window.open(organisation.link, '_newtab')
      }
    }
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('organisations', {
      getOrganisation: 'get'
    }),
    ...mapGetters('auth', {
      user: 'user'
    }),
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    }
  }
}
</script>

<style scoped>
  .news-container-content {
    box-sizing: border-box;
  }
</style>
