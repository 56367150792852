<template>
  <div>
    <v-card
      color="lightAccent"
      class="mb-5"
    >
      <v-card-text>
        <v-toolbar
          flat
          color="transparent"
          dense
          class="mt-0 mb-6"
        >
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('closePartnerEditor')"
            icon
          >
            <v-icon>
              fas fa-times
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="partnerEditorForm" v-model="partnerFormValid">
          <v-row>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpPartner.name"
                outlined
                dense
                label="Name"
                :rules="[rules.longText, rules.required]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpPartner.link"
                outlined
                dense
                label="Link"
                :rules="[rules.longText]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="py-0" cols="12"
            >
              Logo
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12">
              <PicUpload
                ref="partnerPicUpload"
                v-model="tmpPartner.pic"
                @removedUploadedFile="removedUploadedFile()"
                @fileAdd="$nextTick(() => { $refs.partnerEditorForm.validate() })"
                :acceptedMimeTypes="['image/png', 'image/jpg', 'image/jpeg']"
                :maxFileSize="0.5"
                :maxFiles="1"
                bgColor="lightAccent"
                :scaleToFit="[400, 400]"
                :resizeQuality="75"
              ></PicUpload>
            </v-col>
          </v-row>
        </v-form>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="$emit('removePartner')"
            >
              ENTFERNEN
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!partnerFormValid"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="$emit('addPartner', tmpPartner)"
            >
              {{ tmpPartner._id ? 'ÜBERNEHMEN': 'HINZUFÜGEN' }}
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PicUpload from './PicUpload.vue'

export default {
  name: 'PartnerEditor',
  props: {
    value: {}
  },
  components: {
    PicUpload
  },
  data () {
    return {
      partnerFormValid: false,
      tmpPartner: {}
    }
  },
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions([
      'showSnackbar'
    ]),
    removedUploadedFile () {
      this.$emit('removeUploadedPartnerPic')
    },
    adapt () {
      if (this.value) {
        this.tmpPartner = JSON.parse(JSON.stringify(this.value))
      }
    },
    async upload () {
      await this.$refs.partnerPicUpload.upload()
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ])
  },
  watch: {
    value () {
      this.adapt()
    },
    tmpPartner: {
      deep: true,
      handler () {
        if (
          this.tmpPartner?.pic?.name !== this.value?.pic?.name ||
          this.tmpPartner?.name !== this.value?.name ||
          this.tmpPartner?.phone !== this.value?.phone ||
          this.tmpPartner?.email !== this.value?.email ||
          this.tmpPartner?.position !== this.value?.position
        ) {
          this.tmpPartner.isDirty = true
        } else {
          delete this.tmpPartner.isDirty
        }
      }
    }
  }
}
</script>
