<template>
  <div>
    <vue-headful
      title="SJR Ulm - Veranstaltungen"
      description="Hier findet ihr alle Veranstaltungen des Stadtjugendrings Ulm e.V., seiner Einrichtungen und seiner Mitglieder."
    />
    <v-toolbar
      flat
      color="#f5f7fa"
    >
      <span
        class="section-title primary--text"
        cols="6"
      >
        VERANSTALTUNGEN
      </span>
    </v-toolbar>
    <!-- Filters -->
    <v-container fluid>
      <v-row dense>
        <v-col class="text-right">
          <v-btn
            text
            :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
            @click="showFilters = true"
          >
            FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
          </v-btn>
          <v-btn
            v-if="computedFiltersActive > 0"
            icon
            color="secondary"
            @click="resetFilters()"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            label="Suche nach SjR Einrichtung(en)"
            multiple
            dense
            v-model="filterOrganisations"
            deletable-chips
            chips
            color="secondary"
            item-color="secondary"
            :items="computedInstitutions"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense class="mb-3">
        <v-col cols="12">
          <v-text-field
            label="Suche nach Veranstaltungsname"
            :color="search === '' ? 'secondary' : 'primary'"
            class="text-field-search"
            hide-details
            v-model="search"
            clearable
            dense
          >
            <template slot="append">
              <v-icon
                v-if="search === ''"
                class="m-3"
                small
                color="secondary"
              >
                fa-search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div ref="eventsEditorContainer">
      <EventEditor
        ref="eventsEditor"
        :event="getEvent($route.params.id)"
        :showEditor="showEditor"
        v-show="
          user &&
          ac &&
          (
            ac.can(user.role).updateAny('event').granted ||
            ac.can(user.role).updateOwn('event').granted
          )
        "
      />
    </div>
    <div ref="events"></div>
    <template
      v-if="mode === 'grid'"
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col
            v-for="(event, i) in paginatedEvents"
            :key="i"
            cols="12"
            md="4"
            sm="6"
          >
            <EventContainer
              :event="event"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-container fluid>
      <v-row v-if="computedTotal === 0">
        <v-col  class="info--text text-center pa-0" cols="12">
          Keine Einträge vorhanden
        </v-col>
      </v-row>
    <v-row v-if="computedTotal > 0">
      <v-col class="pa-0" algin-self="center" cols="12" sm="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <v-btn
            v-if="skip > 0"
            tile
            text
            depressed
            :color="hover ? 'secondary' : 'info'"
            class="white--text"
            @click="nextPage()"
          >
            <v-icon small class="mr-3">fas fa-chevron-left</v-icon>
            Aktuellere anzeigen
          </v-btn>
        </v-hover>
      </v-col>
      <v-col align-self="center" class="info--text text-center pa-0" cols="12" sm="4">
        <template v-if="skip+limit <= computedTotal">
        {{skip+1}}-{{skip+limit}} von {{computedTotal}}
        </template>
        <template v-else>
          {{skip+1}}-{{computedTotal}} von {{computedTotal}}
        </template>
      </v-col>
      <v-col align-self="center" class="text-right pa-0" cols="12" sm="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <v-btn
            v-if="(skip + limit) < computedTotal"
            tile
            text
            depressed
            :color="hover ? 'secondary' : 'info'"
            class="white--text"
            @click="previousPage()"
          >
            Zukünftigere anzeigen
            <v-icon small class="ml-3">fas fa-chevron-right</v-icon>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
    </v-container>
    <v-dialog
      v-if="
        ac &&
        user &&
        (
          $route.params.id === 'neu' &&
          (
            ac.can(user.role).createAny('event') ||
            ac.can(user.role).createOwn('event')
          )
        ) ||
        (
          $route.params.id && $route.params.id !== 'neu' &&
          (
            ac.can(user.role).updateAny('event') ||
            (
              ac.can(user.role).updateOwn('event') &&
              getEvent($route.params.id).organisation === user.organisation
            )
          )
        )
      "
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showEditorDialog"
      fullscreen
    >
      <EventEditor
        :event="getEvent($route.params.id)"
        :showEditor="showEditorDialog"
      />
    </v-dialog>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12">
              <div class="body-1">
                Zielgruppen-Alter
              </div>
              <v-range-slider
                v-model="filterAgeRange"
                :max="99"
                :min="0"
                hide-details
                class="align-center"
                color="secondary"
                track-color="info"
              >
                <template v-slot:prepend>
                  <v-text-field
                    v-model="filterAgeRange[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 40px"
                    color="secondary"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    v-model="filterAgeRange[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 40px"
                    color="secondary"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-dialog
                ref="filterDatesDialog"
                v-model="showFilterDatesDialog"
                :return-value.sync="filterDates"
                persistent
                width="320px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    color="secondary"
                    :value="computedFilterDates"
                    @click:clear="filterDates = []"
                    label="Zeitraum"
                    append-icon="fas fa-calendar-day"
                    readonly
                    id="filterDatesInput"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-if="showFilterDatesDialog"
                  v-model="filterDates"
                  full-width
                  format="24hr"
                  :min="$moment().format('YYYY-MM-DD')"
                  color="secondary"
                  range
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-hover
                    v-slot:default="{ hover }"
                  >
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="showFilterDatesDialog = false"
                    >
                      Abbrechen
                    </v-btn>
                  </v-hover>
                  <v-hover
                    v-slot:default="{ hover }"
                  >
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="$refs.filterDatesDialog.save(filterDates)"
                    >
                      OK
                    </v-btn>
                  </v-hover>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Kategorie"
                multiple
                v-model="filterCategories"
                clearable
                color="secondary"
                item-color="secondary"
                :items="eventCategories"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Organisation(en)"
                multiple
                v-model="filterOrganisations"
                chips
                deletable-chips
                color="secondary"
                item-color="secondary"
                :items="computedOrganisations"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                color="secondary"
                label="Barrierefrei"
                v-model="filterAccessible"
              >
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                color="secondary"
                label="Bildungsangebot"
                v-model="filterEducational"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <template v-if="user">
          <v-row>
            <v-col cols="12">
              <div class="body-1">Veranstaltungen intern / öffentlich</div>
              <v-radio-group
                v-model="filterInternal"
              >
                <v-radio
                  color="secondary"
                  label="Alle anzeigen"
                  :value="0"
                ></v-radio>
                <v-radio
                  color="secondary"
                  label="Nur interne"
                  :value="1"
                ></v-radio>
                <v-radio
                  color="secondary"
                  label="Nur öffentliche"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="body-1">Veranstaltungen alle / eigene</div>
              <v-checkbox
                color="secondary"
                label="Nur eigene anzeigen"
                v-model="filterOwn"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="body-1">Vergangene Veranstaltungen anzeigen</div>
              <v-checkbox
                color="secondary"
                label="Auch vergangene anzeigen"
                v-model="filterShowPast"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import EventEditor from '@/components/EventEditor'
import EventContainer from '@/components/EventContainer'

export default {
  name: 'Events',
  components: {
    EventEditor,
    EventContainer
  },
  mixins: [makeFindMixin({ service: 'events', watch: true })],
  data () {
    return {
      limit: 6,
      skip: 0,
      showEditor: false,
      showEditorDialog: false,
      mode: 'grid',
      search: '',
      showFilters: false,
      showFilterDatesDialog: false,
      filterDates: [],
      filterCategories: [],
      filterAgeRange: [0, 99],
      filterOrganisations: [],
      filterInstitutions: [],
      filterAccessible: false,
      filterEducational: false,
      filterInternal: 0,
      filterOwn: false,
      filterShowPast: false
    }
  },
  mounted () {
    if (this.$route.query.s) {
      this.skip = parseInt(this.$route.query.s)
    }
    if (this.$route.query.organisation) {
      const queryIds = this.$route.query.organisation.split(',')
      const queryOrganistions = this.organisations.filter(o => queryIds.includes(o._id) || queryIds.includes(o.slug))
      if (queryOrganistions) {
        this.filterOrganisations = this.filterOrganisations.concat(queryOrganistions.map(o => o._id))
      }
    }
    if (localStorage.getItem('eventsMode')) {
      this.mode = localStorage.getItem('eventsMode')
    } else {
      localStorage.setItem('eventsMode', this.mode)
    }
    this.adaptParams()
  },
  methods: {
    ...mapActions('events', {
      requestEvent: 'get'
    }),
    resetFilters () {
      this.filterDates = []
      this.filterCategories = []
      this.filterAgeRange = [0, 99]
      this.filterOrganisations = []
      this.filterAccessible = false
      this.filterEducational = false
      this.filterInternal = 0
      this.filterOwn = false
    },
    async adaptParams () {
      if (this.$route.params.id) {
        if (this.$route.params.id !== 'neu') {
          let tmpEvent = this.getEvent(this.$route.params.id)
          if (!tmpEvent) {
            tmpEvent = await this.requestEvent(this.$route.params.id)
          }
          this.openEditor(tmpEvent)
        } else {
          this.openEditor()
        }
      } else {
        this.closeEditor()
      }
    },
    previousPage () {
      this.skip = this.skip + this.limit
    },
    nextPage () {
      this.skip = this.skip - this.limit
    },
    openEditor (event) {
      if (this.mode === 'list') {
        if (event) {
          this.$refs[event._id][0].$el.parentNode.insertBefore(this.$refs.eventsEditor.$el, this.$refs[event._id][0].$el.nextSibling)
          setTimeout(() => {
            this.$vuetify.goTo(this.$refs.eventsEditor.$el, { offset: 60 })
            this.showEditor = true
          }, 100)
        } else {
          this.showEditor = true
        }
      } else {
        this.showEditorDialog = true
      }
    },
    closeEditor () {
      this.$refs.eventsEditorContainer.parentNode.insertBefore(this.$refs.eventsEditor.$el, this.$refs.eventsEditorContainer.nextSibling)
      this.showEditor = false
      this.showEditorDialog = false
    }
  },
  computed: {
    eventsParams () {
      if (this.filterShowPast) {
        return {
          paginate: false
        }
      } else {
        return {
          query: {
            $or: [
              { 'date.end': { $gte: this.$moment().subtract(1, 'days').toISOString() } },
              { 'interval.end': { $gte: this.$moment().subtract(1, 'days').toISOString() } }
            ]
          },
          paginate: false
        }
      }
    },
    ...mapGetters([
      'ac',
      'eventCategories'
    ]),
    ...mapGetters('events', {
      getEvent: 'get',
      events: 'list'
    }),
    ...mapGetters('organisations', {
      getOrganisation: 'get',
      organisations: 'list'
    }),
    ...mapGetters('auth', {
      user: 'user'
    }),
    computedEvents () {
      const tmpEvents = this.$sortByProperty(this.$allEvents(this.events).filter(obj => {
        return (!this.search || obj.title.toLowerCase().includes(this.search.toLowerCase())) &&
        (this.filterDates.length === 0 || this.$moment(obj.date.start).isAfter(this.$moment(this.filterDates[0]))) &&
        (this.filterDates.length <= 1 || this.$moment(obj.date.start).isBefore(this.$moment(this.filterDates[1]))) &&
        (obj.age.until >= this.filterAgeRange[0] && obj.age.from <= this.filterAgeRange[1]) &&
        (this.filterOrganisations.length === 0 || this.filterOrganisations.includes(obj.organisation)) &&
        (this.filterCategories.length === 0 || this.filterCategories.some(r => obj.categories.indexOf(r) >= 0)) &&
        (!this.filterAccessible || obj.accessible) &&
        (!this.filterEducational || obj.educational) &&
        (this.filterInternal === 0 || (this.filterInternal === 1 && obj.internal === true) || (this.filterInternal === 2 && obj.internal === false)) &&
        (!this.filterOwn || !this.user || obj.organisation === this.user.organisation)
      }), 'date.start')
      if (this.filterShowPast) {
        return tmpEvents
      } else {
        return tmpEvents.filter((obj) => this.$moment(obj.date.end).isAfter(this.$moment().subtract(1, 'days').toISOString()))
      }
    },
    paginatedEvents () {
      return this.computedEvents.slice(this.skip, this.limit * ((this.skip / this.limit) + 1))
    },
    computedFilterDates () {
      const tmpString = this.filterDates.map(obj => this.$moment(obj).format('DD.MM.YYYY'))
      return tmpString.join(' bis ')
    },
    computedOrganisations () {
      return this.organisations.filter(obj => obj.isActive).map(obj => { return { value: obj._id, text: obj.name } })
    },
    computedInstitutions () {
      return this.organisations.filter(obj => obj.type !== 'member' && obj.isActive).map(obj => { return { value: obj._id, text: obj.name } })
    },
    computedFiltersActive () {
      let count = 0
      if (this.filterAgeRange[0] !== 0 || this.filterAgeRange[1] !== 99) { ++count }
      if (this.filterCategories.length !== 0) { ++count }
      if (this.filterOrganisations.length !== 0) { ++count }
      if (this.filterDates.length !== 0) { ++count }
      if (this.filterAccessible) { ++count }
      if (this.filterEducational) { ++count }
      if (this.filterInternal !== 0) { ++count }
      if (this.filterOwn) { ++count }
      return count
    },
    computedTotal () {
      try {
        return this.computedEvents.length
      } catch (e) {
        return 0
      }
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    mode () {
      localStorage.setItem('eventsMode', this.mode)
    },
    skip () {
      if (parseInt(this.$route.query.s) !== this.skip) {
        this.$router.replace({ query: { s: this.skip } })
      }
    },
    filterDates () {
      if (this.filterDates.length === 2) {
        if (this.$moment(this.filterDates[0]).isAfter(this.$moment(this.filterDates[1]))) {
          const tmpDate = this.filterDates[0]
          this.filterDates[0] = this.filterDates[1]
          this.filterDates[1] = tmpDate
        }
      }
    },
    user () {
      this.findEvents()
    },
    filterOrganisations () {
      if (this.filterOrganisations.length === 0) {
        const query = JSON.parse(JSON.stringify(this.$route.query))
        delete query.organisation
        this.$router.replace({ query: { ...query } })
      } else {
        const newQuery = { ...this.$router.query, organisation: this.filterOrganisations.join(',') }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
          this.$router.replace({ query: newQuery })
        }
      }
    }
  }
}
</script>
