<template>
  <v-sheet tile color="primary">
    <v-form ref="micrositeForm" v-model="formValid">
      <v-card flat tile>
        <v-card-text>
          <v-row class="mb-5">
            <v-col class="primary--text section-title">
              Organisations-Profil
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col class="primary--text section-sub-title">
              Hauptinformationen
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-6">
              <v-radio-group v-model="hideName">
                <v-radio
                  label="Name und Logo anzeigen"
                  color="secondary"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Nur Logo anzeigen"
                  color="secondary"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-6">
              <tiptap-vuetify
                placeholder="Text"
                v-model="text"
                label="Organisations-Beschreibung"
                :card-props="{ tile: true, flat: true, outlined: true }"
                :extensions="extensions"
              >
              </tiptap-vuetify>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-6">
              <tiptap-vuetify
                placeholder="&quot;Stellenauschschreibung&quot; für neue Mitglieder - Das kannst du bei uns tun"
                v-model="candidature"
                label="Stellenausschreibung"
                :card-props="{ tile: true, flat: true, outlined: true }"
                :extensions="extensions"
              >
              </tiptap-vuetify>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="founded"
                outlined
                dense
                label="Gründungsjahr"
                :rules="[rules.shortText]"
                color="secondary"
                class="optional-field"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col class="primary--text section-sub-title">
              {{ organisation.type === 'member' || organisation.type === 'cooperation' ? "Vorstand": "Leitung" }}
            </v-col>
          </v-row>
          <v-row>
            <template
              v-for="(member, i) in board"
            >
              <v-col :key="i+'desc'" class="py-2 body-1" align-self="center" cols="12" sm="6">
                {{member.firstName}}
              </v-col>
              <v-col :key="i+'url'" class="py-2 body-1" align-self="center" cols="12" sm="5">
                {{member.lastName}}
              </v-col>
              <v-col :key="i" class="py-1 text-center" align-self="center" cols="12" sm="1">
                <v-btn
                  icon
                  color="secondary"
                  @click="removeMember(i)"
                >
                  <v-icon
                    small
                  >
                    fas fa-trash
                  </v-icon>
                </v-btn>
              </v-col>
              <div class="divider" :key="i+'divider'"></div>
            </template>
          </v-row>
          <v-row>
            <v-col class="py-4 pb-0" cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Vorname"
                v-model="memberFirstname"
                :rules="[rules.shortText]"
                color="secondary"
                class="optional-field"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-4 pb-0" cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Nachname"
                v-model="memberLastname"
                :rules="[rules.shortText]"
                color="secondary"
                class="optional-field"
              >
              </v-text-field>
            </v-col>
            <div class="divider" :key="i+'divider'"></div>
            <v-col class="pt-5" cols="12">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :disabled="!memberFirstname || !memberLastname"
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="addMember()"
                >
                  Weitere
                  <v-icon
                    class="ml-3"
                    small
                  >
                    fas fa-plus
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col class="primary--text section-sub-title">
              Titelbilder
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Dropzone
                :serverUri="computedServerUri + '/uploads/'"
                uploadFilePath="temporary"
                maxFiles=20
                resizeWidth=1280
                resizeHeight=720
                maxFileSize=2
                acceptedMimeTypes='image/png, image/jpeg'
                addFileMessage='Titelbilder'
                :convertFileName="true"
                :existingFiles="computedExistingFiles"
                :filesUploadedCallback="fileUploaded"
                :fileRemovedCallback="fileRemoved"
              />
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col class="primary--text section-sub-title">
              Kontakt
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Ansprechpartner Vorname"
                v-model="contactFirstname"
                :rules="[rules.required, rules.shortText]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Ansprechpartner Nachname"
                v-model="contactLastname"
                :rules="[rules.required, rules.shortText]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12" sm="6">
              <v-text-field
                color="secondary"
                outlined
                dense
                label="Telefon"
                v-model="contactPhone"
                :rules="[rules.shortText]"
                class="optional-field"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <v-text-field
                color="secondary"
                outlined
                dense
                label="Email"
                v-model="contactEmail"
                :rules="[rules.required, rules.shortText, rules.email]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12" sm="6">
              <v-text-field
                color="secondary"
                outlined
                dense
                label="Fax"
                v-model="contactFax"
                :rules="[rules.shortText]"
                class="optional-field"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            class="my-5"
          >
            <v-col class="primary--text section-sub-title">
              Online-Kanäle
            </v-col>
          </v-row>
          <v-row>
            <template
              v-for="(channel, i) in channels"
            >
              <v-col :key="i+'desc'" align-self="center" class="py-2 body-1" cols="12" sm="3">
                {{channel.type ? channelTypes.find(t => t.value === channel.type).text : 'Link'}}
              </v-col>
              <v-col :key="i+'desc'" align-self="center" class="py-2 body-1" cols="12" sm="3">
                <template v-if="channel.type === 'icon'">
                  <v-icon
                    size="20"
                  >
                    {{channel.description}}
                  </v-icon>
                </template>
                <template
                  v-else
                >
                  {{channel.description}}
                </template>
              </v-col>
              <v-col :key="i+'url'" align-self="center" class="py-2 body-1" cols="12" sm="5">
                {{channel.url}}
              </v-col>
              <v-col :key="i" class="py-2 text-center" cols="12" sm="1">
                <v-btn
                  small
                  icon
                  color="secondary"
                  @click="removeChannel(i)"
                >
                  <v-icon
                    small
                  >
                    fas fa-trash
                  </v-icon>
                </v-btn>
              </v-col>
              <div class="divider" :key="i+'divider'"></div>
            </template>
          </v-row>
          <v-row>
            <v-col class="py-4 pb-0" cols="12" sm="3">
              <v-select
                outlined
                dense
                label="Typ"
                v-model="channelType"
                color="secondary"
                :items="channelTypes"
              >
              </v-select>
            </v-col>
            <v-col class="py-4 pb-0" cols="12" sm="3">
              <v-text-field
                v-if="channelType === 'link'"
                outlined
                dense
                label="Bezeichnung des Links (z.B. Webseite)"
                v-model="channelDescription"
                :rules="[rules.shortText]"
                color="secondary"
              >
              </v-text-field>
              <v-select
                v-else-if="channelType === 'icon'"
                outlined
                dense
                label="Icon"
                v-model="channelDescription"
                :rules="[rules.shortText]"
                color="secondary"
                :items="[
                  { text: 'Globus', value: 'fa fa-globe' },
                  { text: 'Mail', value: 'fa fa-envelope' },
                  { text: 'Facebook', value: 'fab fa-facebook' },
                  { text: 'Instagram', value: 'fab fa-instagram' },
                  { text: 'Twitter', value: 'fab fa-twitter' },
                  { text: 'Tiktok', value: 'fab fa-tiktok' },
                  { text: 'Discord', value: 'fab fa-discord' },
                  { text: 'Vimeo', value: 'fab fa-vimeo' },
                  { text: 'Youtube', value: 'fab fa-youtube' },
                  { text: 'Telegram', value: 'fab fa-telegram' },
                  { text: 'Spotify', value: 'fab fa-spotify' },

                ]"
              >
                <template v-slot:item="{ item }">
                  <v-icon
                    size="22"
                    color="secondary"
                    class="mr-3 mb-1"
                  >
                    {{ item.value }}
                  </v-icon>
                   {{ item.text }}
                </template>
              </v-select>
            </v-col>
            <v-col class="py-4 pb-0" cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Link"
                v-model="channelUrl"
                :rules="[rules.shortText]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
            <div class="divider" :key="i+'divider'"></div>
            <v-col class="pt-5" align-self="center" cols="12">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :disabled="!channelDescription || !channelUrl || !channelType"
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="addChannel()"
                >
                  Weitere
                  <v-icon
                    class="ml-3"
                    small
                  >
                    fas fa-plus
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <v-row class="mt-5 mb-3">
            <v-col class="primary--text section-sub-title">
              Tabs
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-4 pb-0" cols="12">
              <v-select
                outlined
                dense
                label="Aktive Tabs"
                v-model="tabs"
                multiple
                chips
                deletable-chips
                color="secondary"
                :items="tabNames"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mt-5 mb-3">
            <v-col class="primary--text section-sub-title">
              Team
            </v-col>
          </v-row>
          <v-row
            v-if="activeTeamMemberIndex === undefined ? team.length > 0 : team.length > 1"
          >
            <v-col
              class="my-0"
            >
              <v-list
                three-line
              >
                <template
                  v-for="(teamMember, i) in team"
                >
                  <div
                    :key="i"
                  >
                    <v-list-item
                      v-if="i !== activeTeamMemberIndex"
                    >
                      <v-list-item-avatar
                        size="100"
                        class="mr-12"
                      >
                        <template
                          v-if="teamMember.pic"
                        >
                          <v-img
                            :src="teamMember.pic.uri ? teamMember.pic.uri : s3 + teamMember.pic.name"
                          ></v-img>
                        </template>
                        <template
                          v-else
                        >
                          <v-icon
                            size="100"
                          >fas fa-user</v-icon>
                        </template>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{teamMember.name}} {{teamMember._id}}
                          <template
                            v-if="
                              !teamMember._id ||
                              teamMember?.pic?.name !== microsite.team[i]?.pic?.name ||
                              teamMember?.name !== microsite.team[i]?.name ||
                              teamMember?.phone !== microsite.team[i]?.phone ||
                              teamMember?.email !== microsite.team[i]?.email ||
                              teamMember?.position !== microsite.team[i]?.position
                            "
                          >
                            <v-chip
                              class="ml-3"
                              color="warning"
                            >
                              Noch nicht gespeichert
                            </v-chip>
                          </template>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{teamMember.email}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{teamMember.phone}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{teamMember.position}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action
                        v-if="i !== activeTeamMemberIndex"
                      >
                        <v-btn
                          icon
                          @click="activeTeamMemberIndex = i"
                        >
                          <v-icon
                            color="secondary"
                            small
                          >
                            fas fa-edit
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <team-member-editor
            ref="teamMemberEditor"
            v-show="activeTeamMemberIndex !== undefined"
            v-model="team[activeTeamMemberIndex]"
            @addTeamMember="addTeamMember"
            @removeTeamMember="removeTeamMember"
            @removeUploadedTeamMemberPic="removeUploadedTeamMemberPic"
            @closeTeamMemberEditor="closeTeamMemberEditor"
          ></team-member-editor>
          <v-row
            v-show="activeTeamMemberIndex === undefined"
            class="mt-3 mb-8"
          >
            <v-col
              class="py-0"
            >
              <v-btn
                @click="() => { team.push({}); activeTeamMemberIndex = team.length - 1 }"
              >
                TEAM-MITGLIED HINZUFÜGEN
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-5 mb-3">
            <v-col class="primary--text section-sub-title">
              Partner
            </v-col>
          </v-row>
          <v-row
            v-if="activePartnerIndex === undefined ? partners.length > 0 : partners.length > 1"
          >
            <v-col
              class="my-0"
            >
              <v-list
                two-line
              >
                <template
                  v-for="(partner, i) in partners"
                >
                  <div
                    :key="i"
                  >
                    <v-list-item
                      v-if="i !== activePartnerIndex"
                    >
                      <v-list-item-avatar
                        size="100"
                        class="mr-12"
                      >
                        <template
                          v-if="partner.pic"
                        >
                          <v-img
                            :src="partner.pic.uri ? partner.pic.uri : s3 + partner.pic.name"
                          ></v-img>
                        </template>
                        <template
                          v-else
                        >
                          <v-icon
                            size="100"
                          >fas fa-handshake</v-icon>
                        </template>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{partner.name}}
                          <template
                            v-if="
                              !partner._id ||
                              partner?.pic?.name !== microsite.partners[i]?.pic?.name ||
                              partner?.name !== microsite.partners[i]?.name ||
                              partner?.link !== microsite.partners[i]?.link
                            "
                          >
                            <v-chip
                              class="ml-3"
                              color="warning"
                            >
                              Noch nicht gespeichert
                            </v-chip>
                          </template>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{partner.link}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action
                        v-if="i !== activePartnerIndex"
                      >
                        <v-btn
                          icon
                          @click="activePartnerIndex = i"
                        >
                          <v-icon
                            color="secondary"
                            small
                          >
                            fas fa-edit
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <partner-editor
            ref="partnerEditor"
            v-show="activePartnerIndex !== undefined"
            v-model="partners[activePartnerIndex]"
            @addPartner="addPartner"
            @removePartner="removePartner"
            @removeUploadedPartnerPic="removeUploadedPartnerPic"
            @closePartnerEditor="closePartnerEditor"
          ></partner-editor>
          <v-row
            v-show="activePartnerIndex === undefined"
            class="mt-3 mb-8"
          >
            <v-col
              class="py-0"
            >
              <v-btn
                @click="() => { partners.push({}); activePartnerIndex = partners.length - 1 }"
              >
                PARTNER HINZUFÜGEN
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-5 mb-3">
            <v-col class="primary--text section-sub-title">
              Individueller Tab
            </v-col>
          </v-row>
          <v-col cols="12" class="px-0">
            <v-text-field
              v-model="custom.title"
              outlined
              dense
              label="Tab-Name"
              :rules="[rules.shortText]"
              color="secondary"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="px-0 mb-6">
            <tiptap-vuetify
              placeholder="Tab-Inhalt"
              v-model="custom.text"
              label="Individueller-Tab"
              :card-props="{ tile: true, flat: true, outlined: true }"
              :extensions="extensions"
            >
            </tiptap-vuetify>
          </v-col>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-hover
            v-if="microsite"
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'error' : 'info'"
              class="white--text"
              @click="showDeleteDialog = true"
            >
              Löschen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!formValid || processingSave || text === '' || text === '<p></p>'"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              :loading="processingSave"
              @click="saveMicrosite()"
            >
              Speichern
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="showDeleteDialog" max-width="350px">
      <v-card>
        <v-card-title>Organisations-Profil löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showDeleteDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="processingDelete"
              :color="hover ? 'error' : 'info'"
              class="white--text"
              :loading="processingDelete"
              @click="deleteMicrosite()"
            >
              Löschen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PicUpload
      v-show="false"
      ref="collectedTeamMemberUpload"
      :value="team.map(m => m.pic).filter(i => i)"
      :acceptedMimeTypes="['image/png', 'image/jpg', 'image/jpeg']"
      :maxFileSize="0.5"
      :maxFiles="1000"
    ></PicUpload>
    <PicUpload
      v-show="false"
      ref="collectedPartnerUpload"
      :value="partners.map(m => m.pic).filter(i => i)"
      :acceptedMimeTypes="['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml']"
      :maxFileSize="0.5"
      :maxFiles="1000"
    ></PicUpload>
  </v-sheet>
</template>

<script>
import feathersClient from '@/feathers-client'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from './Dropzone'
import PicUpload from '@/components/PicUpload'
import TeamMemberEditor from '@/components/TeamMemberEditor'
import PartnerEditor from '@/components/PartnerEditor'
import { v4 as uuidv4 } from 'uuid'
import { TiptapVuetify, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
  name: 'MicrositeEditor',
  props: [
    'organisation'
  ],
  components: {
    Dropzone,
    TiptapVuetify,
    TeamMemberEditor,
    PartnerEditor,
    PicUpload
  },
  data () {
    return {
      tabs: [],
      team: [],
      partners: [],
      custom: {},
      activeTeamMemberIndex: undefined,
      activePartnerIndex: undefined,
      ts: undefined,
      pics: [],
      hideName: false,
      picsToUpload: [],
      uploadService: undefined,
      formValid: false,
      microsite: undefined,
      channelType: '',
      channelDescription: '',
      channelUrl: '',
      memberFirstname: '',
      memberLastname: '',
      text: '',
      candidature: '',
      contactEmail: '',
      contactPhone: '',
      contactFax: '',
      contactFirstname: '',
      contactLastname: '',
      channels: [],
      founded: '',
      board: [],
      processingSave: false,
      processingDelete: false,
      showDeleteDialog: false,
      channelTypes: [{ text: 'Icon', value: 'icon' }, { text: 'Link', value: 'link' }],
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList
      ]
    }
  },
  mounted () {
    this.ts = Date.now()
    this.uploadService = feathersClient.service('uploads')
    this.loadMicrosite()
  },
  methods: {
    ...mapActions('microsites', {
      createMicrosite: 'create',
      patchMicrosite: 'patch',
      removeMicrosite: 'remove',
      requestMicrosite: 'get'
    }),
    ...mapActions('uploads3', {
      removeUpload: 'remove'
    }),
    ...mapActions('organisations', {
      patchOrganisation: 'patch'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    closeTeamMemberEditor () {
      if (!this.team[this.activeTeamMemberIndex]._id) {
        this.removeTeamMember()
      } else {
        this.activeTeamMemberIndex = undefined
      }
    },
    addTeamMember (teamMember) {
      this.$set(this.team, this.activeTeamMemberIndex, teamMember)
      this.activeTeamMemberIndex = undefined
    },
    removeTeamMember () {
      this.team.splice(this.activeTeamMemberIndex, 1)
      this.activeTeamMemberIndex = undefined
    },
    async removeUploadedTeamMemberPic () {
      try {
        const tmpDirtyTeam = JSON.parse(JSON.stringify(this.team.filter(m => m._id && m.isDirty)))
        this.microsite = await this.patchMicrosite([this.microsite._id, {
          ['team.' + this.activeTeamMemberIndex + '.pic']: null
        }])
        this.adapt()
        for (const member of tmpDirtyTeam) {
          const index = this.team.findIndex(m => m._id === member._id)
          this.team[index] = member
        }
      } catch (e) {
        this.showSnackbar({ mode: 'save', success: false })
        return
      }
      this.showSnackbar({ mode: 'save', success: true })
    },
    closePartnerEditor () {
      if (!this.partners[this.activePartnerIndex]._id) {
        this.removePartner()
      } else {
        this.activePartnerIndex = undefined
      }
    },
    addPartner (partner) {
      this.$set(this.partners, this.activePartnerIndex, partner)
      this.activePartnerIndex = undefined
    },
    removePartner () {
      this.partners.splice(this.activePartnerIndex, 1)
      this.activePartnerIndex = undefined
    },
    async removeUploadedPartnerPic () {
      try {
        const tmpDirtyPartners = JSON.parse(JSON.stringify(this.partners.filter(p => p._id && p.isDirty)))
        this.microsite = await this.patchMicrosite([this.microsite._id, {
          ['partners.' + this.activePartnerIndex + '.pic']: null
        }])
        this.adapt()
        for (const partner of tmpDirtyPartners) {
          const index = this.partners.findIndex(p => p._id === partner._id)
          this.partners[index] = partner
        }
      } catch (e) {
        this.showSnackbar({ mode: 'save', success: false })
        return
      }
      this.showSnackbar({ mode: 'save', success: true })
    },
    adapt () {
      if (this.microsite) {
        this.text = this.microsite.text
        this.hideName = this.microsite.hideName || false
        this.candidature = this.microsite.candidature
        this.contactEmail = this.microsite.contact.email
        this.contactPhone = this.microsite.contact.phone
        this.contactFax = this.microsite.contact.fax
        this.contactFirstname = this.microsite.contact.firstName
        this.contactLastname = this.microsite.contact.lastName
        this.channels = this.microsite.channels
        this.founded = this.microsite.founded
        this.board = this.microsite.board

        if (this.microsite.custom) {
          this.custom = this.microsite.custom
        }

        if (this.microsite.tabs) {
          this.tabs = this.microsite.tabs
        }

        if (this.microsite.team) {
          this.team = this.microsite.team.concat(this.team.filter(t => !t._id))
        }
        if (this.microsite.partners) {
          this.partners = this.microsite.partners.concat(this.partners.filter(t => !t._id))
        }

        if (this.pics.length === 0) { // pics may be loaded already before save
          const pics = []
          JSON.parse(JSON.stringify(this.microsite.pics)).forEach((picName) => {
            pics.push({
              name: picName,
              id: uuidv4()
            })
          })
          this.pics = pics
        }
      } else {
        this.$refs.micrositeForm.reset()
        this.pics = []
      }
    },
    fileUploaded (newFile) {
      const hitIndex = this.picsToUpload.findIndex((file) => { return (file.id && (file.id === newFile.id)) })
      if (hitIndex === -1) {
        this.picsToUpload.push({
          name: newFile.name,
          id: newFile.id
        })
      } else {
        // Update name of existing file
        this.picsToUpload[hitIndex].name = newFile.name
      }
    },
    fileRemoved (file) {
      // Check temporary files first
      const indexHitTemp = this.picsToUpload.findIndex((value) => { return (value.id && (value.id === file.id)) })
      if (indexHitTemp !== -1) {
        this.picsToUpload.splice(indexHitTemp, 1)
        return
      }
      // Check existing files next
      const indexHitExist = this.pics.findIndex((value) => { return (value.id && (value.id === file.id)) })
      if (indexHitExist !== -1) {
        this.pics.splice(indexHitExist, 1)
      }
    },
    addChannel () {
      this.channels.push(
        {
          type: this.channelType,
          description: this.channelDescription,
          url: this.channelUrl
        }
      )
      this.channelType = ''
      this.channelDescription = ''
      this.channelUrl = ''
    },
    removeChannel (i) {
      this.channels.splice(i, 1)
    },
    addMember () {
      this.board.push(
        {
          firstName: this.memberFirstname,
          lastName: this.memberLastname
        }
      )
      this.memberFirstname = ''
      this.memberLastname = ''
    },
    removeMember (i) {
      this.board.splice(i, 1)
    },
    loadMicrosite () {
      if (this.organisation && this.organisation.microsite) {
        const tmpMicrosite = this.getMicrosite(this.organisation.microsite)
        if (!tmpMicrosite) {
          this.requestMicrosite(this.organisation.microsite)
            .then((result) => { this.microsite = result })
        } else {
          this.microsite = tmpMicrosite
        }
      }
    },
    async saveMicrosite () {
      this.processingSave = true
      // Remove existing pics
      if (this.microsite) {
        const picturesRemoved = await this.removePictures(this.pics.map((file) => { return file.name }))
        if (!picturesRemoved) {
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      }
      // Create new pic path array
      let picPaths = this.picsToUpload.map((file) => { return file.name })
      if (this.organisation.microsite) {
        picPaths = picPaths.concat(this.pics.map((file) => { return file.name }))
      }
      // Add data
      if (this.memberFirstname && this.memberLastname) {
        this.addMember()
      }
      if (this.channelDescription && this.channelUrl && this.channelType) {
        this.addChannel()
      }
      // Patch files (move and rename)
      for (let i = 0; i < this.picsToUpload.length; i++) {
        try {
          await this.uploadService.patch(
            this.picsToUpload[i].name,
            {
              oldFilePath: 'temporary',
              newFilePath: 'pics/' + this.organisation._id + '/microsite/',
              newFileName: this.picsToUpload[i].name
            }
          )
        } catch (e) {
          this.createLog({ type: 'error', text: 'Move microsite pics: ' + e })
        }
        const hitIndex = this.pics.findIndex((file) => { return (file.id && (file.id === this.picsToUpload[i].id)) })
        if (hitIndex === -1) {
          this.pics.push({
            name: this.picsToUpload[i].name,
            id: this.picsToUpload[i].id
          })
        }
      }
      this.picsToUpload.length = 0
      // Uploads
      try {
        await this.$refs.collectedTeamMemberUpload.upload()
        await this.$refs.collectedPartnerUpload.upload()
      } catch (e) {
        this.showSnackbar({ mode: 'save', success: false })
        this.processingSave = false
        console.log(e)
        return
      }
      // Create map
      const map = {
        text: this.text,
        hideName: this.hideName,
        custom: this.custom,
        candidature: this.candidature,
        pics: picPaths,
        founded: this.founded,
        contact: {
          firstName: this.contactFirstname,
          lastName: this.contactLastname,
          phone: this.contactPhone,
          fax: this.contactFax,
          email: this.contactEmail
        },
        channels: this.channels,
        board: this.board,
        team: this.team,
        partners: this.partners,
        tabs: this.tabs
      }
      if (this.microsite?.team) {
        for (const picName of this.microsite.team.map(t => t.pic?.name).filter(t => t)) {
          if (!map.team.map(t => t.pic?.name).filter(t => t).includes(picName)) {
            await this.removeUpload([picName, {}, {}])
          }
        }
      }
      if (this.microsite?.partners) {
        for (const picName of this.microsite.partners.map(t => t.pic?.name).filter(t => t)) {
          if (!map.partners.map(t => t.pic?.name).filter(t => t).includes(picName)) {
            await this.removeUpload([picName, {}, {}])
          }
        }
      }
      if (this.organisation.microsite) {
        try {
          await this.patchMicrosite([
            this.organisation.microsite,
            map,
            {}
          ])
        } catch (e) {
          this.createLog({ type: 'error', text: 'Patch microsite: ' + e })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      } else {
        try {
          map.organisation = this.organisation._id
          const msResult = await this.createMicrosite(map)
          await this.patchOrganisation(
            [
              this.organisation._id,
              { microsite: msResult._id },
              {}
            ]
          )
          this.microsite = msResult
        } catch (e) {
          this.createLog({ type: 'error', text: 'Patch organisation with microsite id: ' + e })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      }
      this.team = []
      this.partners = []
      this.adapt()
      this.processingSave = false
      this.showSnackbar({ mode: 'save', success: true })
    },
    async deleteMicrosite () {
      this.processingDelete = true
      const picturesRemoved = await this.removePictures([])
      if (!picturesRemoved) {
        this.processingDelete = false
        this.showSnackbar({ mode: 'delete', success: false })
        return
      }
      try {
        await this.removeMicrosite([
          this.organisation.microsite,
          {}
        ])
        this.microsite = undefined
        this.adapt()
      } catch (e) {
        this.createLog({ type: 'error', text: 'Remove microsite: ' + e })
        this.processingDelete = false
        this.showSnackbar({ mode: 'delete', success: false })
        return
      }
      try {
        await this.patchOrganisation([
          this.organisation,
          { microsite: null },
          { }
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Remove microsite from organisation: ' + e })
        this.processingDelete = false
        this.showSnackbar({ mode: 'delete', success: false })
        return
      }
      this.processingDelete = false
      this.showDeleteDialog = false
      this.showSnackbar({ mode: 'delete', success: true })
    },
    async removePictures (picturesToKeep) {
      if (!this.microsite) {
        return false
      }
      for (const existingPic of this.microsite?.pics) {
        if (!picturesToKeep.includes(existingPic)) {
          try {
            await this.uploadService.remove({ fileName: existingPic, path: 'pics/' + this.organisation._id + '/microsite' })
          } catch (e) {
            this.createLog({ type: 'error', text: 'Remove pic from microsite: ' + e })
            return false
          }
        }
      }
      return true
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      's3',
      'tabNames'
    ]),
    ...mapGetters('microsites', {
      getMicrosite: 'get'
    }),
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    },
    computedExistingFiles () {
      const existingFiles = []
      for (let i = 0; i < this.pics.length; i++) {
        existingFiles.push(
          {
            name: this.pics[i].name,
            id: this.pics[i].id,
            filePath: 'pics/' + this.organisation._id + '/microsite/'
          }
        )
      }
      return existingFiles
    }
  },
  watch: {
    organisation () {
      if (this.organisation) {
        this.loadMicrosite()
      } else {
        this.$refs.micrositeForm.reset()
        this.pics = []
      }
    },
    microsite () {
      if (this.microsite) {
        this.adapt()
      }
    },
    text () {
      if (this.text) {
        this.text = this.$sanitize(this.text)
      }
    },
    candidature () {
      if (this.candidature) {
        this.candidature = this.$sanitize(this.candidature)
      }
    },
    custom () {
      if (this.custom?.text) {
        this.custom.text = this.$sanitize(this.custom.text)
      }
    }
  }
}
</script>
