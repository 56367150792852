<template>
  <v-container
    class="pa-0 ma-0"
  >
    <v-hover v-slot:default="{ hover }">
      <v-card
        tile
        outlined
        :ref="event._id"
        :class="hover ? 'elevation-3': ''"
      >
        <v-card-text
          v-if="mode !== 'list'"
        >
          <v-row
            class="mx-1 clickable"
            @click="showOrganisation(getOrganisation(event.organisation))"
          >
            <v-col
              cols="6"
              class="primary--text card-text"
              align-self="center"
            >
              {{getOrganisation(event.organisation).name}}
            </v-col>
            <v-col
              cols="6"
              class="text-right"
              align-self="center"
            >
              <img
                height="50px"
                :src="computedServerUri + '/pics/' + event.organisation + '/logo/' + getOrganisation(event.organisation).pic"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-carousel
          class="flat-carousel"
          height="auto"
          v-if="event.pics.length > 0 && mode !== 'list'"
          cycle
          hide-delimiter-background
          hide-delimiters
          :show-arrows="event.pics.length > 1"
          :show-arrows-on-hover="event.pics.length > 1"
        >
          <v-carousel-item
            v-for="(pic, i) in event.pics"
            :key="i"
          >
            <v-img
              @click="$router.push('/veranstaltungen/' +  event._id)"
              class="clickable"
              :aspect-ratio="16/9"
              :src="computedServerUri + '/pics/' + event.organisation + '/events/' + pic"
            >
              <v-avatar
                v-if="event.internal"
                color="warning"
                class="rotate ml-3 mt-3 pa-3 white--text caption"
                size="60"
              >
                INTERN
              </v-avatar>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <v-card-text
          @click="$router.push('/veranstaltungen/' +  event._id)"
          class="primary--text card-title clickable event-container-content"
        >
          <v-row
            dense
          >
            <v-col>
              <template v-if="$moment(event.date.start).isSame(event.date.end, 'day')">
                <span class="card-text regular text-uppercase">{{$moment(event.date.start).format('dddd')}}</span>,
                <span class="card-text">{{$moment(event.date.start).format('DD.')}} </span>
                <span class="card-text text-uppercase">{{$moment(event.date.start).format('MMM').replace('.', '')}} </span>
                <span class="card-text">{{$moment(event.date.start).format('YYYY')}}</span><br>
                <span class="card-text">{{$moment(event.time.start).format('HH:mm')}}-{{$moment(event.time.end).format('HH:mm')}} Uhr</span>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="12">
                    <span class="card-text">
                      <span class="card-text regular text-uppercase">{{$moment(event.date.start).format('dddd')}}, </span>
                      {{$moment(event.date.start).format('DD.MM.YY,')}} {{$moment(event.time.start).format('HH:mm')}}  Uhr <span class="card-text regular">bis</span><br>
                    </span>
                    <span class="card-text">
                      <span class="card-text regular text-uppercase">{{$moment(event.date.end).format('dddd')}}, </span>
                      {{$moment(event.date.end).format('DD.MM.YY,')}} {{$moment(event.time.end).format('HH:mm,')}} Uhr<br>
                    </span>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="card-title">
              {{event.title}}
              <span class="ml-1" v-if="event.internal && (event.pics.length === 0)" />
              <span class="warning--text" v-if="event.internal && (event.pics.length === 0)">INTERN</span>
              <div class="error--text" v-if="event.isAccepted === false">NOCH NICHT FREIGEGEBEN</div>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="event.subTitle"
          >
            <v-col class="card-text regular">
              {{event.subTitle}}
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col
              class="item-body pretext pb-0"
              v-html="$sanitize($truncate(event.info, 100))"
            ></v-col>
          </v-row>
          <v-row
            dense
            v-if="event.tags?.length > 0"
          >
            <v-col>
              <span
                class="tag secondary--text text-uppercase"
                v-for="tag in event.tags"
                :key="tag"
              >
                #{{tag}}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="my-0"
          v-if="
              !$route.params.id &&
              user &&
              ac &&
              (
                ac.can(user.role).updateAny('event').granted ||
                (
                  ac.can(user.role).updateOwn('event').granted &&
                  event.organisation === user.organisation
                )
              )
            "
        >
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            icon
            @click="$router.push('/veranstaltungen/editor/' +  event._id)"
          >
            <v-icon small>fas fa-edit</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EventContainer',
  props: {
    event: {},
    mode: {}
  },
  methods: {
    showOrganisation (organisation) {
      if (organisation.type === 'member' || organisation.type === 'institution') {
        this.$router.push('/mitglieder/' + organisation._id)
      } else if (organisation.link) {
        window.open(organisation.link, '_newtab')
      }
    }
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('organisations', {
      getOrganisation: 'get'
    }),
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    }
  }
}
</script>

<style scoped>
  .event-container-content {
    box-sizing: border-box;
  }
</style>
