import feathersClient, { makeServicePlugin, BaseModel } from '@/feathers-client'

class Microsite extends BaseModel {
}
// Required for $FeathersVuex plugin to work after production transpile.
Microsite.modelName = 'Microsite'
const servicePath = 'microsites'
const servicePlugin = makeServicePlugin({
  Model: Microsite,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
