<template>
  <div>
    <v-toolbar
      flat
      color="primary"
      v-if="
        ac &&
        user &&
        ac.can(user.role).createAny('voting').granted
      "
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="$router.push('/abstimmungen/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>Neue Abstimmung</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="info"
        v-if="showEditor"
        @click="$closeEditor($route, $router)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      v-show="showEditor"
      class="pa-5"
      tile
      color="primary"
    >
      <v-form ref="votingForm" v-model="formValid">
        <v-card flat tile>
          <v-card-text>
            <v-row>
              <v-col class="primary--text section-title" cols="12">Basis-Informationen</v-col>
              <v-col cols="12">
                <v-select
                  v-model="votingType"
                  :items="[
                    {text: 'Mitgliederversammlung', value: 'members'},
                    {text: 'Individuelle Abstimmung', value: 'custom'}
                  ]"
                  color="secondary"
                  item-color="secondary"
                  label="Typ"
                  dense
                  outlined
                  :rules="[rules.required]"
                  :disabled="selectedVoting"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  label="Titel"
                  :rules="[rules.required, rules.longText]"
                  color="secondary"
                  :disabled="
                    !$route.params.id ||
                    !(
                      ac.can(user.role).updateAny('voting').attributes.includes('*') ||
                      ac.can(user.role).updateAny('voting').attributes.includes('title')
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <tiptap-vuetify
                  placeholder="Beschreibung"
                  v-model="description"
                  label="Beschreibung"
                  :card-props="{ tile: true, flat: true, outlined: true }"
                  :extensions="extensions"
                  :disabled="
                    !$route.params.id ||
                    !(
                      ac.can(user.role).updateAny('voting').attributes.includes('*') ||
                      ac.can(user.role).updateAny('voting').attributes.includes('description')
                    )
                  "
                >
                </tiptap-vuetify>
                <div
                  v-if="!description || description === '' || description === '<p></p>'"
                  class="v-messages px-3 p error--text"
                >
                  Pflichteingabe.
                </div>
                <div
                  v-else
                  style="height:22px;"
                >
                  &nbsp;
                </div>
              </v-col>
              <v-col cols="12">
                <v-dialog
                  ref="dateDialog"
                  v-model="showDateDialog"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      ref="datePicker"
                      outlined
                      dense
                      color="secondary"
                      :value="computedDate"
                      :label="'Letzter möglicher Abstimmungstag'"
                      append-icon="fas fa-calendar-day"
                      readonly
                      v-on="on"
                      :rules="[
                        rules.required
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="showDateDialog"
                    v-model="date"
                    full-width
                    format="24hr"
                    color="secondary"
                    first-day-of-week="1"
                  >
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="showDateDialog = false"
                      >
                        Abbrechen
                      </v-btn>
                    </v-hover>
                    <v-spacer></v-spacer>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :disabled="!date"
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="$refs.dateDialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-hover>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <template v-if="votingType === 'custom'">
                <v-col class="primary--text section-title" cols="12">Individuelle Abstimmung</v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="customName"
                    outlined
                    dense
                    label="Dein Name"
                    :rules="[rules.required, rules.longText]"
                    color="secondary"
                    :disabled="
                      !$route.params.id ||
                      !(
                        ac.can(user.role).updateAny('voting').attributes.includes('*') ||
                        ac.can(user.role).updateAny('voting').attributes.includes('custom')
                      )
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="customEmail"
                    outlined
                    dense
                    label="Deine Email-Adresse"
                    :rules="[rules.required, rules.email, rules.longText]"
                    color="secondary"
                    :disabled="
                      !$route.params.id ||
                      !(
                        ac.can(user.role).updateAny('voting').attributes.includes('*') ||
                        ac.can(user.role).updateAny('voting').attributes.includes('custom')
                      )
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="number"
                    min="1"
                    outlined
                    dense
                    label="Anzahl der Teilnehmer"
                    v-model="customVotes"
                    :rules="[rules.required]"
                    color="secondary"
                  >
                  </v-text-field>
                </v-col>
              </template>
              <v-col class="primary--text section-title" cols="12">Fragen</v-col>
              <template
                v-for="(question, i) in questions"
              >
                <v-col :key="i+'desc'" class="py-2 body-1" cols="12">
                  {{question.title}}
                  <v-btn
                    icon
                    color="secondary"
                    @click="removeQuestion(i)"
                  >
                    <v-icon
                      small
                    >
                      fas fa-trash
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col :key="i+'url'" class="py-2 body-1" cols="12">
                  Angenommen über {{question.requirement}} %
                </v-col>
                <v-col v-if="question.description" :key="i+'url'" class="py-2 body-1" cols="12">
                  {{question.description}}
                </v-col>
                <v-col
                  :key="i + 'links'"
                >
                  <template
                    v-for="(link, j) in question.links"
                  >
                    <v-col :key="j+'link'" class="body-1 pa-0" align-self="center" cols="12">
                      <v-icon x-small color="#999" class="mr-1">fas fa-external-link-alt</v-icon>
                      {{link.text}} ({{link.url}})
                    </v-col>
                  </template>
                </v-col>
                <div class="divider" :key="i+'divider'"></div>
              </template>
              <v-col class="py-4 pb-0" cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Frage / Titel"
                  v-model="questionTitle"
                  :error-messages="computedQuestionsError"
                  color="secondary"
                  @focus="$refs.votingForm.validate()"
                  @blur="$refs.votingForm.validate()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-4 pb-0" cols="12">
                <v-text-field
                  type="number"
                  min="0"
                  max="99"
                  outlined
                  dense
                  label="Prozentzahl über der die Frage als angenommen gilt"
                  v-model="questionRequirement"
                  :rules="[checkRequirement]"
                  color="secondary"
                  @focus="$refs.votingForm.validate()"
                  @blur="$refs.votingForm.validate()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-4 pb-0" cols="12">
                <v-textarea
                  outlined
                  dense
                  label="Beschreibung"
                  v-model="questionDescription"
                  color="secondary"
                  class="optional-field"
                >
                </v-textarea>
              </v-col>
              <template
                v-for="(link, i) in tmpLinks"
              >
                <v-col :key="i+'desc'" class="py-2 body-1" align-self="center" cols="12" sm="6">
                  {{link.url}}
                </v-col>
                <v-col :key="i+'url'" class="py-2 body-1" align-self="center" cols="12" sm="5">
                  {{link.text}}
                </v-col>
                <v-col :key="i" class="py-1 text-center" align-self="center" cols="12" sm="1">
                  <v-btn
                    icon
                    color="secondary"
                    @click="removeLink(i)"
                  >
                    <v-icon
                      small
                    >
                      fas fa-trash
                    </v-icon>
                  </v-btn>
                </v-col>
                <div class="divider" :key="i+'divider'"></div>
              </template>
              <v-col class="py-4 pb-0 pt-3" cols="12">
                <v-row dense>
                  <v-col :class="$vuetify.breakpoint.mdAndUp ? '' : 'pb-0'" cols="12" md="6">
                    <v-text-field
                      outlined
                      dense
                      label="Link-Url beginnend mit http / https"
                      v-model="linkUrl"
                      color="secondary"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-0'" cols="12" md="6">
                    <v-text-field
                      outlined
                      dense
                      label="Angezeigter Link-Text"
                      v-model="linkText"
                      color="secondary"
                      class="optional-field"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-10" cols="12">
                    <v-hover v-slot:default="{ hover }">
                      <v-btn
                        tile
                        depressed
                        :disabled="!linkUrl"
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="addLink()"
                      >
                        Weiterer Link
                        <v-icon
                          class="ml-3"
                          small
                        >
                          fas fa-plus
                        </v-icon>
                      </v-btn>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
              <div class="divider" :key="i+'divider'"></div>
              <v-col class="pt-5" cols="12">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    tile
                    depressed
                    :disabled="!questionTitle"
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    @click="addQuestion()"
                  >
                    Weitere Frage hinzufügen
                    <v-icon
                      class="ml-3"
                      small
                    >
                      fas fa-plus
                    </v-icon>
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            class="px-4 pb-4"
          >
            <v-hover
              v-if="selectedVoting"
              v-slot:default="{ hover }"
            >
              <v-btn
                v-if="selectedVoting.isActive"
                tile
                depressed
                :disabled="processingArchive"
                :color="hover ? 'warning' : 'info'"
                class="white--text"
                :loading="processingArchive"
                @click="showDeleteDialog = true"
              >
                Archivieren
              </v-btn>
            </v-hover>
            <v-hover
              v-if="selectedVoting"
              v-slot:default="{ hover }"
            >
              <v-btn
                v-if="!selectedVoting.isActive"
                tile
                depressed
                :disabled="processingActivate"
                :color="hover ? 'success' : 'info'"
                class="white--text"
                :loading="processingActivate"
                @click="activateVoting()"
              >
                Aktivieren
              </v-btn>
            </v-hover>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :disabled="!formValid || processingSave || description === '' || description === '<p></p>'"
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="votingType === 'members' ? showSaveDialog = true : saveVoting()"
                :loading="processingSave"
              >
                Speichern
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-dialog v-model="showDeleteDialog" max-width="350px">
        <v-card>
          <v-card-title>Wirklich archivieren?</v-card-title>
          <v-card-text>
            Sollte die Abstimmung bereits laufen und noch nicht beendet sein, kann nicht weiter abgestimmt werden!
          </v-card-text>
          <v-card-actions class="px-6 pb-6">
            <v-hover
              v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showDeleteDialog = false"
              >
                Abbrechen
              </v-btn>
            </v-hover>
            <v-spacer></v-spacer>
            <v-hover
              v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :disabled="processingDelete"
                :color="hover ? 'warning' : 'info'"
                class="white--text"
                :loading="processingDelete"
                @click="archiveVoting()"
              >
                Archivieren
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSaveDialog" max-width="350px">
        <v-card>
          <v-card-title>Achtung!</v-card-title>
          <v-card-text
            v-if="selectedVoting"
          >
            Nachträgliche Änderungen können zu Problemen und Inkonsistenzen bei laufenden Abstimmungen führen.
          </v-card-text>
          <v-card-text
            v-else
          >
            Nach dem Abspeichern der Abstimmung können keine weiteren Stimmberechtigten mehr hinzugenommen werden. Es werden lediglich die jetzt vorhandenen Stimmberechtigten berücksichtigt.
          </v-card-text>
          <v-card-actions class="px-6 pb-6">
            <v-hover
              v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showSaveDialog = false"
              >
                Abbrechen
              </v-btn>
            </v-hover>
            <v-spacer></v-spacer>
            <v-hover
              v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :disabled="processingDelete"
                :color="hover ? 'success' : 'info'"
                class="white--text"
                :loading="processingDelete"
                @click="saveVoting()"
              >
                Speichern
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TiptapVuetify, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
  name: 'VotingEditor',
  props: [
    'showEditor'
  ],
  components: {
    TiptapVuetify
  },
  data () {
    return {
      title: null,
      description: '',
      questions: [],
      processingArchive: false,
      processingActivate: false,
      processingSave: false,
      selectedVoting: undefined,
      showDeleteDialog: false,
      formValid: false,
      date: undefined,
      showDateDialog: false,
      questionTitle: undefined,
      questionDescription: undefined,
      votingType: undefined,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList
      ],
      linkText: undefined,
      linkUrl: undefined,
      tmpLinks: [],
      showSaveDialog: false
    }
  },
  mounted () {
    this.adapt()
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('votings', {
      getVoting: 'get'
    }),
    computedQuestionsError () {
      if ((this.questionTitle && this.questionTitle !== '') || this.questions.length > 0) {
        return undefined
      } else {
        return 'Mindestens eine Frage erforderlich'
      }
    },
    computedDate () {
      return this.date ? this.$moment(this.date).format('dddd, DD.MM.YYYY') : ''
    }
  },
  methods: {
    ...mapActions([
      'showSnackbar'
    ]),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('votings', {
      createVoting: 'create',
      patchVoting: 'patch'
    }),
    addLink () {
      if (this.linkUrl && this.linkUrl !== '') {
        this.tmpLinks.push(
          {
            url: this.linkUrl,
            text: this.linkText
          }
        )
        this.linkUrl = ''
        this.linkText = ''
      }
    },
    removeLink (question, i) {
      this.links.splice(i, 1)
    },
    checkRequirement (v) {
      if (this.questionTitle && this.questionTitle !== '') {
        if (!v) {
          return 'Pflichteingabe.'
        } else {
          return true
        }
      } else {
        return true
      }
    },
    adapt () {
      this.selectedVoting = this.getVoting(this.$route.params.id)
      if (this.selectedVoting) {
        this.title = this.selectedVoting.title
        this.description = this.selectedVoting.description
        this.questions = JSON.parse(JSON.stringify(this.selectedVoting.questions))
        this.date = undefined
        this.$nextTick(() => {
          this.votingType = this.selectedVoting.type
          this.date = this.$moment(this.selectedVoting.dueDate).format('YYYY-MM-DD')
        })
        if (this.selectedVoting.type === 'custom') {
          this.customName = this.selectedVoting.custom.name
          this.customEmail = this.selectedVoting.custom.email
          this.customVotes = this.selectedVoting.custom.votes
        }
      } else {
        this.$refs.votingForm.reset()
        this.questions = []
        this.description = ''
        this.$nextTick(() => {
          this.votingType = 'members'
        })
      }
    },
    async saveVoting (confirmed) {
      this.processingSave = true
      if (this.questionTitle && this.questionRequirement) {
        this.addQuestion()
      }
      // Create map
      const map = {
        title: this.title,
        type: this.votingType,
        description: this.description,
        questions: this.questions,
        dueDate: this.$moment(this.date).endOf('day').toISOString()
      }
      if (this.votingType === 'custom') {
        map.custom = {
          name: this.customName,
          email: this.customEmail,
          votes: parseInt(this.customVotes)
        }
      }
      // Update or create
      try {
        if (this.selectedVoting) {
          this.selectedVoting = await this.patchVoting([
            this.selectedVoting._id,
            map,
            {}
          ])
        } else {
          this.selectedVoting = await this.createVoting(map)
        }
      } catch (e) {
        this.createLog({ type: 'error', text: 'Patch / create voting: ' + e.toString() + ' ' + JSON.stringify(map) })
        this.processingSave = false
        this.showSnackbar({ mode: 'save', success: false })
        return
      }
      this.processingSave = false
      this.showSnackbar({ mode: 'save', success: true })
      this.$closeEditor(this.$route, this.$router)
    },
    async archiveVoting () {
      this.processingArchive = true
      try {
        await this.patchVoting([
          this.selectedVoting._id,
          {
            isActive: false
          },
          {}
        ])
        this.showDeleteDialog = false
      } catch (e) {
        this.createLog({ type: 'error', text: 'Archive voting: ' + e })
        this.processingArchive = false
        this.showSnackbar({ mode: 'archive', success: false })
        return
      }
      this.processingArchive = false
      this.showSnackbar({ mode: 'archive', success: true })
    },
    async activateVoting () {
      this.processingActivate = true
      try {
        await this.patchVoting([
          this.selectedVoting._id,
          {
            isActive: true
          },
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Activate voting: ' + e })
        this.processingActivate = false
        this.showSnackbar({ mode: 'activate', success: false })
        return
      }
      this.processingActivate = false
      this.showSnackbar({ mode: 'activate', success: true })
    },
    addQuestion () {
      this.addLink()
      this.questions.push(
        {
          title: this.questionTitle,
          description: this.questionDescription,
          requirement: this.questionRequirement,
          links: this.tmpLinks
        }
      )
      this.tmpLinks = []
      this.questionTitle = ''
      this.questionDescription = ''
      this.questionRequirement = undefined
    },
    removeQuestion (i) {
      this.questions.splice(i, 1)
    }
  },
  watch: {
    '$route.params.id' () {
      this.adapt()
    },
    selectedVoting () {
      this.adapt()
    },
    description () {
      if (this.description) {
        this.description = this.$sanitize(this.description)
      }
    }
  }
}
</script>
