import Vue from 'vue'
import Store from '@/store'
import Multiguard from 'vue-router-multiguard'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import WhoWeAre from '../views/WhoWeAre.vue'
import WhatWeDo from '../views/WhatWeDo.vue'
import JoinUs from '../views/JoinUs.vue'
import Membership from '../views/Membership.vue'
import Organisations from '../views/Organisations.vue'
import OrganisationDetails from '../views/OrganisationDetails.vue'
import EventDetails from '../views/EventDetails.vue'
import Events from '../views/Events.vue'
import BookingEditor from '../views/BookingEditor.vue'
import Users from '../views/Users.vue'
import Voters from '../views/Voters.vue'
import Votings from '../views/Votings.vue'
import UserEditor from '../components/ProfileEditor.vue'
import Contact from '../views/Contact.vue'
import Offers from '../views/Offers.vue'
import Verify from '../views/Verify.vue'
import Delegates from '../views/Delegates.vue'
import Voting from '../views/Voting.vue'

let ac
let user

Vue.use(VueRouter)

const showElements = (to, from, next) => {
  for (const query in to.query) {
    if (query !== 's' && query !== 'organisation') {
      Store.commit('SET_SHOW_' + query.toUpperCase(), to.query[query])
    }
  }
  next()
}

const waitFor = async (to, from, next) => {
  if (to.fullPath.indexOf('//') !== -1) {
    return next(to.fullPath.replace('//', '/'))
  }
  if (Store.state.system.init) {
    // Login
    if (!Store.getters['auth/user']) {
      try {
        await Store.dispatch('auth/authenticate')
      } catch (error) {
        localStorage.removeItem('feathers-jwt')
        if (error.message !== 'No accessToken found in storage') {
          Store.commit('SET_SHOW_ERROR_DIALOG', error.message)
        }
      }
    }
    if (localStorage['feathers-jwt']) {
      try {
        await Store.dispatch('users/find', { paginate: false })
        await Store.dispatch('voters/find', { paginate: false })
        await Store.dispatch('votings/find', { paginate: false })
        delete to.query.login
      } catch (error) {
        console.log(error)
        localStorage.removeItem('feathers-jwt')
        Store.commit('SET_SHOW_ERROR_DIALOG', error.message)
      }
    }
    // Load common stuff
    try {
      await Store.dispatch('organisations/find', { paginate: false })
      await Store.dispatch('accesscontrol/find', { paginate: false })
    } catch (e) {}
    // Set ac variables
    ac = Store.getters.ac
    user = Store.getters['auth/user']
    // Set show
    Store.commit('SET_SHOW', true)
    Store.commit('SET_INIT', false)
  }
  if (to.path !== '/') {
    Store.commit('SET_SHOW_NAV', false)
  } else {
    Store.commit('SET_SHOW_NAV', true)
  }
  next()
}

const beforeEnterOrganisationEditor = (to, from, next) => {
  if (to.params.id !== 'neu') {
    if (
      user &&
      ac &&
      (
        ac.can(user.role).updateAny('organisation').granted ||
        (
          ac.can(user.role).updateOwn('organisation').granted &&
          to.params.id === user.organisation
        )
      )
    ) {
      next()
    } else {
      if (!from.name) {
        next('/neuigkeiten')
      } else {
        next(from)
      }
      Store.commit('SET_SHOW_ERROR_DIALOG', '403')
    }
  } else {
    if (
      user &&
      ac &&
      (
        ac.can(user.role).createAny('organisation').granted ||
        ac.can(user.role).createOwn('organisation').granted
      )
    ) {
      next()
    } else {
      if (!from.name) {
        next('/neuigkeiten')
      } else {
        next(from)
      }
      Store.commit('SET_SHOW_ERROR_DIALOG', '403')
    }
  }
}

const asyncBeforeEnterEditor = async (objectType, to, from, next) => {
  let storeGettersKey
  switch (objectType) {
    case 'event': storeGettersKey = 'events/get'; break
    case 'news': storeGettersKey = 'news/get'; break
    case 'offer': storeGettersKey = 'news/get'; break
    default: return
  }
  if (to.params.id !== 'neu') {
    let targetObject = Store.getters[storeGettersKey](to.params.id)
    if (!targetObject) {
      try {
        targetObject = await Store.dispatch(storeGettersKey, to.params.id)
      } catch (error) {
        if (!from.name) {
          next('/neuigkeiten')
        } else {
          next(from)
        }
        Store.commit('SET_SHOW_ERROR_DIALOG', '404')
        return
      }
    }
    if (!targetObject) {
      if (!from.name) {
        next('/neuigkeiten')
      } else {
        next(from)
      }
      Store.commit('SET_SHOW_ERROR_DIALOG', '404')
      return
    }
    if (
      user &&
      ac &&
      (
        ac.can(user.role).updateAny(objectType).granted ||
        (
          ac.can(user.role).updateOwn(objectType).granted &&
          targetObject.organisation === user.organisation
        )
      )
    ) {
      next()
    } else {
      if (!from.name) {
        next('/neuigkeiten')
      } else {
        next(from)
      }
      Store.commit('SET_SHOW_ERROR_DIALOG', '403')
    }
  } else {
    if (
      user &&
      ac &&
      (
        ac.can(user.role).createAny(objectType).granted ||
        ac.can(user.role).createOwn(objectType).granted
      )
    ) {
      next()
    } else {
      if (!from.name) {
        next('/neuigkeiten')
      } else {
        next(from)
      }
      Store.commit('SET_SHOW_ERROR_DIALOG', '403')
    }
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Neuigkeiten
  {
    path: '/neuigkeiten',
    name: 'news',
    component: Home,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/neuigkeiten/editor/:id',
    name: 'newsEditor',
    component: Home,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Mitglieder
  {
    path: '/mitglieder',
    name: 'organisations',
    component: Organisations,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/mitglieder/editor/:id',
    name: 'organisationsEditor',
    component: Organisations,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      beforeEnterOrganisationEditor
    ])
  },
  {
    path: '/mitglieder/:id',
    name: 'organisationDetails',
    component: OrganisationDetails,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/mitglieder/:id/:editor',
    name: 'organisationDetailsEditor',
    component: OrganisationDetails,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      beforeEnterOrganisationEditor
    ])
  },
  // Veranstaltungen
  {
    path: '/veranstaltungen',
    name: 'events',
    component: Events,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/veranstaltungen/buchung/:id',
    name: 'bookingEditor',
    component: BookingEditor,
    beforeEnter: Multiguard([
      waitFor
    ])
  },
  {
    path: '/veranstaltungen/editor/:id',
    name: 'eventsEditor',
    component: Events,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => asyncBeforeEnterEditor('event', to, from, next)
    ])
  },
  {
    path: '/veranstaltungen/:id',
    name: 'eventDetails',
    component: EventDetails,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/veranstaltungen/:id/:editor',
    name: 'eventDetailsEditor',
    component: EventDetails,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => asyncBeforeEnterEditor('event', to, from, next)
    ])
  },
  // Wer wir sind
  {
    path: '/wer-wir-sind',
    name: 'WhoWeAre',
    component: WhoWeAre,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Was wir machen
  {
    path: '/was-wir-machen',
    name: 'WhatWeDo',
    component: WhatWeDo,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/was-wir-machen/editor/:id',
    name: 'WhatWeDoEditor',
    component: WhatWeDo,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      beforeEnterOrganisationEditor
    ])
  },
  // Mitmachen
  {
    path: '/mitmachen',
    name: 'JoinUs',
    component: JoinUs,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Mitglied werden
  {
    path: '/mitgliedschaft',
    name: 'Membership',
    component: Membership,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Verify
  {
    path: '/verify/:type/:token',
    name: 'verify',
    component: Verify,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Nutzer
  {
    path: '/nutzer',
    name: 'users',
    component: Users,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).createAny('admin').granted
        ) {
          next()
        } else {
          if (!from.name) {
            next('/neuigkeiten')
          } else {
            next(from)
          }
          Store.commit('SET_SHOW_ERROR_DIALOG', '403')
        }
      }
    ])
  },
  // Stimmberechtigte
  {
    path: '/stimmberechtigte',
    name: 'voters',
    component: Voters,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).readAny('voter').granted
        ) {
          next()
        } else {
          if (!from.name) {
            next('/neuigkeiten')
          } else {
            next(from)
          }
          Store.commit('SET_SHOW_ERROR_DIALOG', '403')
        }
      }
    ])
  },
  // Voters editor
  {
    path: '/stimmberechtigte/editor/:id',
    name: 'votersEditor',
    component: Voters,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (to.params.id !== 'neu') {
          const targetVoter = Store.getters['voters/get'](to.params.id)
          if (!targetVoter) {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '404')
            return
          }
          if (
            user &&
            ac &&
            ac.can(user.role).updateAny('voter').granted
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        } else {
          if (
            user &&
            ac &&
            ac.can(user.role).createAny('voter').granted
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        }
      }
    ])
  },
  // Abstimmungen
  {
    path: '/abstimmungen',
    name: 'votings',
    component: Votings,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).readAny('voting').granted
        ) {
          next()
        } else {
          if (!from.name) {
            next('/neuigkeiten')
          } else {
            next(from)
          }
          Store.commit('SET_SHOW_ERROR_DIALOG', '403')
        }
      }
    ])
  },
  // Abstimmungen Editor
  {
    path: '/abstimmungen/editor/:id',
    name: 'votingEditor',
    component: Votings,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (to.params.id !== 'neu') {
          const targetVoting = Store.getters['votings/get'](to.params.id)
          if (!targetVoting) {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '404')
            return
          }
          if (
            user &&
            ac &&
            ac.can(user.role).updateAny('voting').granted
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        } else {
          if (
            user &&
            ac &&
            ac.can(user.role).createAny('voting').granted
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        }
      }
    ])
  },
  // Profile editor
  {
    path: '/profil/editor/:id',
    name: 'profile',
    component: UserEditor,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (to.params.id !== 'neu') {
          const targetUser = Store.getters['users/get'](to.params.id)
          if (!targetUser) {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '404')
            return
          }
          if (
            user &&
            ac &&
            (
              ac.can(user.role).updateAny(targetUser.role).granted ||
              (
                ac.can(user.role).updateOwn(targetUser.role).granted &&
                to.params.id === user._id
              )
            )
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        } else {
          if (
            user &&
            ac &&
            (
              ac.can(user.role).createAny('admin').granted ||
              ac.can(user.role).createOwn('admin').granted
            )
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        }
      }
    ])
  },
  {
    path: '/nutzer/editor/:id',
    name: 'usersEditor',
    component: Users,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (to.params.id !== 'neu') {
          const targetUser = Store.getters['users/get'](to.params.id)
          if (!targetUser) {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '404')
            return
          }
          if (
            user &&
            ac &&
            (
              ac.can(user.role).updateAny(targetUser.role).granted ||
              (
                ac.can(user.role).updateOwn(targetUser.role).granted &&
                to.params.id === user._id
              )
            )
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        } else {
          if (
            user &&
            ac &&
            (
              ac.can(user.role).createAny('admin').granted ||
              ac.can(user.role).createOwn('admin').granted
            )
          ) {
            next()
          } else {
            if (!from.name) {
              next('/neuigkeiten')
            } else {
              next(from)
            }
            Store.commit('SET_SHOW_ERROR_DIALOG', '403')
          }
        }
      }
    ])
  },
  // Kontakt
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  // Schwarzes Brett
  {
    path: '/aushang',
    name: 'offers',
    component: Offers,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (
          user &&
          ac &&
          ac.can(user.role).readAny('offer').granted
        ) {
          next()
        } else {
          if (!from.name) {
            next('/neuigkeiten')
          } else {
            next(from)
          }
          Store.commit('SET_SHOW_ERROR_DIALOG', '403')
        }
      }
    ])
  },
  {
    path: '/aushang/editor/:id',
    name: 'offersEditor',
    component: Offers,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => asyncBeforeEnterEditor('offer', to, from, next)
    ])
  },
  {
    path: '/abstimmen/delegiertenwahl/:voting/:boardCode',
    name: 'delegates',
    component: Delegates,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/abstimmen/stimmabgabe/:voting/:delegateCode',
    name: 'voting',
    component: Voting,
    beforeEnter: Multiguard([
      waitFor,
      showElements
    ])
  },
  {
    path: '/:id',
    name: 'home',
    component: Home,
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        const organisation = Store.getters['organisations/list']
          .find(organisation => organisation._id?.toLowerCase() === to.params.id?.toLowerCase() || organisation.slug?.toLowerCase() === to.params.id?.toLowerCase())
        if (organisation) {
          next({ name: 'organisationDetails', params: { id: organisation._id } })
        } else {
          if (!from.name) {
            next('/neuigkeiten')
          } else {
            next(from)
          }
          Store.commit('SET_SHOW_ERROR_DIALOG', '404')
        }
      }
    ])
  },
  // Alle
  {
    path: '*',
    beforeEnter: Multiguard([
      waitFor,
      showElements,
      (to, from, next) => {
        if (!from.name) {
          next('/neuigkeiten')
        } else {
          next(from)
        }
        Store.commit('SET_SHOW_ERROR_DIALOG', '404')
      }
    ])
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  waitFor
})

export default router
