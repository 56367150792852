<template>
  <div>
    <vue-headful
      title="SJR Ulm - Kontakt"
      description="So könnt ihr den Stadtjugendring Ulm e.V. erreichen."
    />
    <TitleToolbar
      v-bind:title="title"
    />
    <v-container
      class="my-6 px-8"
      fluid
    >
      <v-row
        class="services-title py-6"
        no-gutters
      >
        Wir freuen uns von Dir zu hören!
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-row
            class="services-description"
            style="font-weight: bold;"
            no-gutters
          >
            Stadtjugendring Ulm e.V.<br>
            Schillerstraße 1/4<br>
            89077 Ulm<br><br>
            Telefon: 0731–14 06 90<br>
            Telefax: 0731–14 06 969<br><br>
            <a
              href='emailto:info@sjr-ulm.de'
              class="text-link"
            >
              info@sjr-ulm.de
            </a><br>
            <a
              href='www.sjr-ulm.de'
              class="text-link"
            >
              www.sjr-ulm.de
            </a>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-img
            max-height="600"
            contain
            src="/img/191028-stadtjugendring-lageplan.svg"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <GmapMap
        :center="center"
        :zoom="17"
        style="width: 100%; height: 500px;"
        :options="mapOptions"
      >
      <GmapMarker
        :position="{
            lat: center.lat,
            lng: center.lng
        }"
        :text="markerText"
        color='#003151'
        fontWeight='bold'
      />
      </GmapMap>
    </v-container>
  </div>
</template>

<script>

import TitleToolbar from '@/components/TitleToolbar'

export default {
  name: 'Contact',
  data: () => ({
    title: 'KONTAKT',
    markerText: 'STADTJUGENDRING ULM',
    center: { lat: 48.392650, lng: 9.983296 },
    infoOptions: {
      pixelOffset: {
        width: -5,
        height: -55
      },
      content: ''
    },
    mapOptions: {
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'road.local',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        }
      ]
    },
    infoWindowPos: null,
    infoWinOpen: false
  }),
  components: {
    TitleToolbar
  },
  computed: {
    imageWidth () {
      let value = '30%'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': value = '100%'; break
        case 'sm': value = '70%'; break
        case 'md': value = '40%'; break
      }
      return value
    },
    textJustify () {
      let value = 'justify-center'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': value = 'justify-start'; break
        case 'sm': value = 'justify-start'; break
      }
      return value
    },
    textAlign () {
      let value = 'center'
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': value = 'left'; break
        case 'sm': value = 'left'; break
      }
      return value
    }
  }
}
</script>

<style scoped>
    a.text-link {
        display: contents;
        text-decoration: none;
        color: #31aec0;
    }
</style>
