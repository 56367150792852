<template>
  <div>
    <v-toolbar
      flat
      color="#f5f7fa"
    >
      <span
        class="section-title primary--text"
        cols="6"
      >
        Schwarzes Brett
      </span>
    </v-toolbar>
    <Header />
    <!-- Filters -->
    <v-container fluid>
      <v-row dense>
        <v-col class="text-right">
          <v-btn
            text
            :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
            @click="showFilters = true"
          >
            FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
          </v-btn>
          <v-btn
            v-if="computedFiltersActive > 0"
            icon
            color="secondary"
            @click="resetFilters()"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div ref="newsEditorContainer">
      <NewsEditor
        v-show="
          ac &&
          user &&
          (
            ac.can(user.role).createAny('news').granted ||
            ac.can(user.role).createOwn('news').granted
          )
        "
        ref="newsEditor"
        :event="getNews($route.params.id)"
        :showEditor="showEditor"
      />
    </div>
    <div ref="news"></div>
    <v-container
      fluid
    >
      <v-row>
        <v-col
          v-for="(newsEntry, i) in news"
          :key="i"
          cols="12"
          md="4"
          lg="3"
          sm="6"
        >
          <NewsContainer
            :news="newsEntry"
          >
          </NewsContainer>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row v-if="computedTotal === 0">
        <v-col  class="info--text text-center pa-0" cols="12">
          Keine Einträge vorhanden
        </v-col>
      </v-row>
      <v-row v-if="computedTotal > 0">
        <v-col class="pa-0" algin-self="center" cols="12" sm="4">
          <v-hover
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="skip > 0"
              tile
              text
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="nextPage()"
            >
              <v-icon small class="mr-3">fas fa-chevron-left</v-icon>
              Aktuellere anzeigen
            </v-btn>
          </v-hover>
        </v-col>
        <v-col align-self="center" class="info--text text-center pa-0" cols="12" sm="4">
          <template v-if="skip+limit <= computedTotal">
          {{skip+1}}-{{skip+limit}} von {{computedTotal}}
          </template>
          <template v-else>
            {{skip+1}}-{{computedTotal}} von {{computedTotal}}
          </template>
        </v-col>
        <v-col align-self="center" class="text-right pa-0" cols="12" sm="4">
          <v-hover
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="(skip + limit) < computedTotal"
              tile
              text
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="previousPage()"
            >
              Ältere anzeigen
              <v-icon small class="ml-3">fas fa-chevron-right</v-icon>
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showEditorDialog"
      fullscreen
      v-if="
        ac &&
        user &&
        (
          ac.can(user.role).createAny('news').granted ||
          ac.can(user.role).createOwn('news').granted
        )
        "
    >
      <NewsEditor
        :news="getNews($route.params.id)"
        :showEditor="showEditorDialog"
      />
    </v-dialog>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col>
              <v-dialog
                ref="filterDatesDialog"
                v-model="showFilterDatesDialog"
                :return-value.sync="filterDates"
                persistent
                width="320px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    color="secondary"
                    :value="computedFilterDates"
                    @click:clear="filterDates = []"
                    label="Zeitraum"
                    append-icon="fas fa-calendar-day"
                    readonly
                    id="filterDatesInput"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-if="showFilterDatesDialog"
                  v-model="filterDates"
                  full-width
                  format="24hr"
                  color="secondary"
                  range
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-hover
                    v-slot:default="{ hover }"
                  >
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="showFilterDatesDialog = false"
                    >
                      Abbrechen
                    </v-btn>
                  </v-hover>
                  <v-hover
                    v-slot:default="{ hover }"
                  >
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="$refs.filterDatesDialog.save(filterDates)"
                    >
                      OK
                    </v-btn>
                  </v-hover>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Organisation(en)"
                multiple
                v-model="filterOrganisations"
                clearable
                color="secondary"
                item-color="secondary"
                :items="computedOrganisations"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <template v-if="user">
          <v-row>
            <v-col cols="12">
              <div class="body-1">Aushang temporäre / dauerhaft</div>
              <v-radio-group
                v-model="filterPermanent"
              >
                <v-radio
                  color="secondary"
                  label="Alle anzeigen"
                  :value="0"
                ></v-radio>
                <v-radio
                  color="secondary"
                  label="Nur temporäre"
                  :value="1"
                ></v-radio>
                <v-radio
                  color="secondary"
                  label="Nur dauerhaft"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="body-1">Aushang alle / eigene</div>
              <v-checkbox
                color="secondary"
                label="Nur eigene anzeigen"
                v-model="filterOwn"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import NewsEditor from '@/components/NewsEditor'
import NewsContainer from '@/components/NewsContainer'

export default {
  name: 'offers',
  components: {
    NewsEditor,
    NewsContainer
  },
  mixins: [makeFindMixin({ service: 'news', watch: true })],
  data () {
    return {
      limit: 6,
      skip: 0,
      showEditor: false,
      showEditorDialog: false,
      showFilterDatesDialog: false,
      showFilters: false,
      filterOrganisations: [],
      filterPermanent: 0,
      filterOwn: false,
      filterDates: []
    }
  },
  mounted () {
    if (this.$route.query.s) {
      this.skip = parseInt(this.$route.query.s)
    }
    this.adaptParams()
    if (this.$route.params.own) {
      this.filterOwn = true
      delete this.$route.params.own
    }
  },
  methods: {
    ...mapActions('news', {
      requestNews: 'get'
    }),
    resetFilters () {
      this.filterOrganisations = []
      this.filterPermanent = 0
      this.filterOwn = false
    },
    async adaptParams () {
      if (this.$route.params.id) {
        if (this.$route.params.id !== 'neu') {
          let tmpNews = this.getNews(this.$route.params.id)
          if (!tmpNews) {
            tmpNews = await this.requestNews(this.$route.params.id)
          }
          this.openEditor(tmpNews)
        } else {
          this.openEditor()
        }
      } else {
        this.closeEditor()
      }
    },
    previousPage () {
      this.skip = this.skip + this.limit
    },
    nextPage () {
      this.skip = this.skip - this.limit
    },
    openEditor (event) {
      this.showEditorDialog = true
    },
    closeEditor () {
      this.showEditor = false
      this.showEditorDialog = false
    }
  },
  computed: {
    newsParams () {
      const query = { qid: 'offers', query: { internal: true, $limit: this.limit, $skip: this.skip, $sort: { createdAt: -1 } } }
      if (this.filterOrganisations.length > 0) {
        query.query.organisation = { $in: this.filterOrganisations }
      }
      if (this.filterOwn) {
        query.query.organisation = this.user.organisation
      }
      if (this.filterPermanent.length) {
        query.query.permanent = true
      }
      if (this.filterDates.length === 2) {
        query.query.updatedAt = { $gt: this.filterDates[0], $lt: this.filterDates[1] }
      }
      return query
    },
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('news', {
      getNews: 'get'
    }),
    ...mapGetters('organisations', {
      getOrganisation: 'get',
      organisations: 'list'
    }),
    ...mapGetters('auth', {
      user: 'user'
    }),
    computedTotal () {
      try {
        return this.newsPaginationData.offers.mostRecent.total
      } catch (e) {
        return 0
      }
    },
    computedOrganisations () {
      return this.organisations.filter(obj => obj.isActive).map(obj => { return { value: obj._id, text: obj.name } })
    },
    computedFiltersActive () {
      let count = 0
      if (this.filterOrganisations.length !== 0) { ++count }
      if (this.filterPermanent !== 0) { ++count }
      if (this.filterOwn) { ++count }
      return count
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    skip () {
      if (parseInt(this.$route.query.s) !== this.skip) {
        this.$router.replace({ query: { s: this.skip } })
      }
    },
    showEditorDialog () {
      if (!this.showEditorDialog) {
        this.findNews()
      }
    },
    filterDates () {
      if (this.filterDates.length === 2) {
        if (this.$moment(this.filterDates[0]).isAfter(this.$moment(this.filterDates[1]))) {
          const tmpDate = this.filterDates[0]
          this.filterDates[0] = this.filterDates[1]
          this.filterDates[1] = tmpDate
        }
      }
    },
    user () {
      this.findNews()
    }
  }
}
</script>
