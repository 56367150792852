<template>
  <div>
    <v-toolbar
      flat
      color="primary"
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="$router.push($route.path + '/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>{{ $route.name.toLowerCase().includes("offers") ? 'AUSHANG' : 'NEUIGKEITEN' }} HINZUFÜGEN</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="info"
        v-if="showEditor"
        @click="$closeEditor($route, $router)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      v-show="showEditor"
      tile
      color="primary"
      class="pa-5"
    >
      <v-form ref="newsForm" v-model="formValid">
        <v-card flat tile>
          <v-card-text>
            <v-row class="my-5">
              <v-col class="primary--text section-sub-title">
                {{ $route.name.toLowerCase().includes("offers") ? 'Aushang' : 'Neuigkeiten' }}-Infos
              </v-col>
            </v-row>
            <v-row class="my-5">
              <v-col>
                <v-autocomplete
                  label="Organisation"
                  outlined
                  dense
                  :items="$sortByProperty(organisations, 'name').filter(obj => obj.isActive)"
                  v-model="organisationId"
                  item-value="_id"
                  item-text="name"
                  color="secondary"
                  item-color="secondary"
                  :rules="[rules.required]"
                  :disabled="
                    !(
                      ac &&
                      user &&
                      ac.can(user.role).createAny('news').granted
                    )
                  "
                >
                  <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="my-5">
              <v-col>
                <v-autocomplete
                  outlined
                  dense
                  label="Autor"
                  :filter="$filterFullName"
                  :items="$sortByProperty(users, 'firstName').filter(obj => obj.isActive)"
                  v-model="authorId"
                  item-value="_id"
                  color="secondary"
                  item-color="secondary"
                  :rules="[rules.required]"
                  :disabled="
                    !(
                      ac &&
                      user &&
                      ac.can(user.role).createAny('news').granted
                    )
                  "
                >
                  <template slot="selection" slot-scope="data">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="my-5">
              <v-col class="primary--text section-sub-title">
                {{ $route.name.toLowerCase().includes("offers") ? 'Aushang' : 'Neuigkeiten' }}-Text
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  outlined
                  dense
                  label="Überschrift"
                  :rules="[rules.longText, rules.required]"
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mb-6">
                <tiptap-vuetify
                  placeholder="Text"
                  v-model="info"
                  label="Text"
                  :card-props="{ tile: true, flat: true, outlined: true }"
                  :extensions="extensions"
                >
                </tiptap-vuetify>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mb-6">
                <v-combobox
                  label="Schlagworte (Filter auf Organisations-Seite)"
                  outlined
                  dense
                  :items="[]"
                  v-model="tags"
                  multiple
                  chips
                  deletable-chips
                  item-value="_id"
                  item-text="name"
                  color="secondary"
                  item-color="secondary"
                >
                </v-combobox>
              </v-col>
            </v-row>
            <v-row>
            <template
              v-for="(link, i) in links"
            >
              <v-col :key="i+'desc'" class="py-2 body-1" align-self="center" cols="12" sm="5">
                {{link.url}}
              </v-col>
              <v-col :key="i+'url'" class="py-2 body-1" align-self="center" cols="12" sm="5">
                {{link.text}}
              </v-col>
              <v-col :key="i+'url'" class="py-2 body-1" align-self="center" cols="12" sm="1">
                <v-checkbox
                  dense
                  disabled
                  v-model="link.isVideo"
                  label="Video"
                >
                </v-checkbox>
              </v-col>
              <v-col :key="i" class="py-1 text-center" align-self="center" cols="12" sm="1">
                <v-btn
                  icon
                  color="secondary"
                  @click="removeLink(i)"
                >
                  <v-icon
                    small
                  >
                    fas fa-trash
                  </v-icon>
                </v-btn>
              </v-col>
              <div class="divider" :key="i+'divider'"></div>
            </template>
          </v-row>
          <v-row>
            <v-col class="py-4 pb-0" cols="12" sm="5">
              <v-text-field
                outlined
                dense
                label="Link-Url beginnend mit http / https"
                v-model="linkUrl"
                :rules="[rules.longText]"
                color="secondary"
                class="optional-field"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-4 pb-0" cols="12" sm="5">
              <v-text-field
                outlined
                dense
                label="Angezeigter Link-Text"
                v-model="linkText"
                :rules="[rules.longText]"
                color="secondary"
                class="optional-field"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-4 pt-1 pb-0" cols="12" sm="2">
              <v-checkbox
                color="secondary"
                dense
                label="Video"
                v-model="linkIsVideo"
              ></v-checkbox>
            </v-col>
            <div class="divider" :key="i+'divider'"></div>
            <v-col class="pt-5" cols="12">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :disabled="!linkUrl"
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="addLink()"
                >
                  Weitere
                  <v-icon
                    class="ml-3"
                    small
                  >
                    fas fa-plus
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
            <v-row class="my-5">
              <v-col class="primary--text section-sub-title">
                Bilder
              </v-col>
            </v-row>
            <v-row v-if="!news || organisation">
              <v-col cols="12">
                <Dropzone
                  :serverUri="computedServerUri + '/uploads/'"
                  uploadFilePath="temporary"
                  maxFiles=20
                  resizeWidth=1280
                  resizeHeight=720
                  maxFileSize=2
                  acceptedMimeTypes='image/png, image/jpeg'
                  :addFileMessage="($route.name.toLowerCase().includes('offers') ? 'Aushang' : 'Neuigkeiten') + '-Bilder'"
                  :convertFileName="true"
                  :existingFiles="computedExistingFiles"
                  :filesUploadedCallback="fileUploaded"
                  :fileRemovedCallback="fileRemoved"
                  :isInUse="showEditor"
                />
              </v-col>
            </v-row>
            <template v-if="$route.name.toLowerCase().includes('offers')">
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  Anzeige-Optionen
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="permanent"
                    label="Dauerhaft anzeigen"
                    color="secondary"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </template>
            <template v-if="
              user &&
              !$route.name.toLowerCase().includes('offers') &&
              (
                (news && (!organisationId || !['institution', 'sjr', 'cooperation'].includes(getOrganisation(organisationId).type))) ||
                !['institution', 'sjr', 'cooperation'].includes(getOrganisation(user.organisation).type)
                || (news && news.isAccepted === false)
              )
            ">
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  Freigabe
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-checkbox
                    dense
                    :disabled="
                      !ac.can(user.role).updateAny('news').granted
                    "
                    v-model="isAccepted"
                    label="Freigegeben"
                    color="secondary"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-card-actions
            class="px-4 pb-4"
          >
            <v-hover
              v-if="news"
              v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'error' : 'info'"
                class="white--text"
                @click="showDeleteDialog = true"
              >
                Löschen
              </v-btn>
            </v-hover>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :disabled="!formValid || processingSave || info === '' || info === '<p></p>'"
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                :loading="processingSave"
                @click="saveNews(false)"
              >
                Speichern
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-sheet>
    <v-dialog v-model="showDeleteDialog" max-width="350px">
      <v-card>
        <v-card-title>{{ $route.name.toLowerCase().includes("offers") ? 'Aushang' : 'Neuigkeit' }} löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showDeleteDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="processingDelete"
              :color="hover ? 'error' : 'info'"
              class="white--text"
              :loading="processingDelete"
              @click="deleteNews()"
            >
              Löschen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewConfirmationDialog" max-width="350px">
      <v-card>
        <v-card-title>Neuigkeit gespeichert</v-card-title>
        <v-card-text>Du erhältst eine Email, sobald deine Neuigkeit von einem Admin freigegeben wurde.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              :loading="processingDelete"
              @click="showNewConfirmationDialog = false"
            >
              Verstanden
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUpdateConfirmationDialog" max-width="350px">
      <v-card>
        <v-card-title>Neuigkeit speichern?</v-card-title>
        <v-card-text>Ein Admin muss deine Änderungen erst freigeben. Bis dahin wird deine Neuigkeit nicht öffentlich sichtbar sein.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showUpdateConfirmationDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'success' : 'info'"
              class="white--text"
              @click="() => { showUpdateConfirmationDialog = false; saveNews(true) }"
            >
              Speichern
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClient from '@/feathers-client'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from './Dropzone'
import { v4 as uuidv4 } from 'uuid'
import { TiptapVuetify, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
  name: 'NewsEditor',
  props: [
    'news',
    'showEditor'
  ],
  components: {
    Dropzone,
    TiptapVuetify
  },
  data () {
    return {
      ts: undefined,
      tags: [],
      pics: [],
      picsToUpload: [],
      uploadService: undefined,
      formValid: false,
      title: '',
      info: '',
      links: [],
      linkUrl: undefined,
      linkText: undefined,
      linkIsVideo: undefined,
      permanent: false,
      organisationId: null,
      authorId: null,
      organisation: null,
      processingDelete: false,
      processingSave: false,
      showDeleteDialog: false,
      showNewConfirmationDialog: false,
      showUpdateConfirmationDialog: false,
      isAccepted: false,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList
      ]
    }
  },
  mounted () {
    this.ts = Date.now()
    this.uploadService = feathersClient.service('uploads')
    this.adapt()
  },
  methods: {
    ...mapActions('news', {
      createNews: 'create',
      patchNews: 'patch',
      removeNews: 'remove',
      requestNews: 'get'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    adapt () {
      if (this.user) {
        if (this.news) {
          const pics = []
          JSON.parse(JSON.stringify(this.news.pics)).forEach((picName) => {
            pics.push({
              name: picName,
              id: uuidv4()
            })
          })
          if (this.ac.can(this.user.role).createAny('news').granted) {
            this.authorId = this.news.author
          } else {
            this.authorId = this.user._id
          }
          if (this.news.tags) {
            this.tags = this.news.tags
          }
          this.organisationId = this.news.organisation
          this.organisation = this.getOrganisation(this.news.organisation)
          this.title = this.news.title
          this.info = this.news.info
          this.pics = pics
          this.internal = this.news.internal
          this.permanent = this.news.permanent
          this.isAccepted = this.news.isAccepted
          this.links = this.news.links || []
        } else {
          this.$refs.newsForm.reset()
          this.$nextTick().then(() => {
            this.pics = []
            this.organisationId = this.user.organisation
            this.authorId = this.user._id
            this.isAccepted = this.$route.name.toLowerCase().includes('offers')
            this.info = undefined
            this.title = undefined
            this.links = []
          })
        }
        this.picsToUpload = []
      }
    },
    fileUploaded (newFile) {
      const hitIndex = this.picsToUpload.findIndex((file) => { return (file.id && (file.id === newFile.id)) })
      if (hitIndex === -1) {
        this.picsToUpload.push({
          name: newFile.name,
          id: newFile.id
        })
      } else {
        // Update name of existing file
        this.picsToUpload[hitIndex].name = newFile.name
      }
    },
    fileRemoved (file) {
      // Check temporary files first
      const indexHitTemp = this.picsToUpload.findIndex((value) => { return (value.id && (value.id === file.id)) })
      if (indexHitTemp !== -1) {
        this.picsToUpload.splice(indexHitTemp, 1)
        return
      }
      // Check existing files next
      const indexHitExist = this.pics.findIndex((value) => { return (value.id && (value.id === file.id)) })
      if (indexHitExist !== -1) {
        this.pics.splice(indexHitExist, 1)
      }
    },
    async saveNews (confirmed) {
      this.processingSave = true
      let needUpdateConfirmation
      let needCreateConfirmation
      if (this.news) {
        needCreateConfirmation = false
        needUpdateConfirmation = this.needUpdateConfirmation()
      } else {
        needUpdateConfirmation = false
        needCreateConfirmation = this.needCreateConfirmation()
      }
      if (this.linkUrl) {
        this.addLink()
      }
      // Check if confirmation will be neccessary (update)
      if (!confirmed) {
        if (needUpdateConfirmation) {
          if (this.checkDirty()) {
            this.processingSave = false
            this.showUpdateConfirmationDialog = true
            return
          }
        }
      }
      // Remove existing pics
      if (this.news) {
        const temporaryPics = this.news.pics
        const picNames = this.pics.map((pic) => { return pic.name })
        for (const existingPic of this.news.pics) {
          if (!picNames.includes(existingPic)) {
            const index = temporaryPics.indexOf(existingPic)
            temporaryPics.splice(index, 1)
            try {
              await this.uploadService.remove({ fileName: existingPic, path: 'pics/' + this.organisationId + '/news' })
              await this.patchNews([
                this.news._id,
                {
                  pics: temporaryPics
                },
                {}
              ])
            } catch (e) {
              this.createLog({ type: 'error', text: 'Remove old news pics: ' + e })
              this.processingSave = false
              this.showSnackbar({ mode: 'save', success: false })
              return
            }
          }
        }
      }
      // Create new pic path array
      let picPaths = this.picsToUpload.map((file) => { return file.name })
      if (this.news) {
        picPaths = picPaths.concat(this.pics.map((file) => { return file.name }))
      }
      // Move files and remove corrupted (e.g. missing files)
      if (this.news && (this.organisation._id !== this.organisationId)) {
        const corruptFileNames = await this.moveFiles()
        for (const corruptFileName of corruptFileNames) {
          const indexCorrupt = picPaths.indexOf(corruptFileName)
          if (indexCorrupt !== -1) {
            picPaths.splice(indexCorrupt, 1)
          }
        }
      }
      // Patch files (move)
      for (let i = 0; i < this.picsToUpload.length; i++) {
        try {
          await this.uploadService.patch(
            this.picsToUpload[i].name,
            {
              oldFilePath: 'temporary',
              newFilePath: 'pics/' + this.organisationId + '/news/',
              newFileName: this.picsToUpload[i].name
            }
          )
        } catch (e) {
          this.createLog({ type: 'error', text: 'Move news pics: ' + e })
        }
      }
      // Create map
      const map = {
        title: this.title,
        info: this.info,
        tags: this.tags,
        links: this.links,
        pics: picPaths,
        internal: this.$route.name.toLowerCase().includes('offers'),
        permanent: this.permanent
      }
      // Additional check on update
      if (this.news) {
        if (this.organisation._id !== this.organisationId) {
          map.organisation = this.organisationId
        }
        if (this.news.author !== this.authorId) {
          map.author = this.authorId
        }
      } else {
        map.organisation = this.organisationId
        map.author = this.authorId
      }
      // Accept
      if (this.ac.can(this.user.role).updateAny('news').granted) {
        map.isAccepted = this.isAccepted
      }
      // Update or create
      try {
        if (this.news) {
          await this.patchNews([
            this.news._id,
            map,
            {}
          ])
        } else {
          await this.createNews(map)
        }
      } catch (e) {
        this.createLog({ type: 'error', text: 'Patch / create news: ' + e + ' ' + JSON.stringify(map) })
        this.processingSave = false
        this.showSnackbar({ mode: 'save', success: false })
        return
      }

      this.picsToUpload.length = 0
      this.processingSave = false
      // Check if confirmation will be neccessary (create)
      if (needCreateConfirmation) {
        this.showNewConfirmationDialog = true
      } else {
        this.showSnackbar({ mode: 'save', success: true })
      }
      this.$closeEditor(this.$route, this.$router)
    },
    async deleteNews () {
      this.processingDelete = true
      for (const existingPic of this.news.pics) {
        try {
          await this.uploadService.remove({ fileName: existingPic, path: 'pics/' + this.organisationId + '/news' })
        } catch (e) {
          this.createLog({ type: 'error', text: 'Delete news pics: ' + e })
        }
      }
      try {
        await this.removeNews([
          this.news._id,
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Delete news: ' + e })
        this.processingDelete = false
        this.showSnackbar({ mode: 'delete', success: false })
        return
      }
      this.processingDelete = false
      this.showDeleteDialog = false
      this.showSnackbar({ mode: 'delete', success: true })
      this.$closeEditor(this.$route, this.$router)
    },
    checkDirty () {
      if (this.news) {
        if (this.news.title !== this.title) return true
        if (this.news.info !== this.info) return true
        if (!this.arraysEqual(this.news.pics, this.pics)) return true
        if (this.picsToUpload.length !== 0) return true
      }
      return false
    },
    needUpdateConfirmation () {
      if (this.news && this.news.isAccepted === false) {
        return false
      }
      if (this.$route.name.toLowerCase().includes('offers')) {
        return false
      }
      if (!this.ac.can(this.user.role).updateAny('news').granted) {
        if (this.ac.can(this.user.role).updateOwn('news').granted) {
          if (this.news.organisation === this.user.organisation) {
            const userOrganisation = this.getOrganisation(this.user.organisation)
            if (userOrganisation) {
              if (userOrganisation.type === 'sjr') return false
              if (userOrganisation.type === 'cooperation') return false
              if (userOrganisation.type === 'institution') return false
            }
          }
        }
        return true
      }
      return false
    },
    needCreateConfirmation () {
      if (this.$route.name.toLowerCase().includes('offers')) {
        return false
      }
      if (!this.ac.can(this.user.role).createAny('news').granted) {
        if (this.ac.can(this.user.role).createOwn('news').granted) {
          if (this.organisationId === this.user.organisation) {
            const userOrganisation = this.getOrganisation(this.user.organisation)
            if (userOrganisation) {
              if (userOrganisation.type === 'sjr') return false
              if (userOrganisation.type === 'cooperation') return false
              if (userOrganisation.type === 'institution') return false
            }
          }
        }
        return true
      }
      return false
    },
    arraysEqual (a, b) {
      if (a === b) return true
      if (a === null || b === null) return false
      if (a.length !== b.length) return false

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
      }
      return true
    },
    async moveFiles () {
      const corruptFileNames = []
      if (this.news && (this.organisation._id !== this.organisationId)) {
        for (const existingPic of this.news.pics) {
          try {
            const result = await this.uploadService.patch(
              existingPic,
              {
                oldFilePath: 'pics/' + this.organisation._id + '/news',
                newFilePath: 'pics/' + this.organisationId + '/news/',
                newFileName: existingPic
              }
            )
            if (!result) {
              corruptFileNames.push(existingPic)
            }
          } catch (e) {
            this.createLog({ type: 'error', text: 'Move news pics: ' + e })
            corruptFileNames.push(existingPic)
          }
        }
      }
      return corruptFileNames
    },
    addLink () {
      this.links.push(
        {
          url: this.linkUrl,
          text: this.linkText,
          isVideo: this.linkIsVideo
        }
      )
      this.linkUrl = ''
      this.linkText = ''
      this.linkIsVideo = false
    },
    removeLink (i) {
      this.links.splice(i, 1)
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('organisations', {
      organisations: 'list',
      getOrganisation: 'get'
    }),
    ...mapGetters('users', {
      users: 'list'
    }),
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    },
    computedExistingFiles () {
      const existingFiles = []
      if (this.news) {
        for (let i = 0; i < this.pics.length; i++) {
          existingFiles.push(
            {
              name: this.pics[i].name,
              id: this.pics[i].id,
              filePath: '/pics/' + this.organisation._id + '/news/'
            }
          )
        }
      }
      return existingFiles
    }
  },
  watch: {
    '$route.params.id' () {
      this.adapt()
    },
    showEditor () {
      if (!this.showEditor) {
        this.$refs.newsForm.reset()
      }
    },
    info () {
      if (this.info) {
        this.info = this.$sanitize(this.info)
      }
    }
  }
}
</script>
