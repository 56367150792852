<template>
  <div>
    <vue-headful
      title="SJR Ulm - Was wir machen"
      description="Erfahrt mehr über Leistungen, Angebote, Einrichtungen und Kooperationen des STadtjugendrings Ulm e.V."
    />
    <TitleToolbar
      v-bind:title="title"
    />
    <Header
      headline="DAS SIND WIR & WIR SIND VIELE"
      headlineSubtitle="In Ergänzung zu den Programmen unserer Mitglieder hat der Stadtjugendring auch eigene Angebote für Kinder und Jugendliche. Wir gestalten Schul-Projekte, organisieren Ferien-Kurse oder informieren auf Veranstaltungen und Messen über aktuelle Themen."
      imageSource="/img/was-wir-machen.jpg"
      backgroundColor="#009BCC"
    />
    <!-- Editor -->
    <v-toolbar
      v-if="
        user &&
        ac &&
        (
          ac.can(user.role).createAny('organisation').granted
        )
      "
      color="primary"
    >
      <v-btn
        text
        color="info"
        @click="$router.push($route.path + '/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>Neue Einrichtung / Kooperation</span>
      </v-btn>
    </v-toolbar>
    <TitleToolbar
      title="UNSERE EINRICHTUNGEN"
      color="white"
    />
    <v-container
      fluid
      class="px-6 mb-6"
    >
      <v-row dense>
        <OrganisationContainer
          v-for="organisation in computedInstitutions"
          :key="organisation._id + organisation.updatedAt"
          :organisation="organisation"
        />
      </v-row>
    </v-container>
    <TitleToolbar
      title="UNSERE KOOPERATIONEN"
      color="white"
    />
    <v-container
      fluid
      class="px-6 mb-6"
    >
      <v-row dense>
        <OrganisationContainer
          v-for="organisation in computedCooperations"
          :key="organisation._id + organisation.updatedAt"
          :organisation="organisation"
        />
      </v-row>
    </v-container>
    <TitleToolbar
      title="UNSERE LEISTUNGEN"
      color="white"
    />
    <v-container
      fluid
      class="px-5 mb-6"
    >
      <v-row>
        <v-col>
          <ul>
            <li>Wir vertreten die Interessen und Bedarfe von Kindern und Jugendlichen in der Stadt.</li>
            <li>Wir vernetzen, unterstützen und fördern sowohl finanziell als auch mit knowhow.</li>
            <li>Wir verstehen uns als Förderer und Stifter von Innovationen und als Plattform neuer, zeitgemäßer Ideen.</li>
            <li>Wir erbringen essentielle Arbeit und Prävention zum Kinder- und Jugendschutz.</li>
            <li>Wir gestalten politische Prozesse und Jugendhilfeplanung aktiv mit.</li>
            <li>Wir fördern und fordern das ehrenamtliche Engagement junger Menschen.</li>
            <li>Wir bringen Jugendarbeit und Jugendkultur im öffentlichen Raum stetig voran.</li>
            <li>Wir leisten gemeinsam einen nachhaltig positiven Beitrag zum Angebot und Image der Stadt Ulm.</li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            title="FÖRDERUNG"
            subTitle="Eine der Kernaufgaben des Stadtjugendrings ist die Förderung der Jugendarbeit in Ulm."
            description="Ihr habt eine Idee, aber kein Geld? Deine Gruppe plant ein Projekt, euch fehlen aber die Mittel? Ihr wollt euch fortbilden und benötigt dafür einen Zuschuss? Ihr plant und organisiert Ferienprogramm oder Freizeiten?"
            text="
              <ul>
                <li>Möglichkeit auf Zuschüsse und finanzielle Unterstützung für Veranstaltungen, Fortbildungen, Projekte, Versicherungen, Anschaffungen, Freizeiten, Materialien etc.</li>
                <li>Förderung von Freizeitmaßnahmen und Fortbildungen</li>
                <li>Stetige Realisierung und Entwicklung von schulischen und außerschulischen Projekten für Kinder und Jugendliche in der Stadt</li>
                <li>Ein starkes und stabiles Netzwerk aus derzeit 61 Mitglieder, mit insgesamt über 25.000 Kindern und Jugendlichen</li>
                <li>Politische Interessenvertretung und Gremienarbeit</li>
              </ul>
            "
            backgroundImageSource="/img/img-foerderung.jpg"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            title="BERATUNG"
            subTitle="Wir beraten und unterstützen Vereine, Jugendgruppen, junge Menschen, die aktiv werden wollen und so zu einer offenen und pluralistischen Gesellschaft beitragen."
            description="Wenn Du Unterstützung bei der Umsetzung Deiner Projekte und Ideen brauchst: Kontaktiere uns gerne per Mail an info@sjr-ulm.de"
            text="
              <ul>
                <li>Hilfe, Unterstützung und Vermittlung bei rechtlichen oder bürokratischen Fragen oder Problemen wie Versicherungen, Haftung, Anträgen, Gestattungen etc.</li>
                <li>Beratung, Unterstützung und Hilfeleistung beim Umgang mit neuen Verordnungen oder Gesetzgebungen</li>
                <li>Gruppen- und Einzelberatungen</li>
                <li>Ein stets offenes Ohr für sämtliche Fragen und ein hohes Maß an Bereitschaft diese auch zu beantworten</li>
              </ul>
            "
            backgroundImageSource="/img/img-beratung.jpg"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            title="SERVICE & VERLEIH"
            description="Spielgeräte für Freizeiten oder Sommerfest? Fahrzeuge für den Team-Ausflug oder die Band-Tour: der SJR hat’s!"
            text="
              <ul>
                <li>Verleih von PKW über Spielgeräte bis hin zu Musikinstrumenten und digitalen Medien</li>
                <li>Unterstützung unserer Mitlgieder beim Aufbau einer nachhaltigen und effektiven Struktur</li>
                <li>Hilfestellung bei Digitalisierungsprozessen</li>
              </ul>
            "
            backgroundImageSource="/img/img-verleih.jpg"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            title="RÄUME &   SONSTIGE LEISTUNGEN"
            description="Dein Verein sucht ein zu Hause? Eure Band braucht Platz zum Proben? Dann bist Du hier richtig!"
            text="
              <ul>
                <li>Wir verwalten über 8500qm an Räumen für Jugendarbeit</li>
                <li>Maßnahmen, Schulungen und Fortbilungen</li>
                <li>Eine Vielzahl an Kooperationsprojekten</li>
              </ul>
            "
            backgroundImageSource="/img/img-verwaltung.jpg"
          />
        </v-col>
      </v-row>
    </v-container>
    <TitleToolbar
      title="Belegungsplan Bus"
      color="white"
    />
    <v-toolbar
      flat
      color="primary"
      v-if="
        user &&
        ac &&
        (
          ac.can(user.role).createAny('rental').granted
        )
      "
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="showRentalsEditorDialog = true"
      >
        <v-icon
          small
          class="mr-3"
        >
          fas fa-plus
        </v-icon>
        <span>Neuen Eintrag hinzufügen</span>
      </v-btn>
    </v-toolbar>
    <v-container fluid class="px-8 mb-6">
      <v-row>
        <v-col
          cols="12"
        >
          <v-sheet>
            <v-row class="mx-0">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  class="mr-4 white--text"
                  :color="hover ? 'secondary' : 'info'"
                  @click="setToday"
                >
                  Heute
                </v-btn>
              </v-hover>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  fab
                  text
                  small
                  :color="hover ? 'secondary' : 'info'"
                  @click="prev"
                >
                  <v-icon
                    small
                  >
                    fas fa-chevron-left
                  </v-icon>
                </v-btn>
              </v-hover>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  fab
                  text
                  small
                  :color="hover ? 'secondary' : 'info'"
                  @click="next"
                >
                  <v-icon
                    small
                  >
                    fas fa-chevron-right
                  </v-icon>
                </v-btn>
              </v-hover>
              <span class="text-uppercase title mt-1 ml-3">{{ computedCalendarTitle }}</span>
              <v-spacer></v-spacer>
              <v-menu
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon
                        small
                        right
                      >
                        fas fa-chevron-down
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Tag</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Woche</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Monat</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet height="450">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="info"
              :events="computedRentals"
              event-color="warning"
              :type="type"
              @click:date="viewDay"
              @click:event="editRental"
              @change="updateRange"
              :event-name="rentalName"
              :weekdays="[1,2,3,4,5,6,0]"
            ></v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showRentalsEditorDialog"
      max-width="450px"
    >
      <v-card
        tile
        flat
      >
        <v-form v-model="rentalEditorFormValid" ref="rentalEditorForm">
          <v-card-text>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  icon
                  @click="showRentalsEditorDialog = false"
                >
                  <v-icon>
                    fas fa-times
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-dialog
                  ref="datesDialog"
                  v-model="showDatesDialog"
                  :return-value.sync="dates"
                  persistent
                  width="320px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      color="secondary"
                      :value="computedDates"
                      label="Zeitraum"
                      append-icon="fas fa-calendar-day"
                      readonly
                      id="datesInput"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="showDatesDialog"
                    v-model="dates"
                    full-width
                    format="24hr"
                    :min="$moment().format('YYYY-MM-DD')"
                    color="secondary"
                    range
                    first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'warning' : 'info'"
                        class="white--text"
                        @click="showDatesDialog = false"
                      >
                        Abbrechen
                      </v-btn>
                    </v-hover>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="$refs.datesDialog.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-hover>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-dialog
                  ref="startTimeDialog"
                  v-model="showStartTimeDialog"
                  :return-value.sync="startTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense outlined
                      color="secondary"
                      v-model="startTime"
                      label="Beginn der Vermietung"
                      append-icon="fas fa-clock"
                      readonly
                      id="startTimeInput"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="showStartTimeDialog"
                    v-model="startTime"
                    full-width
                    format="24hr"
                    color="secondary"
                  >
                    <v-spacer></v-spacer>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'warning' : 'info'"
                        class="white--text"
                        @click="showStartTimeDialog = false"
                      >
                        Abbrechen
                      </v-btn>
                    </v-hover>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="$refs.startTimeDialog.save(startTime)"
                      >
                        OK
                      </v-btn>
                    </v-hover>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-dialog
                  ref="endTimeDialog"
                  v-model="showEndTimeDialog"
                  :return-value.sync="endTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      outlined
                      color="secondary"
                      v-model="endTime"
                      label="Ende der Vermietung"
                      append-icon="fas fa-clock"
                      readonly
                      v-on="on"
                      id="endTimeInput"
                      :rules="[
                        () => {
                          if (
                            (dates.length === 2) &&
                            !($moment(dates[1]).isAfter($moment(dates[0]))) &&
                            ($moment($moment().format('YYYY-MM-DD') + ' ' + startTime).isAfter(
                              $moment().format('YYYY-MM-DD') + ' ' + endTime)
                            )
                          ) {
                            return 'Endzeit liegt vor Startzeit.'
                          } else {
                            return true
                          }
                        },
                        () => {
                          if (
                            (dates.length === 1) &&
                            ($moment($moment().format('YYYY-MM-DD') + ' ' + startTime).isAfter(
                              $moment().format('YYYY-MM-DD') + ' ' + endTime)
                            )
                          ) {
                            return 'Endzeit liegt vor Startzeit.'
                          } else {
                            return true
                          }
                        },
                        rules.required
                      ]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="showEndTimeDialog"
                    v-model="endTime"
                    full-width
                    format="24hr"
                    color="secondary"
                  >
                    <v-spacer></v-spacer>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'warning' : 'info'"
                        class="white--text"
                        @click="showEndTimeDialog = false"
                      >
                        Abbrechen
                      </v-btn>
                    </v-hover>
                    <v-hover
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="$refs.endTimeDialog.save(endTime)"
                      >
                        OK
                      </v-btn>
                    </v-hover>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  dense
                  outlined
                  label="Vermietet an"
                  :items="users"
                  v-model="by"
                  :rules="[rules.required]"
                  :filter="$filterFullName"
                >
                  <template slot="selection" slot-scope="data">
                    <template v-if="data.item.firstName">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                    <template v-else>
                      {{ data.item }}
                    </template>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-card-actions class="px-0">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  v-if="selectedRental"
                  tile
                  depressed
                  :color="hover ? 'error' : 'info'"
                  class="white--text"
                  @click="prepareRemoveRental(selectedRental)"
                >
                  Löschen
                </v-btn>
              </v-hover>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :color="hover ? 'warning' : 'info'"
                  class="white--text"
                  @click="showRentalsEditorDialog = false"
                >
                  Abbrechen
                </v-btn>
              </v-hover>
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :disabled="!rentalEditorFormValid"
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="saveRental()"
                >
                  Speichern
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showEditorDialog"
      fullscreen
      v-show="
          ac &&
          user &&
          (
            $route.params.id !== 'neu' &&
            (
              ac.can(user.role).updateAny('organisation').granted ||
              (
                ac.can(user.role).updateOwn('organisation').granted &&
                $route.params.id === user.organisation
              )
            ) ||
            (
              $route.params.id === 'neu' &&
              (
                ac.can(user.role).createAny('organisation').granted
              )
            )
          )
        "
    >
      <OrganisationEditor
        :organisation="getOrganisation($route.params.id)"
        :showEditor="showEditorDialog"
      />
    </v-dialog>
  </div>
</template>

<script>

import Header from '@/components/Header'
import TitleToolbar from '@/components/TitleToolbar'
import ImageTextTileCol from '@/components/ImageTextTileCol'
import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import OrganisationEditor from '@/components/OrganisationEditor'
import OrganisationContainer from '@/components/OrganisationContainer'

export default {
  name: 'WhatWeDo',
  mixins: [makeFindMixin({ service: 'rentals', watch: true })],
  data: () => ({
    title: 'WAS WIR MACHEN',
    showRentalsEditorDialog: false,
    startTime: null,
    showStartTimeDialog: false,
    endTime: null,
    showEndTimeDialog: false,
    showDatesDialog: false,
    dates: [],
    by: null,
    rentalEditorFormValid: true,
    selectedRental: undefined,
    focus: '',
    today: null,
    type: 'month',
    typeToLabel: {
      month: 'Monat',
      week: 'Woche',
      day: 'Tag'
    },
    start: null,
    end: null,
    showEditorDialog: false
  }),
  components: {
    Header,
    TitleToolbar,
    ImageTextTileCol,
    OrganisationEditor,
    OrganisationContainer
  },
  mounted () {
    this.adaptParams()
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('organisations', {
      organisations: 'list',
      getOrganisation: 'get'
    }),
    ...mapGetters('users', {
      users: 'list'
    }),
    computedInstitutions () {
      return this.$sortByProperty(
        this.organisations.filter(obj => obj.isActive && obj.type === 'institution'),
        'name'
      )
    },
    computedCooperations () {
      return this.$sortByProperty(
        this.organisations.filter(obj => obj.isActive && obj.type === 'cooperation'),
        'name'
      )
    },
    computedDates () {
      const tmpString = this.dates.map(obj => this.$moment(obj).format('DD.MM.YYYY'))
      return tmpString.join(' bis ')
    },
    rentalsParams () {
      return { query: { } }
    },
    computedRentals () {
      if (this.rentals) {
        return this.rentals.map(obj => {
          obj.start = this.$moment(obj.start).format('YYYY-MM-DD HH:mm')
          obj.end = this.$moment(obj.end).format('YYYY-MM-DD HH:mm')
          return obj
        })
      } else {
        return []
      }
    },
    computedCalendarTitle () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }
      switch (this.type) {
        case 'month':
          return this.$moment(start.date).format('MMM YYYY')
        case 'week':
          if (this.$moment(start.date).isSame(this.$moment(end.date), 'year')) {
            if (this.$moment(start.date).isSame(this.$moment(end.date), 'month')) {
              return this.$moment(start.date).format('DD.') + ' - ' + this.$moment(end.date).format('DD. MMM YYYY')
            } else {
              return this.$moment(start.date).format('DD. MMM') + ' - ' + this.$moment(end.date).format('DD. MMM YYYY')
            }
          } else {
            return this.$moment(start.date).format('DD. MMM YYYY') + ' - ' + this.$moment(end.date).format('DD. MMM YYYY')
          }
        case 'day':
          return this.$moment(start.date).format('dddd, DD. MMM YYYY')
      }
      return ''
    }
  },
  methods: {
    ...mapActions('rentals', {
      createRental: 'create',
      patchRental: 'patch',
      removeRental: 'remove'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    openEditor (organisation) {
      this.showEditorDialog = true
    },
    closeEditor () {
      this.showEditor = false
      this.showEditorDialog = false
    },
    rentalName (e) {
      if (this.$moment(e.start.date).isSame(this.$moment(e.end.date), 'day')) {
        return e.start.time + ' - ' + e.end.time
      } else {
        return this.$moment(e.start.date).format('DD.MM') + ' ' + e.start.time + ' - ' + this.$moment(e.end.date).format('DD.MM') + ' ' + e.end.time
      }
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    updateRange ({ start, end }) {
      this.start = start
      this.end = end
    },
    async prepareRemoveRental (id) {
      await this.removeRental(id)
      this.showSnackbar({ mode: 'delete', success: true })
      this.showRentalsEditorDialog = false
    },
    async saveRental () {
      setTimeout(async () => {
        const start = this.$moment(this.dates[0] + ' ' + this.startTime)
        let end
        if (this.dates.length === 2) {
          end = this.$moment(this.dates[1] + ' ' + this.endTime)
        } else {
          end = this.$moment(this.dates[0] + ' ' + this.endTime)
        }
        let by
        if (this.by.firstName) {
          by = this.by.firstName + ' ' + this.by.lastName
        } else {
          by = this.by
        }
        const map = {
          start,
          end,
          by
        }
        if (this.selectedRental) {
          await this.patchRental([this.selectedRental, map, {}])
        } else {
          await this.createRental(map)
        }
        this.showSnackbar({ mode: 'save', success: true })
        this.showRentalsEditorDialog = false
      }, 1)
    },
    editRental (e) {
      if (
        this.ac &&
        this.user &&
        this.ac.can(this.user.role).updateAny('rental').granted
      ) {
        this.selectedRental = e.event._id
        this.startTime = this.$moment(e.event.start).format('HH:mm')
        this.endTime = this.$moment(e.event.end).format('HH:mm')
        this.dates = [this.$moment(e.event.start).format('YYYY-MM-DD'), this.$moment(e.event.end).format('YYYY-MM-DD')]
        this.by = e.event.by
        this.showRentalsEditorDialog = true
      }
    },
    adaptParams () {
      if (this.$route.params.id) {
        this.openEditor(this.getOrganisation(this.$route.params.id))
      } else {
        this.closeEditor()
      }
    },
    validateDatePicker () {
      if (this.showRentalsEditorDialog && (this.dates.length > 0) && this.startTime && this.endTime) {
        if (this.$refs.rentalEditorForm) {
          this.$refs.rentalEditorForm.validate()
        }
      }
    }
  },
  watch: {
    showRentalsEditorDialog () {
      if (!this.showRentalsEditorDialog) {
        this.startTime = null
        this.endTime = null
        this.dates = []
        this.selectedRental = undefined
        this.$refs.rentalEditorForm.reset()
      }
    },
    '$route.params.id' () {
      this.adaptParams()
    },
    dates () {
      if (this.dates.length === 2) {
        if (this.$moment(this.dates[0]).isAfter(this.$moment(this.dates[1]))) {
          const tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      this.validateDatePicker()
    },
    startTime () {
      this.validateDatePicker()
    },
    endTime () {
      this.validateDatePicker()
    },
    user () {
      this.findRentals()
    }
  }
}
</script>

<style>
  .v-calendar .v-event {
    border-radius: 0px !important;
    border: 2px solid transparent
  }
</style>>
