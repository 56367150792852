<template>
  <v-app v-show="show">
    <v-navigation-drawer
      touchless
      app
      temporary
      clipped
      :value="showNav"
      :mobile-breakpoint=0
      right
      width="100%"
      hide-overlay
      >
      <Nav/>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-right
      flat
      color="white"
    >
      <v-img
        @click="showIntro()"
        class="clickable"
        :src="$vuetify.breakpoint.smAndUp ? '/img/logo.svg' : '/img/logo_icon.svg'"
        max-height="40"
        contain
        position="left"
        :max-width="$vuetify.breakpoint.smAndUp ? 400 : 50"
      ></v-img>
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        v-if="isAuthenticated"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            color="primary"
          >
            <v-icon>fas fa-user-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="
              ac &&
              user &&
              ac.can(user.role).updateOwn(user.role).granted
            "
            @click="$router.push('/profil/editor/' + user._id)"
          >
            <v-list-item-title>Mein Zugangs-Profil bearbeiten</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push('/aushang')"
            v-if="
              ac &&
              user &&
              ac.can(user.role).readAny('news').granted
            "
          >
            <v-list-item-title>Schwarzes Brett</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              ac &&
              user &&
              ac.can(user.role).updateOwn('organisation').granted
            "
            @click="$router.push('/mitglieder/editor/' + user.organisation)"
          >
            <v-list-item-title>Meine Organisation bearbeiten</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              ac &&
              user &&
              ac.can(user.role).readOwn('events').granted
            "
            @click="$router.push({ name: 'events', params: { own: true } })"
          >
            <v-list-item-title>Meine Veranstaltungen</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              ac &&
              user &&
              ac.can(user.role).readOwn('events').granted
            "
            @click="$router.push({ name: 'news', params: { own: true } })"
          >
            <v-list-item-title>Meine Neuigkeiten</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push('/nutzer')"
            v-if="
              ac &&
              user &&
              ac.can(user.role).createAny('admin').granted
            "
          >
            <v-list-item-title>Nutzer verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push('/stimmberechtigte')"
            v-if="
              ac &&
              user &&
              ac.can(user.role).createAny('voter').granted
            "
          >
            <v-list-item-title>Stimmberechtigte verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push('/abstimmungen')"
            v-if="
              ac &&
              user &&
              ac.can(user.role).createAny('voting').granted
            "
          >
            <v-list-item-title>Abstimmungen verwalten</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="initLogout()"
            v-if="
              user
            "
          >
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        icon
        @click="setShowNav(!showNav)"
      >
        <v-icon
          color="primary"
        >
          {{showNav ? 'fas fa-times' : 'fas fa-bars'}}
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="mb-12">
        <router-view></router-view>
    </v-main>
    <Privacy/>
    <Imprint/>
    <Downloads></Downloads>
    <v-dialog
      tile
      max-width="500"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showLogin"
      persistent
    >
      <Login/>
    </v-dialog>
    <v-dialog
      tile
      max-width="500"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showErrorDialog"
    >
      <template v-if="showErrorDialog === '404'">
        <v-card
          tile
        >
          <v-card-text>
            <v-row dense>
              <v-col class="text-right mt-4">
                <v-btn
                  icon
                  @click="setShowErrorDialog(false)"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-icon size="80">fas fa-file</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="card-text text-center">
                Hoppla. Für diesen Bereich existiert leider kein Inhalt ...
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <template v-else-if="showErrorDialog === '403'">
        <v-card
          tile
        >
          <v-card-text>
            <v-row dense>
              <v-col class="text-right mt-4">
                <v-btn
                  icon
                  @click="setShowErrorDialog(false)"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-icon size="80">fas fa-ban</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="!user" class="card-text text-center">
                Bitte logge dich ein um diesen Bereich aufrufen zu können.
              </v-col>
              <v-col v-else class="card-text text-center">
                Du verfügst nicht über die Berechtigung um diesen Bereich aufrufen zu können.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    tile
                    block
                    depressed
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    @click="toLogin()"
                  >
                    Zum Login
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <template v-else>
        <v-card
          tile
        >
          <v-card-text>
            <v-row dense>
              <v-col class="text-right mt-4">
                <v-btn
                  icon
                  @click="setShowErrorDialog(false)"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-icon size="80">fas fa-exclamation</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="card-text text-center">
                {{showErrorDialog}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-snackbar
      v-model="localSnackbarOpen"
      :timeout="snackbarTimeOut"
      :color="snackbarColor"
      style="z-index:1000000000"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-footer
      color="primary"
      dark
    >
      <v-row
        no-gutters
      >
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-col align-self="center" cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'footer-title my-1' : 'my-2 footer-title text-center'">
            © {{ $moment().format('YYYY')}} STADTJUGENDRING ULM E.V.
          </v-col>
          <v-col align-self="center" :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'">
            <v-spacer></v-spacer>
            <span class="clickable info--text footer-item mr-3" @click="$router.replace({ query: { privacy: true }})">DATENSCHUTZ</span>
            <span class="clickable info--text footer-item mr-3" @click="$router.replace({ query: { imprint: true }})">IMPRESSUM</span>
            <span class="clickable info--text footer-item mr-3" @click="$router.replace({ query: { downloads: true }})">DOWNLOADS</span>
            <template v-if="!isAuthenticated">
              <span class="clickable info--text footer-item" @click="$router.replace({ query: { login: true } })" small text>LOGIN</span>
            </template>
            <template v-else>
              <span class="clickable info--text footer-item" @click="initLogout()" small text>LOGOUT</span>
            </template>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text-center">
            <v-btn
              icon
              color="white"
              @click="sheet = !sheet"
            >
              <v-icon>fas fa-chevron-up</v-icon>
            </v-btn>
          </v-col>
          <v-bottom-sheet v-model="sheet">
            <v-sheet tile color="primary" class="text-center">
              <v-col class="pa-0">
                <v-btn
                  icon
                  color="white"
                  @click="sheet = !sheet"
                  class="mt-3"
                >
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'footer-title white--text' : 'footer-title white--text'">
                © {{ $moment().format('YYYY')}} STADTJUGENDRING ULM E.V.
              </v-col>
              <v-col class="clickable info--text footer-item mr-3" @click="$router.replace({ query: { privacy: true }})">DATENSCHUTZ</v-col>
              <v-col class="clickable info--text footer-item mr-3" @click="$router.replace({ query: { imprint: true }})">IMPRESSUM</v-col>
              <v-col class="clickable info--text footer-item mr-3" @click="$router.replace({ query: { downloads: true }})">DOWNLOADS</v-col>
              <template v-if="!isAuthenticated">
                <v-col class="clickable info--text footer-item" @click="$router.replace({ query: { login: true } })" small text>LOGIN</v-col>
              </template>
              <template v-else>
                <v-col class="clickable info--text footer-item" @click="initLogout()" small text>LOGOUT</v-col>
              </template>
            </v-sheet>
          </v-bottom-sheet>
        </template>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Login from './components/Login'
import Nav from './components/Nav'
import Privacy from './components/Privacy'
import Imprint from './components/Imprint'
import Downloads from './components/Downloads'

import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Login,
    Nav,
    Privacy,
    Imprint,
    Downloads
  },
  data: () => {
    return {
      snackbarTimeOut: 5000,
      localSnackbarOpen: false,
      sheet: false
    }
  },
  mounted () {
    window.addEventListener('storage', this.onStorageUpdate)
  },
  computed: {
    ...mapGetters([
      'ac',
      'showNav',
      'showLogin',
      'showErrorDialog',
      'show',
      'snackbarOpen',
      'snackbarText',
      'snackbarColor'
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
      'user'
    ]),
    storeSnackbarOpen () {
      return this.snackbarOpen
    }
  },
  methods: {
    ...mapMutations({
      setShowLogin: 'SET_SHOW_LOGIN',
      setShowErrorDialog: 'SET_SHOW_ERROR_DIALOG',
      setShowNav: 'SET_SHOW_NAV',
      setShowPrivacy: 'SET_SHOW_PRIVACY',
      setShowImprint: 'SET_SHOW_IMPRINT',
      setShowDownloads: 'SET_SHOW_DOWNLOADS',
      setShow: 'SET_SHOW',
      setSnackbarOpen: 'SET_SNACKBAR_OPEN',
      setSnackbarText: 'SET_SNACKBAR_TEXT',
      setSnackbarColor: 'SET_SNACKBAR_COLOR',
      setIntro: 'SET_INTRO'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('auth', [
      'logout'
    ]),
    showIntro () {
      this.setIntro(true)
      this.$router.push('/')
    },
    onStorageUpdate (event) {
      if (event.key === 'feathers-jwt' && event.newValue === null) {
        this.initLogout()
      }
    },
    async initLogout () {
      await this.$router.push('/')
      this.setShow(false)
      try {
        await this.logout()
      } catch (e) {
        this.createLog({ type: 'error', text: 'Logout: ' + e })
      }
      location.reload()
    },
    toLogin () {
      this.setShowErrorDialog(false)
      this.setShowLogin(true)
    },
    closeSnackbar () {
      this.localSnackbarOpen = false
      this.setSnackbarText('')
      this.setSnackbarColor('')
    }
  },
  watch: {
    '$route.query': {
      handler () {
        for (const query of Object.keys(this.$route.query)) {
          const command = 'setShow' + query[0].toUpperCase() + query.substr(1, query.length).toLowerCase()
          if (query !== 's' && query !== 'organisation') {
            this.setShowNav(false)
            this[command](this.$route.query[query])
          }
        }
      },
      deep: true
    },
    storeSnackbarOpen (newValue, oldValue) {
      if (newValue === true) {
        this.localSnackbarOpen = true
      }
    },
    localSnackbarOpen (newValue, oldValue) {
      if (newValue === false) {
        this.setSnackbarOpen(false)
      }
    },
    show () {
      if (this.show === true) {
        setTimeout(() => {
          document.dispatchEvent(new Event('render-trigger'))
        }, 1000)
      }
    }
  }
}
</script>

<style>

  @import '~@/assets/main.css';

  .divider {
    width: 100%;
    border-bottom: 1px solid #b3c1cc;
    opacity: 0.7;
    margin-bottom: 1px;
    height: 1px;
    margin-top: 0px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .clickable {
    cursor: pointer;
  }

  .v-dialog, .v-input {
    border-radius: 0px !important;
  }

  .v-picker__title__btn.v-date-picker-title__date.v-picker__title__btn--active {
    font-family: "Nexa-XBold";
    text-transform: uppercase;
  }

  .menu-intro-item {
    font-family: "Nexa-Black";
    font-size: 102px;
    line-height: 0.98;
    letter-spacing: 4px;
  }

  .menu-intro-item.mobile {
    font-size: 60px;
    letter-spacing: 3px;
  }

  .menu-item {
    font-family: "Nexa-Black";
    font-size: 40px;
    line-height: 1;
    letter-spacing: 2px;
  }

  .menu-item.mobile {
    font-size: 22px;
  }

  .footer-title {
    font-family: "Nexa-Black";
    line-height: 1.42;
    letter-spacing: 1px;
    font-size: 12px;
    cursor: default;
  }

  .footer-item {
    font-family: "Nexa-Black";
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: 1px;
  }

  .section-title {
    font-family: "Nexa-Black";
    font-size: 21px;
    line-height: 22px;
    letter-spacing: 1.5px;
  }

  .section-sub-title {
    font-family: "Nexa-Black";
    font-size: 16px;
    line-height: 1.06;
    letter-spacing: 1.33px;
  }

  .section-sub-title-secondary {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .v-btn, .card-text {
    font-family: "Nexa-XBold";
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.5px;
  }

  .tag {
    font-family: "Nexa-Black";
    font-size: 14px;
    font-weight: 900;
    line-height: 1.29;
    letter-spacing: 1px;
  }

  .card-text.regular {
    font-family: "Nexa-Regular"
  }

  .card-title {
    font-family: "Nexa-Heavy";
    font-size: 16px;
    font-weight: 900;
    line-height: 1.13;
  }

  .timeline-item-description {
    font-family: "Nexa-Black";
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .timeline-item-title {
    font-family: "Nexa-XBold";
    font-size: 21px;
    line-height: 1.14;
  }

  .item-body {
    opacity: 0.5;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.29;
  }

  .timeline-item-time {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.25;
  }

  .timeline-item-date {
    font-family: "Nexa-Black";
    font-size: 16px;
    line-height: 1.19;
  }

  .goal-item-text {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
  }

  .recognised-provider-item-text {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }

  .team-item-primary {
    font-family: "Nexa-Black";
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
  }

  .team-item-name {
    letter-spacing: 1px;
  }

  .team-item-position {
    letter-spacing: normal;
  }

  .team-item-secondary {
    font-family: Roboto;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
  }

  .team-item-mail {
    font-weight: bold;
  }

  .main-committee-primary {
    font-family: "Nexa-Black";
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1px;
  }

  .main-committee-secondary {
    font-family: "Nexa-Bold";
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
  }

  .statutes {
    font-family: Roboto;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
  }

  .statutes-title {
    font-weight: bold;
  }

  .services-title {
    font-family: "Nexa-Black";
    font-size: 32px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: 1px;
  }

  .services-secondary-title {
    display: block;
    font-family: "Nexa-XBold";
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  .services-sub-title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .services-description {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
  }

  .members-list-item-title {
    font-family: "Nexa-XBold";
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: 0.57px;
  }

  .event-box-tag:not(:last-child) {
    margin-right: 8px;
  }

  .event-detail-date {
    font-family: "Nexa-Regular";
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .event-detail-date-bold {
    font-family: "Nexa-Black";
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .header-title {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    font-family: "Nexa-Black";
    font-size: 48px;
    line-height: 1.15;
    letter-spacing: 1.5px;
  }

  .header-title.mobile {
    font-size: 38px;
  }

  .header-pre-title {
    font-family: "Nexa-XBold";
    font-size: 28px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .header-sub-title {
    font-family: "Nexa-XBold";
    font-size: 21px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .header-body {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
    font-family: "Nexa-XBold";
    font-size: 18px;
    line-height: 1.22;
  }

  .flexible-toolbar,
  .flexible-toolbar .v-toolbar__content {
    min-height: 56px !important;
    height: auto !important;
    box-sizing: content-box !important;
  }

  .action-icon-button {
    border-width: 2px;
  }
  .action-icon-button-active {
    border-width: 0px;
    background-color: #003151;
  }
  .action-icon-button-active .v-btn__content > span::before {
    color: white;
  }

  /* Arrow-Buttons: Icongroesse & Positionskorrektur wegen Delimiter */
  .flat-carousel .v-window__container button i {
    font-size: 24px !important;
  }

  .hidden .v-input__slot {
    opacity: 0;
    height: 0px;
  }

  .pretext {
    white-space: pre-wrap;
  }

  span.optional-field::after,
  .optional-field label::after {
    content: " (optional)";
    display: inline-block;
    padding-left: 4px;
    font-size: 75%;
    font-style: italic;
  }

  .no-overflow {
    overflow: hidden;
  }

  .rotate {
    transform: rotate(-10deg);
  }

  .permanent-avatar {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    line-height: 0.85em !important;
  }

  .event-box-image-grayscaled {
    filter: grayscale(1);
  }
  .event-box-logo {
    position: absolute;
    top: 0px;
    right: 0%;
    transition: right 100ms ease-in-out;
    -webkit-transition: right 100ms ease-in-out;
  }
  .event-box-logo-hover {
    right: -20%;
  }

  .v-card--outlined {
    border: 1px solid #b3c1cc !important;
  }

  p, ul, ol {
    width: 100% !important;
  }

  li > p {
    margin-bottom: 6px !important;
  }

  ul, ol {
    margin-bottom: 18px !important;
  }

</style>
