<template>
  <v-dialog
    v-model="showImprint"
    fullscreen
  >
    <v-sheet
      dark
      height="100%"
      width="100%"
      color="primary"
      tile
      style="overflow-y: scroll;"
    >
      <v-container fluid class="px-6">
        <v-row>
          <v-toolbar
            color="transparent"
            flat
          >
            <span class="section-title">IMPRESSUM</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            large
            @click="$router.replace({ query: { imprint: undefined } })"
          >
            <v-icon color="white">fas fa-times</v-icon>
          </v-btn>
          </v-toolbar>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Angaben (gemäß § 5 TMG)
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Stadtjugendring Ulm e.V.
            </v-row>
            <v-row no-gutters>
              Schillerstraße 1/4
            </v-row>
            <v-row no-gutters>
              89077 Ulm
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Vertretung
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Georg Baier
            </v-row>
            <v-row no-gutters>
              Kathrin Przewodnik
            </v-row>
            <v-row no-gutters>
              Lucia Geitmann
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Vereinsregistereintrag
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Amtsgericht Ulm
            </v-row>
            <v-row no-gutters>
              Registernummer VR 482
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Verantwortlich für den Inhalt (nach § 55 Abs. 2 RStV)
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Achim Spannagel
            </v-row>
            <v-row no-gutters>
              Stadtjugendring Ulm e.V.
            </v-row>
            <v-row no-gutters>
              Schillerstr. 1/4
            </v-row>
            <v-row no-gutters>
              89077 Ulm
            </v-row>
            <v-row no-gutters>
              Telefon: 0731-14069-17
            </v-row>
            <v-row no-gutters>
              E-Mail: spannagel@sjr-ulm.de
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4">
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Fotos: Organisationsteam SJR Ulm
            </v-row>
            <v-row no-gutters>
              Gestaltung:<a target="_blank" class="ml-1 white--text" href="https://bootschaft.de">Bootschaft GbR</a>
            </v-row>
            <v-row no-gutters>
              Programmierung:<a target="_blank" class="ml-1 white--text" href="https://widerstand.digital">Widerstand und Söhne GmbH</a>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Hinweis auf EU-Streitschlichtung
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            </v-row>
            <br />
            <v-row no-gutters>
              https://ec.europa.eu/consumers/odr
            </v-row>
            <v-row no-gutters>
              E-Mail-Adresse siehe oben<br/>
              Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-12">
          <span
            class="section-title white--text align-self-center pt-12"
          >
            HAFTUNGSAUSSCHLUSS
          </span>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Haftung für Inhalte
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Haftung für Links
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-12 pb-4">
          <span
            class="header-sub-title white--text align-self-center"
          >
            Urheberrecht
          </span>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12 col-md-8 services-description">
            <v-row no-gutters>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </v-row>
            <br />
            <v-row no-gutters>
              Quelle: Disclaimer von eRecht24, dem Portal zum Internetrecht von Rechtsanwalt Sören Siebert http://www.e-recht24.de, Impressum Generator
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'Imprint',
  computed: {
    showImprint () {
      return this.$store.getters.showImprint
    }
  },
  methods: {
    ...mapMutations({
      setShowImprint: 'SET_SHOW_IMPRINT'
    })
  }
}
</script>
