<template>
  <div>
    <v-card
      tile
      flat
    >
      <v-card-text>
        <v-row>
          <v-col class="pt-0 text-right">
            <v-btn
              icon
              @click="$router.replace({ query: { login: undefined } })"
            >
              <v-icon>
                fas fa-times
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
      </v-card>
    <v-form v-model="loginFormValid">
      <v-card
        flat
        tile
        v-if="mode === 'login'"
      >
        <v-card-title>
          Login
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              class="py-0 pt-5"
              cols="12"
            >
              <v-text-field
                color="secondary"
                outlined
                dense
                label="E-Mail"
                v-model="email"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="py-0"
              cols="12"
            >
              <v-text-field
                color="secondary"
                outlined
                dense
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required]"
                @keydown.enter="onEnterClickedLogin()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="loginError && loginError !== ''">
            <v-col class="error--text">
              {{loginError}}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!loginFormValid"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="onLogin()"
              :loading="loading"
            >
              Einloggen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-form v-model="signupFormValid">
      <v-card
        tile
        flat
        v-if="mode === 'signup'"
      >
        <v-card-title>
          Zugang beantragen
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Deine Organisation ist bereits Mitglied im SjR und du möchtest einen Zugang für die SjR-Plattform beantragen?
              Dann fülle einfach das nachfolgende Formular aus ...
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 pt-5"
            >
              <v-text-field
                color="secondary"
                dense
                outlined
                label="Vorname"
                v-model="firstName"
                :rules="[rules.required, rules.shortText]"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                color="secondary"
                dense
                outlined
                label="Nachname"
                v-model="lastName"
                :rules="[rules.required, rules.shortText]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                color="secondary"
                dense
                outlined
                label="E-Mail"
                v-model="email"
                :rules="[rules.required, rules.email]"
                :error-messages="emailError"
                @input="checkEmailError()"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                color="secondary"
                dense
                outlined
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required, rules.password]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                color="secondary"
                dense
                outlined
                label="Name deiner Organisation"
                v-model="message"
                :rules="[rules.required, rules.longText]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                color="secondary"
                :rules="[rules.required]"
              >
                <template v-slot:label>
                  <div>
                    Ich habe die <a class="secondary--text" target="_blank" @click.stop href="/neuigkeiten?privacy=true">Datenschutzbestimmungen</a> gelesen und akzeptiere diese.
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              :disabled="!signupFormValid"
              @click="onSignup()"
            >
              Zugang beantragen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-form v-model="resetFormValid">
      <v-card
        tile
        flat
        v-if="mode === 'reset'"
      >
        <v-card-title>
          Passwort zurücksetzen
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Wenn du dein Passwort vergessen hast, trage hier deine hinterlegte Email-Adresse ein. An diese Adresse senden wir dir einen Link zum Zurücksetzen deines Passworts.
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                color="secondary"
                label="E-Mail"
                v-model="email"
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="pa-4"
        >
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              :disabled="!resetFormValid"
              @click="onReset()"
            >
              Passwort zurücksetzen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-card
      tile
      flat
      v-if="mode === 'thanks'"
    >
      <v-card-title>
        Vielen Dank
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Du erhältst eine E-Mail sobald dein Zugang verfügbar ist.
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      tile
      flat
      v-if="mode === 'resetThanks'"
    >
      <v-card-title>
        Vielen Dank
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Du hast eine Email zum Zurücksetzen deines Passworts erhalten.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                block
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="mode = 'login'"
              >
                Zurück zur Anmeldung
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      tile
      flat
    >
      <v-card-text>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col class="text-center">
            <v-btn
              v-if="mode === 'login' || mode === 'signup'"
              x-small
              text
              color="secondary"
              @click="mode = 'reset'"
            >
              Passwort vergessen
            </v-btn>
            <v-btn
              v-if="mode === 'signup' || mode === 'thanks' || mode === 'reset'"
              x-small
              text
              color="secondary"
              @click="mode = 'login'"
            >
              Login
            </v-btn>
            <v-btn
              v-if="mode === 'login' || mode === 'reset'"
              x-small
              text
              color="secondary"
              @click="mode = 'signup'"
            >
              Zugang beantragen
            </v-btn>
          </v-col>
      </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Login',
  data: () => ({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    message: '',
    emailError: [],
    loginError: '',
    mode: 'login',
    loginFormValid: false,
    signupFormValid: false,
    resetFormValid: false,
    showResetPassword: false,
    loading: false
  }),
  computed: {
    ...mapGetters([
      'rules',
      'showLogin'
    ])
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    ...mapActions('authManagement', {
      createAuth: 'create'
    }),
    ...mapActions('users', {
      createUser: 'create'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapMutations({
      setInit: 'SET_INIT'
    }),
    onLogin () {
      this.loading = true
      this.authenticate({ strategy: 'local', email: this.email, password: this.password })
        .then(() => {
          this.setInit(true)
          this.$router.options.waitFor(this.$route, this.$route, () => {})
          this.$router.push({ query: { login: undefined } })
          this.loading = false
        })
        .catch(error => {
          if (error.code === 401) {
            this.loginError = 'Passwort oder Email-Adresse ungültig.'
            this.showResetPassword = true
          } else if (error.code === 400) {
            this.loginError = 'Der Nutzer wurde noch nicht freigeschaltet.'
          } else {
            console.log(error)
            this.createLog({ type: 'error', text: 'Login: ' + error })
            this.loginError = error.message
          }
          this.loading = false
        })
    },
    onSignup () {
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        message: this.message,
        entry: 'signup'
      }
      this.createUser([map, {}])
        .then((result) => { this.mode = 'thanks' })
        .catch((error) => {
          if (error.errors && error.errors.email) {
            this.emailError = ['Es existiert bereits ein Zugang für diese Email-Adresse.']
          } else {
            this.createLog({ type: 'error', text: 'Add account: ' + error })
          }
          this.loading = false
        })
    },
    onReset () {
      this.mode = 'resetThanks'
      this.createAuth({ action: 'sendResetPwd', value: { email: this.email } })
    },
    onEnterClickedLogin () {
      if (this.loginFormValid) {
        this.onLogin()
      }
    },
    checkEmailError () {
      this.emailError = []
    }
  },
  watch: {
    loginError () {
      this.loading = false
    },
    emailError () {
      this.loading = false
    }
  }
}
</script>
