<template>
  <div
    v-if="event"
  >
    <v-row>
      <v-col class="mt-6 primary--text section-title">
        Buchungen
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="computedBookings"
        >
          <template
            v-slot:[`item.createdAt`]="{ item }"
          >
            {{$moment(item.createdAt).format('DD.MM.YYYY HH:mm')}}
          </template>
          <template
            v-slot:[`item.participant.birthday`]="{ item }"
          >
            {{$moment(item.participant.birthday).format('DD.MM.YYYY')}}
          </template>
          <template
            v-slot:[`item.firstName`]="{ item }"
          >
              {{item.firstName}} {{item.lastName}}<br>
              Anschrift: {{item.address.street}}, {{item.address.postalCode}} {{item.address.city}}<br>
              Email: {{item.email}}<br>
              <template v-if="item.phone">
                Telefon: {{item.phone}}<br>
              </template>
              <template v-if="item.note">
                Anmerkung: {{item.note}}
              </template>
          </template>
          <template
            v-slot:[`item.participant.discount`]="{ item }"
          >
            <template
              v-if="item.participant.discount"
            >
              {{item.participant.discount.text}}
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template
            v-slot:[`item.payed`]="{ item }"
          >
            <v-btn
              icon
              color="secondary"
              :loading="loaders[item._id + 'payed'] === true"
              @click="changePayed(
                item,
                {
                  payed: !(item.payed || false),
                }
              )"
            >
              <template
                slot="loader"
              >
                <v-progress-circular
                  color="white"
                  width="3"
                  indeterminate
                ></v-progress-circular>
              </template>
              <v-icon>
                {{item.payed ? 'fas fa-check-square' : 'far fa-square'}}
              </v-icon>
            </v-btn>
          </template>

          <template
            v-slot:[`item.isWaiting`]="{ item }"
          >
            <v-btn
              icon
              color="secondary"
              :loading="loaders[item._id + 'isWaiting'] === true"
              @click="changeWaiting(
                item,
                {
                  isWaiting: !(item.isWaiting || false),
                }
              )"
            >
              <template
                slot="loader"
              >
                <v-progress-circular
                  color="white"
                  width="3"
                  indeterminate
                ></v-progress-circular>
              </template>
              <v-icon>
                {{item.isWaiting ? 'fas fa-check-square' : 'far fa-square'}}
              </v-icon>
            </v-btn>
          </template>

          <template
            v-slot:[`item.delete`]="{ item }"
          >
            <v-btn
              fab
              small
              color="secondary"
              class="my-4"
              :loading="loaders[item._id + 'delete'] === true"
              @click="() => { selectedBookingId = item._id; showDeleteDialog = true }"
            >
              <template
                slot="loader"
              >
                <v-progress-circular
                  color="white"
                  width="3"
                  indeterminate
                ></v-progress-circular>
              </template>
              <v-icon
                color="white"
                size="18"
              >
                fa fa-trash
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      class="pb-6 pt-0"
    >
      <v-col cols="12">
        <v-btn
          outlined
          block
          tile
          color="secondary"
          @click="getExcel(event.organisation, event._id, 'booking')"
        >
          <v-icon
            small
            class="mr-3"
          >
            fas fa-download
          </v-icon>
          Excel-Datei
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeleteDialog" max-width="350px">
      <v-card>
        <v-card-title>Wirklich löschen?</v-card-title>
        <v-card-text>
          Diese Aktion kann nicht rückgängig gemacht werden.
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showDeleteDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :disabled="loaders[selectedBookingId + 'delete'] === true"
              :loading="loaders[selectedBookingId + 'delete'] === true"
              @click="deleteBooking()"
            >
              Löschen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Bookings',
  props: ['event'],
  data () {
    return {
      showDeleteDialog: false,
      loaders: {},
      processingSave: false,
      selectedBookingId: undefined,
      headers: [
        { text: 'Buchung', value: 'createdAt' },
        { text: 'Vorname', value: 'participant.firstName' },
        { text: 'Nachname', value: 'participant.lastName' },
        { text: 'Geburstdatum', value: 'participant.birthday' },
        { text: 'Anmeldung', value: 'firstName' },
        { text: 'Anmerkung', value: 'participant.note' },
        { text: 'Ermäßigung', value: 'participant.discount' },
        { text: 'Code', value: 'code' },
        { text: 'Bezahlt', value: 'payed' },
        { text: 'Auf Warteliste', value: 'isWaiting' },
        { text: 'Löschen', value: 'delete' }
      ]
    }
  },
  async mounted () {
    await this.findBookings({ query: { event: this.event._id } })
  },
  methods: {
    ...mapActions([
      'showSnackbar'
    ]),
    ...mapActions('excel', {
      createExcel: 'create'
    }),
    ...mapActions('bookings', {
      findBookings: 'find',
      patchBooking: 'patch',
      removeBooking: 'remove'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    async getExcel (organisation, event, type) {
      const result = await this.createExcel({ type, event, organisation })
      const blob = new Blob(
        [result.sheet],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      )
      this.downloadBlob(blob, event + '_' + this.$moment().format('YYYY-MM-DD_hh-mm') + '.xlsx')
    },
    downloadBlob (blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename || 'download'
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.removeEventListener('click', clickHandler)
        }, 150)
      }
      a.addEventListener('click', clickHandler, false)
      a.click()
    },
    async changePayed (booking, patchObj) {
      this.$set(this.loaders, booking._id + 'payed', true)
      try {
        await this.patchBooking(
          [
            booking._id,
            patchObj
          ]
        )
        this.showSnackbar({ mode: 'save', success: true })
        this.$set(this.loaders, booking._id + 'payed', undefined)
      } catch (e) {
        this.showSnackbar({ mode: 'save', success: false })
        this.$set(this.loaders, booking._id + 'payed', undefined)
      }
    },
    async changeWaiting (booking, patchObj) {
      this.$set(this.loaders, booking._id + 'isWaiting', true)
      try {
        await this.patchBooking(
          [
            booking._id,
            patchObj
          ]
        )
        this.showSnackbar({ mode: 'save', success: true })
        this.$set(this.loaders, booking._id + 'isWaiting', undefined)
      } catch (e) {
        this.showSnackbar({ mode: 'save', success: false })
        this.$set(this.loaders, booking._id + 'isWaiting', undefined)
      }
    },
    async deleteBooking () {
      this.$set(this.loaders, this.selectedBookingId + 'delete', true)
      try {
        await this.removeBooking(this.selectedBookingId)
        this.showSnackbar({ mode: 'delete', success: true })
        this.$set(this.loaders, this.selectedBookingId + 'delete', undefined)
        this.showDeleteDialog = false
        this.selectedBookingId = undefined
      } catch (e) {
        console.log(e)
        this.showSnackbar({ mode: 'delete', success: false })
        this.$set(this.loaders, this.selectedBookingId + 'delete', undefined)
      }
    }
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('bookings', {
      bookings: 'list'
    }),
    computedBookings () {
      return this.bookings.filter(obj => obj.event === this.event._id)
    }
  },
  watch: {
  }
}
</script>
