<template>
  <div>
    <vue-headful
      title="SJR Ulm - Mitgliedschaft"
      description="Stellt hier euren Mitgliedsantrag für den Stadtjugendring Ulm e.V."
    />
    <TitleToolbar
      v-bind:title="title"
      titleSuffix="MITGLIEDERANTRAG"
    />
    <Header
      preHeadline="Wir möchten"
      headline="MITGLIED BEIM SJR WERDEN"
      headlineSubtitle="Der Stadtjugendring ist der Dachverband für Vereine, Initiativen und Gruppierungen der Kinder- und Jugendarbeit in Ulm und bietet seinen Mitgliedern Vorteile wie Zuschüsse, Räumlichkeiten oder ein großes und starkes Netzwerk. Wenn auch ihr interessiert seid und mehr über die Vorteile und Konditionen erfahren wollt füllt einfach das folgende Formular aus oder schreibt eure Fragen an info@sjr-ulm.de ..."
      imageSource="/img/img-wer-wir-sind.jpg"
      backgroundColor="#003151"
    />
    <v-container fluid>
      <v-card flat tile transparent>
        <v-form
          v-if="state === 'write'"
          ref="memberForm"
          v-model="memberFormValid"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="main-committee-primary">
                    ANSPRECHPARTNER
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      outlined
                      dense
                      label="Name"
                      color="secondary"
                      v-model="firstName"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      outlined
                      dense
                      label="Nachname"
                      color="secondary"
                      v-model="lastName"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="main-committee-primary">
                    ORGANISATION
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      label="Name der Organisation"
                      color="secondary"
                      v-model="organisationName"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      outlined
                      dense
                      label="Straße"
                      color="secondary"
                      v-model="street"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      outlined
                      dense
                      label="Nr."
                      color="secondary"
                      v-model="number"
                      type="number"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      outlined
                      dense
                      label="PLZ"
                      color="secondary"
                      v-model="postal"
                      type="number"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      outlined
                      dense
                      label="Ort"
                      color="secondary"
                      v-model="city"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      label="Telefon"
                      color="secondary"
                      v-model="phone"
                      :rules="[rules.required, rules.shortText]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      label="E-Mail"
                      color="secondary"
                      v-model="email"
                      :rules="[rules.email]"
                      class="optional-field"
                    />
                    </v-col>
                </v-row>
                <v-row>
                  <v-col>
                  <v-text-field
                    outlined
                    dense
                    label="Website"
                    color="secondary"
                    v-model="website"
                    :rules="[rules.shortText]"
                    class="optional-field"
                  />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="main-committee-primary">
                    WARUM WOLLT IHR MITGLIED WERDEN?
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      outlined
                      color="secondary"
                      v-model="message"
                      :rules="[rules.required, rules.longText]"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="main-committee-primary">
                DATENSCHUTZ
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-checkbox
                  color="secondary"
                  label="Ich habe die Datenschutzbestimmungen gelesen und akzeptiere diese"
                  v-model="privacy"
                  :rules="[rules.required]"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                block
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                :disabled="!memberFormValid"
                @click="saveMembership()"
              >
                Absenden
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-form>
        <template v-else-if="state === 'done'">
          <v-card-text>
            <v-row>
              <v-col>
                Vielen Dank für deinen Antrag. Wir werden uns schnellstmöglich bei dir melden.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Dein Stadtjugendring Ulm e.V.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    tile
                    depressed
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    @click="state ='write'"
                  >
                    Zurück zum Formular
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-else-if="state === 'error'">
          <v-card-text>
            <v-row>
              <v-col>
                Leider ist hier ein Fehler passiert. Dein Antrag konnte nicht übermittelt werden. Bitte versuche es nochmal oder wende dich direkt an info@sjr-ulm.de ...
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Dein Stadtjugendring Ulm e.V.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    tile
                    depressed
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    @click="state ='write'"
                  >
                    Nochmal versuchen
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
    </v-container>
  </div>
</template>

<script>

import TitleToolbar from '@/components/TitleToolbar'
import Header from '@/components/Header'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Membership',
  data: () => ({
    title: 'MITMACHEN',
    memberFormValid: false,
    firstName: null,
    lastName: null,
    organisationName: null,
    street: null,
    number: null,
    city: null,
    postal: null,
    phone: null,
    email: null,
    website: null,
    message: null,
    privacy: false,
    state: 'write'
  }),
  components: {
    TitleToolbar,
    Header
  },
  methods: {
    ...mapActions('membership', {
      createMembership: 'create'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    async saveMembership () {
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        organisationName: this.organisationName,
        street: this.street,
        number: this.number,
        postal: this.postal,
        city: this.city,
        phone: this.phone,
        email: this.email || '-',
        website: this.website || '-',
        message: this.message,
        privacy: this.privacy
      }
      try {
        await this.createMembership(map)
        this.state = 'done'
      } catch (e) {
        this.createLog({ type: 'error', text: 'Create membership: ' + e })
        this.state = 'error'
      }
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ])
  },
  watch: {
    state () {
      if (this.state === 'write') {
        this.$nextTick()
          .then(() => {
            this.$refs.memberForm.reset()
          })
      }
    }
  }
}
</script>

<style scoped>
  .bg_about-us {
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat
  }
</style>
