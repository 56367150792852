<template>
  <div>
    <template v-if="microsite">
      <TitleToolbar
        v-bind:title="title"
      />
      <v-container :fluid="!$vuetify.breakpoint.mdAndUp">
        <v-card
          tile
          outlined
          class="elevation-3"
        >
          <v-carousel
            v-if="microsite.pics.length > 0"
            cycle
            hide-delimiter-background
            :hide-delimiters="!computedShowCarouselDelimiters"
            :show-arrows="computedShowCarouselDelimiters"
            :show-arrows-on-hover="computedShowCarouselDelimiters"
            class="flat-carousel"
            :class="computedShowCarouselDelimiters ? 'flat-carousel-show-delimiters' : ''"
            height="auto"
          >
            <v-carousel-item
              v-for="(pic, i) in microsite.pics"
              :key="i"
            >
              <v-img
                :aspect-ratio="16/9"
                max-height="600"
                :src="computedServerUri + '/pics/' + organisation._id + '/microsite/' + pic"
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-card-text>
            <v-row class="ma-0 mt-12 px-0 mx-n3">
              <v-col>
                <v-btn
                  class="mb-3"
                  color="secondary"
                  icon
                  @click="$router.push($route.path + '/editor')"
                  v-if="
                    ac &&
                    user &&
                    (
                      ac.can(user.role).updateAny('organisation').granted ||
                      (
                        ac.can(user.role).updateOwn('organisation').granted &&
                        user.organisation === organisation._id
                      )
                    )
                  "
                >
                  <v-icon>
                    fas fa-edit
                  </v-icon>
                </v-btn>
                <v-row
                  v-if="!microsite.hideName"
                  no-gutters
                  class="services-title pb-4"
                >
                  <v-col>
                    {{organisation.name}}
                  </v-col>
                </v-row>
                <v-row
                  v-if="organisation.pic"
                >
                  <v-col
                    cols="12"
                  >
                    <img
                      :src="computedServerUri + '/pics/' + organisation._id + '/logo/' + organisation.pic"
                      :height="microsite.hideName ? '200px': '100px'"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="ma-0 px-0 mx-n3">
              <v-col cols="12" md="7" :class="$vuetify.breakpoint.mdAndUp ? 'pr-12': ''">
                <v-row
                  no-gutters
                  class="services-description pb-4 pretext"
                  v-if="microsite.text"
                  v-html="$sanitize(microsite.text)"
                ></v-row>
                <template v-if="microsite.candidature && microsite.candidature.trim() !== ''">
                  <v-row
                    no-gutters
                    class="section-title py-4"
                  >
                    Das kannst du bei uns tun
                  </v-row>
                  <v-row
                    no-gutters
                    class="services-description pb-4 pretext"
                    v-html="$sanitize(microsite.candidature)"
                  ></v-row>
                </template>
                <v-row
                  class="pb-3 px-3"
                  v-if="microsite.contact.email"
                >
                  <v-btn
                    :href="'mailto:' + microsite.contact.email + '?subject=Ich möchte mitmachen!'"
                    elevation=0
                    tile
                    block
                    color="secondary"
                  >
                    KONTAKT AUFNEHMEN
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    KONTAKT
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1 mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="8"
                  >
                    <template
                      v-if="microsite.contact && microsite.contact.firstName && microsite.contact.lastName"
                    >
                      {{microsite.contact.firstName}} {{microsite.contact.lastName}}<br>
                    </template>
                    <template
                      v-if="organisation"
                    >
                      <template
                        v-if="organisation.name"
                      >
                        {{organisation.name}}<br>
                      </template>
                      <template
                        v-if="organisation.address"
                      >
                        <template
                          v-if="organisation.address.street && organisation.address.number"
                        >
                          {{organisation.address.street}} {{organisation.address.number}}<br>
                        </template>
                        <template
                          v-if="organisation.address.postal && organisation.address.city"
                        >
                          {{organisation.address.postal}} {{organisation.address.city}}
                        </template>
                      </template>
                    </template>
                  </v-col>
                </v-row>
                <v-row
                  v-if="microsite.contact && microsite.contact.phone"
                  no-gutters class="pb-2"
                >
                  <v-col
                    class="footer-item mt-1 text-uppercase"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    TELEFON
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1 mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="8"
                  >
                    {{microsite.contact.phone}}
                  </v-col>
                </v-row>
                <template v-if="micosite && microsite.contact">
                  <v-row
                    no-gutters class="pb-2"
                    v-if="microsite.contact.fax"
                  >
                    <v-col
                      class="footer-item mt-1 text-uppercase"
                      cols="12"
                      sm="6"
                      md="12"
                      lg="4"
                    >
                      FAX
                    </v-col>
                    <v-col
                      class="team-item-secondary"
                      :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                      cols="12"
                      sm="6"
                      md="12"
                      lg="8"
                    >
                      {{microsite.contact.fax}}
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters class="pb-2"
                    v-if="microsite.contact.email"
                  >
                    <v-col
                      class="footer-item mt-1 text-uppercase"
                      cols="12"
                      sm="6"
                      md="12"
                      lg="4"
                    >
                      EMAIL
                    </v-col>
                    <v-col
                      class="team-item-secondary"
                      :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                      cols="12"
                      sm="6"
                      md="12"
                      lg="8"
                    >
                      {{microsite.contact.email}}
                    </v-col>
                  </v-row>
                </template>
                <v-row
                  v-for="(channel, i) in microsite.channels.filter(c => c.type !== 'icon')"
                  :key="i"
                  no-gutters class="pb-2"
                >
                  <v-col
                    class="footer-item mt-1 text-uppercase"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    {{channel.description}}
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="8"
                  >
                    <a target="_blank" :href="channel.url.startsWith('http') ? channel.url : 'http://' + channel.url">{{channel.url}}</a>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters class="pb-2"
                  v-if="microsite.founded"
                >
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    GRÜNDUNG
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="8"
                  >
                    {{microsite.founded}}
                  </v-col>
                </v-row>
                <v-row
                  v-if="microsite.board && microsite.board.length > 0"
                  no-gutters class="pb-2"
                >
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    {{ organisation.type === 'member' || organisation.type === 'cooperation' ? "VORSTAND": "LEITUNG" }}
                  </v-col>
                  <v-col align-self="center" :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'">
                    <v-row dense>
                      <v-col
                        v-for="(member, i) in microsite.board"
                        class="team-item-secondary py-0"
                        cols="12"
                        :key="i"
                      >
                        {{member.firstName}} {{member.lastName}}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="organisation.categories && organisation.categories.length > 0"
                  no-gutters class="pb-2"
                >
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    KATEGORIEN
                  </v-col>
                  <v-col align-self="center" :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1 mb-3'">
                    <v-row dense>
                      <v-col
                        v-for="(category, i) in organisation.categories"
                        class="team-item-secondary py-0"
                        cols="12"
                        sm="6"
                        md="12"
                        lg="8"
                        :key="i"
                      >
                        {{organisationCategories.find(obj => obj.value === category).text}}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="organisation.bookingInformation"
                  no-gutters class="pb-2"
                >
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    RECHTLICHES
                  </v-col>
                  <v-col align-self="center" :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1 mb-3'">
                    <v-row dense>
                      <v-hover
                        v-slot:default="{ hover }"
                      >
                        <v-btn
                          tile
                          small
                          depressed
                          :color="hover ? 'secondary' : 'info'"
                          class="white--text ml-1 mt-1"
                          @click="showBookingInformation = true"
                        >
                          Ansehen
                        </v-btn>
                      </v-hover>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="microsite.channels.filter(c => c.type === 'icon').length > 0"
                  no-gutters class="pb-2 pt-2"
                >
                  <v-col
                    class="footer-item mt-1 mb-2"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="4"
                  >
                    LINKS
                  </v-col>
                  <a
                    v-for="(channel, i) in microsite.channels.filter(c => c.type === 'icon')"
                    :key="i"
                    :href="channel.url"
                    target="_blank"
                    style="text-decoration:none"
                    class="mr-3"
                  >
                    <v-icon
                      size="40"
                      color="secondary"
                    >
                      {{channel.description}}
                    </v-icon>
                  </a>
                </v-row>
              </v-col>
            </v-row>
            <v-divider
              v-if="microsite.tabs && Array.isArray(microsite.tabs) && microsite.tabs.length > 0"
              class="mt-6"
            ></v-divider>
            <v-row
              v-if="microsite.tabs && Array.isArray(microsite.tabs) && microsite.tabs.length > 0"
              class="ma-0 px-0 mt-6"
            >
              <v-col
                class="px-0"
              >
                <v-tabs
                  v-model="activeTab"
                  centered
                  grow
                >
                  <v-tabs-slider
                    color="primary"

                  ></v-tabs-slider>

                  <v-tab
                    v-for="item in microsite.tabs"
                    :key="item"
                    class="section-title"
                  >
                    {{ item === 'custom' ? (microsite.custom?.title ? microsite.custom?.title : 'Individueller Tab') : tabNames.find(t => t.value === item).text }}
                  </v-tab>
                </v-tabs>
                <v-row
                  class="mt-3"
                >
                  <v-col
                    cols="12"
                  >
                    <v-tabs-items v-model="activeTab">
                      <v-tab-item
                        v-for="item in microsite.tabs"
                        :key="item"
                      >
                        <v-card flat>
                          <v-card-text
                            class="px-0"
                          >
                            <template
                              v-if="item === 'events'"
                            >
                              <v-row dense>
                                <v-col>
                                  <v-btn
                                    tile
                                    icon
                                    outlined
                                    color="secondary"
                                    class="mr-4"
                                    @click="eventMode = 'grid'"
                                    :class="eventMode !== 'grid' ? 'action-icon-button': 'action-icon-button-active'"
                                  >
                                    <span class="fas fa-grip-horizontal"></span>
                                  </v-btn>
                                  <v-btn
                                    tile
                                    icon
                                    outlined
                                    color="secondary"
                                    @click="eventMode = 'list'"
                                    :class="eventMode !== 'list' ? 'action-icon-button': 'action-icon-button-active'"
                                  >
                                    <span class="fas fa-th-list"></span>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <template v-if="computedEvents.length === 0">
                                <v-row>
                                  <v-col
                                    class="text-center title mt-6"
                                  >
                                    Aktuell keine Veranstaltungen
                                  </v-col>
                                </v-row>
                              </template>
                              <v-row
                                v-if="eventMode === 'grid'"
                              >
                                <v-col>
                                  <v-container>
                                    <v-row>
                                      <v-carousel
                                        height="auto"
                                        :continuous="false"
                                        :show-arrows="computedEvents.length > 1"
                                        :show-arrows-on-hover="computedEvents.length > 1"
                                      >
                                        <template v-for="(event, index) in computedEvents">
                                          <v-carousel-item
                                            v-if="(index + 1) % eventColumns === 1 || eventColumns === 1"
                                            :key="index"
                                          >
                                            <v-row class="flex-nowrap py-4" style="height:100%">
                                              <template v-for="(n,i) in eventColumns">
                                                <template v-if="(+index + i) < computedEvents.length">
                                                  <v-col :key="i">
                                                    <v-sheet v-if="(+index + i) < computedEvents.length"
                                                      height="100%"
                                                    >
                                                      <v-row
                                                        class="mx-1 fill-height mb-8 pb-8"
                                                        align="center"
                                                        justify="center"
                                                      >
                                                        <EventContainer :event="computedEvents[+index + i]"/>
                                                      </v-row>
                                                    </v-sheet>
                                                  </v-col>
                                                </template>
                                              </template>
                                            </v-row>
                                          </v-carousel-item>
                                        </template>
                                      </v-carousel>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                              <v-row
                                v-else-if="eventMode === 'list'"
                              >
                                <v-col
                                  v-for="(event, i) in computedEvents"
                                  :key="i"
                                  cols="12"
                                  class="py-2"
                                >
                                  <EventContainer mode="list" :event="event"/>
                                </v-col>
                              </v-row>
                            </template>
                            <template
                              v-if="item === 'news'"
                            >
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    v-model="tagFilter"
                                    :items="[...organisationNews.map(n => n.tags).filter(i => i).flat()].sort()"
                                    color="secondary"
                                    item-color="secondary"
                                    label="Filter nach Schlagworten"
                                    dense
                                    outlined
                                    multiple
                                    chips
                                    deletable-chips
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                              <template v-if="computedNews.length === 0">
                                <v-row>
                                  <v-col
                                    class="text-center title mt-6"
                                  >
                                    Noch keine Neuigkeiten veröffentlicht
                                  </v-col>
                                </v-row>
                              </template>
                              <v-row>
                                <v-col>
                                  <v-container>
                                    <v-row>
                                      <v-carousel
                                        height="auto"
                                        :continuous="false"
                                        :show-arrows="computedNews.length > 1"
                                        :show-arrows-on-hover="computedNews.length > 1"
                                      >
                                        <template v-for="(news, index) in computedNews">
                                          <v-carousel-item
                                            v-if="(index + 1) % newsColumns === 1 || newsColumns === 1"
                                            :key="index"
                                          >
                                            <v-row class="flex-nowrap py-4" style="height:100%">
                                              <template v-for="(n,i) in newsColumns">
                                                <template v-if="(+index + i) < computedNews.length">
                                                  <v-col :key="i">
                                                    <v-sheet v-if="(+index + i) < computedNews.length"
                                                      height="100%"
                                                    >
                                                      <v-row
                                                        class="mx-1 fill-height mb-8 pb-8"
                                                        justify="center"
                                                      >
                                                        <NewsContainer :news="computedNews[+index + i]"/>
                                                      </v-row>
                                                    </v-sheet>
                                                  </v-col>
                                                </template>
                                              </template>
                                            </v-row>
                                          </v-carousel-item>
                                        </template>
                                      </v-carousel>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </template>
                            <template
                              v-if="item === 'team'"
                            >
                              <v-row
                                v-if="microsite.team && microsite.team.length === 0"
                              >
                                <v-col
                                  class="text-center title mt-6"
                                >
                                  Noch keine Team-Mitglieder eingetragen
                                </v-col>
                              </v-row>
                              <v-row v-else>
                                <v-col>
                                  <v-container>
                                    <v-row>
                                      <v-carousel
                                        height="auto"
                                        :continuous="false"
                                        :show-arrows="microsite.team.length > 1"
                                        :show-arrows-on-hover="microsite.team.length > 1"
                                      >
                                        <template v-for="(news, index) in microsite.team">
                                          <v-carousel-item
                                            v-if="(index + 1) % eventColumns === 1 || eventColumns === 1"
                                            :key="index"
                                          >
                                            <v-row class="flex-nowrap py-4" style="height:100%">
                                              <template v-for="(n,i) in eventColumns">
                                                <template v-if="(+index + i) < microsite.team.length">
                                                  <v-col :key="i">
                                                    <v-sheet v-if="(+index + i) < microsite.team.length"
                                                      height="100%"
                                                    >
                                                      <v-row
                                                        class="mx-1 fill-height mb-8 pb-8"
                                                        justify="center"
                                                        align="top"
                                                      >
                                                        <TeamMemberContainer v-show=true :teamMember="microsite.team[+index + i]"/>
                                                      </v-row>
                                                    </v-sheet>
                                                  </v-col>
                                                </template>
                                              </template>
                                            </v-row>
                                          </v-carousel-item>
                                        </template>
                                      </v-carousel>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </template>
                            <template
                              v-if="item === 'partners'"
                            >
                              <v-row
                                v-if="microsite.partners && microsite.partners.length === 0"
                              >
                                <v-col
                                  class="text-center title mt-6"
                                >
                                  Noch keine Partner eingetragen
                                </v-col>
                              </v-row>
                              <v-row v-else>
                                <v-col>
                                  <v-container>
                                    <v-row>
                                      <v-carousel
                                        height="auto"
                                        :continuous="false"
                                        :show-arrows="microsite.partners.length > 1"
                                        :show-arrows-on-hover="microsite.partners.length > 1"
                                      >
                                        <template v-for="(news, index) in microsite.partners">
                                          <v-carousel-item
                                            v-if="(index + 1) % eventColumns === 1 || eventColumns === 1"
                                            :key="index"
                                          >
                                            <v-row class="flex-nowrap py-4" style="height:100%">
                                              <template v-for="(n,i) in eventColumns">
                                                <template v-if="(+index + i) < microsite.partners.length">
                                                  <v-col :key="i">
                                                    <v-sheet v-if="(+index + i) < microsite.partners.length"
                                                      height="100%"
                                                    >
                                                      <v-row
                                                        class="mx-1 fill-height mb-8 pb-8"
                                                        justify="center"
                                                        align="top"
                                                      >
                                                        <PartnerContainer :partner="microsite.partners[+index + i]"/>
                                                      </v-row>
                                                    </v-sheet>
                                                  </v-col>
                                                </template>
                                              </template>
                                            </v-row>
                                          </v-carousel-item>
                                        </template>
                                      </v-carousel>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </template>
                            <template
                              v-if="item === 'custom'"
                            >
                              <v-row
                                v-if="microsite.custom"
                              >
                                <v-col
                                  class="text-center title mt-6"
                                >
                                  Noch kein Inhalt verfasst
                                </v-col>
                              </v-row>
                              <v-row
                                v-else
                              >
                                <v-col>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        v-html="microsite.custom?.text"
                                      ></v-col>
                                    </v-row>
                                  </v-container>
                                </v-col>
                              </v-row>
                            </template>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog
        tile
        overlay-color="primary"
        overlay-opacity=".91"
        v-model="showEditor"
        fullscreen
        style="z-index:1001"
      >
        <OrganisationEditor
          :organisation="getOrganisation($route.params.id)"
          :showEditor="showEditor"
        />
      </v-dialog>
    </template>
    <template v-else-if="organisation">
      <v-container class="fill-height">
        <v-row :class="$vuetify.breakpoint.smAndUp ? 'px-12 mx-12 mt-6' : 'mx-3 mt-6'">
          <v-col cols="12" class="text-center my-6">
            <v-icon size="60">far fa-file</v-icon>
          </v-col>
          <v-col cols="12" class="text-center display-1">
            Für diese Organisation wurde leider noch kein Profil angelegt.
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-dialog
      tile
      max-width="800"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showBookingInformation"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <template
            v-if="organisation && organisation.bookingInformation"
          >
            <v-row>
              <v-col
                class="py-12 px-6"
                v-html="$sanitize(organisation.bookingInformation)"
              >
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col
                class="text-center title mt-6"
              >
                Keine Informationen hinterlegt
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import OrganisationEditor from '@/components/OrganisationEditor'
import TitleToolbar from '@/components/TitleToolbar'
import EventContainer from '@/components/EventContainer'
import NewsContainer from '@/components/NewsContainer'
import TeamMemberContainer from '@/components/TeamMemberContainer'
import PartnerContainer from '@/components/PartnerContainer'
import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'

export default {
  name: 'OrganisationDetails',
  mixins: [makeFindMixin({ service: 'events', watch: true }), makeFindMixin({ service: 'news', watch: true })],
  components: {
    TitleToolbar,
    EventContainer,
    NewsContainer,
    TeamMemberContainer,
    PartnerContainer,
    OrganisationEditor
  },
  data () {
    return {
      eventMode: 'grid',
      tagFilter: undefined,
      showBookingInformation: false,
      organisation: null,
      microsite: null,
      title: 'MITGLIEDER',
      activeTab: 0,
      swiperOption: {
        pagination: {
          clickable: true
        },
        spaceBetween: 50,
        slidesPerView: 3,
        breakpoints: {
          960: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      showEditor: false
    }
  },
  mounted () {
    this.adaptParams()
  },
  methods: {
    ...mapActions('microsites', {
      requestMicrosite: 'get'
    }),
    async adaptParams () {
      this.organisation = this.getOrganisation(this.$route.params.id)
      if (this.organisation.microsite) {
        let tmpMicrosite = this.getMicrosite(this.organisation.microsite)
        if (!tmpMicrosite) {
          tmpMicrosite = await this.requestMicrosite(this.organisation.microsite)
        }
        this.microsite = tmpMicrosite
      } else {
        this.microsite = undefined
        // Show error
      }
      if (this.$route.params.editor === 'editor') {
        this.showEditor = true
      } else {
        this.showEditor = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'ac',
      'organisationCategories',
      'tabNames'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('organisations', {
      getOrganisation: 'get'
    }),
    ...mapGetters('microsites', {
      getMicrosite: 'get'
    }),
    eventColumns () {
      if (this.$vuetify.breakpoint.xl) {
        return 4
      }

      if (this.$vuetify.breakpoint.lg) {
        return 4
      }

      if (this.$vuetify.breakpoint.md) {
        return 3
      }

      if (this.$vuetify.breakpoint.sm) {
        return 2
      }

      return 1
    },
    newsColumns () {
      if (this.$vuetify.breakpoint.xl) {
        return 3
      }

      if (this.$vuetify.breakpoint.lg) {
        return 2
      }

      if (this.$vuetify.breakpoint.md) {
        return 2
      }

      if (this.$vuetify.breakpoint.sm) {
        return 1
      }

      return 1
    },
    eventsParams () {
      return {
        query: {
          $and: [
            {
              $or: [
                { 'date.end': { $gte: this.$moment().subtract(1, 'days').toISOString() } },
                { 'interval.end': { $gte: this.$moment().subtract(1, 'days').toISOString() } }
              ]
            },
            {
              organisation: (this.organisation ? this.organisation._id : null)
            }
          ],
          $limit: 1000
        }
      }
    },
    newsParams () {
      return {
        query: {
          organisation: (this.organisation ? this.organisation._id : null),
          $or: [{ internal: false }, { internal: { $ne: true } }],
          $limit: 1000
        }
      }
    },
    computedEvents () {
      if (this.events) {
        return this.$sortByProperty(this.$allEvents(this.events).filter((obj) => obj.internal !== true && obj.organisation === this.organisation._id), 'date.start')
      } else {
        return []
      }
    },
    organisationNews () {
      if (this.news) {
        return this.news.filter((obj) => obj.organisation === this.organisation._id)
      } else {
        return []
      }
    },
    computedNews () {
      if (this.news) {
        if (this.tagFilter && Array.isArray(this.tagFilter) && this.tagFilter.length > 0) {
          return this.news.filter((obj) => obj.tags && this.tagFilter.filter(f => obj.tags.includes(f)).length > 0 && obj.organisation === this.organisation._id).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        }
        return this.news.filter((obj) => obj.organisation === this.organisation._id).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      } else {
        return []
      }
    },
    computedShowCarouselDelimiters () {
      return this.microsite.pics.length > 1
    },
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    '$route.params.editor' () {
      this.adaptParams()
    }
  }
}
</script>

<style scoped>
  /* Fixes bug with element width calculations */
  .swiper-container {
    width: 100%;
  }
  .swiper-container:hover .swiper-button-prev,
  .swiper-container:hover .swiper-button-next {
    transform: translateX(0%);
  }
  .swiper-container .swiper-button-next {
    transform: translateX(200%);
  }
  .swiper-container .swiper-button-prev {
    transform: translateX(-200%);
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-image: none !important;
    line-height: 36px;
    text-align: center;
    height: 36px;
    width: 36px;
    background-color: rgba(0,0,0,0.4);
    color: white;
    border-radius: 50%;
    -webkit-transition: 0.2s -webkit-transform cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.2s -webkit-transform cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.2s transform cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.2s transform cubic-bezier(0.25, 0.8, 0.5, 1), 0.2s -webkit-transform cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  .swiper-button-prev:not(.swiper-button-disabled),
  .swiper-button-next:not(.swiper-button-disabled) {
    cursor: pointer !important;
  }
</style>
