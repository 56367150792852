<template>
  <div>
    <v-form ref="accountForm" v-model="formValid">
      <v-card flat tile>
        <v-card-text>
          <v-row class="mb-5">
            <v-col class="primary--text section-title">
              Account-Einstellungen
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col class="primary--text section-sub-title">
              Nutzer-Zugänge
            </v-col>
          </v-row>
         <v-row>
            <template
              v-for="(user, i) in computedOrganisationUsers"
            >
              <v-col :key="i+'desc'" align-self="center" class="py-2 body-1" cols="12" sm="11">
                {{user.firstName}}
                {{user.lastName}}
                <v-chip :outlined="user.isVerified" :color="user.isVerified ? 'success' : 'warning'" class="ml-3">{{user.isVerified ? 'bestätigt': 'unbestätigt'}}</v-chip>
                <v-chip :outlined="user.isActive" :color="user.isActive ? 'success' : 'warning'" class="ml-3">{{user.isActive ? 'aktiv': 'inaktiv'}}</v-chip>
              </v-col>
              <v-col :key="i" align-self="center" class="py-2 text-center body-1" cols="12" sm="1">
                <v-btn
                  icon
                  color="secondary"
                  @click="archiveUser(user._id)"
                >
                  <v-icon
                    small
                  >
                    fas fa-trash
                  </v-icon>
                </v-btn>
              </v-col>
              <div class="divider" :key="i+'divider'"></div>
            </template>
          </v-row>
          <v-row>
            <v-col class="py-4 pb-0" cols="12" sm="5">
              <v-text-field
                outlined
                dense
                label="Vorname"
                v-model="accountFirstname"
                :rules="[rules.shortText, rules.required]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-4 pb-0" cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Nachname"
                v-model="accountLastname"
                :rules="[rules.shortText, rules.required]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12" sm="5">
              <v-text-field
                outlined
                dense
                label="Email"
                v-model="accountEmail"
                :rules="[rules.email, rules.required]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
            <v-col class="py-0 text-right" cols="12" sm="6">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  block
                  depressed
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  :loading="processingSave"
                  @click="addAccount()"
                  :disabled="!formValid || processingSave"
                >
                  Einladen
                  <v-icon
                    class="ml-3"
                    small
                  >
                    fas fa-envelope
                  </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AccountEditor',
  props: [
    'organisation',
    'showEditor'
  ],
  data () {
    return {
      accountFirstname: '',
      accountLastname: '',
      accountEmail: '',
      formValid: false,
      processingSave: false
    }
  },
  methods: {
    adapt () {
      if (this.organisation) {
        //
      } else {
        this.$refs.accountForm.reset()
      }
    },
    ...mapActions('users', {
      createUser: 'create',
      patchUser: 'patch'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    async addAccount () {
      this.processingSave = true
      try {
        await this.createUser({
          firstName: this.accountFirstname,
          lastName: this.accountLastname,
          email: this.accountEmail,
          organisation: this.organisation._id,
          entry: 'invitation',
          role: 'member'
        })
      } catch (e) {
        this.createLog({ type: 'error', text: 'Add account: ' + e })
        this.showSnackbar({ mode: 'invite', success: false })
        return
      }
      this.accountFirstname = ''
      this.accountLastname = ''
      this.accountEmail = ''
      this.showSnackbar({ mode: 'invite', success: true })
      this.processingSave = false
    },
    async archiveUser (id) {
      try {
        await this.patchUser([id, { isActive: false }, {}])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Archive account: ' + e })
        this.showSnackbar({ mode: 'archive', success: false })
        return
      }
      this.showSnackbar({ mode: 'archive', success: true })
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ]),
    ...mapGetters('users', {
      users: 'list'
    }),
    computedOrganisationUsers () {
      if (this.organisation) {
        return this.users.filter(obj =>
          obj.organisation === this.organisation._id &&
          obj.isActive
        )
      } else {
        return []
      }
    }
  },
  watch: {
    organisation () {
      this.adapt()
    }
  }
}
</script>
