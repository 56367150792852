<template>
  <v-container>
    <v-row>
      <v-col>
          <v-img
            :aspect-ratio="16/9"
            :src="backgroundImageSource"
            :gradient="darken ? 'to top right, rgba(0,0,0,.1), rgba(0,0,0,.8)' : 'to top right, rgba(0,0,0,.1), rgba(0,0,0,.3)'"
          >
              <v-row class="pa-3 px-6 fill-height">
                <v-col cols="12">
                  <span
                    v-if="secondaryTitle && secondaryTitle !== ''"
                    class="services-secondary-title white--text"
                  >
                    {{secondaryTitle}}
                  </span>
                  <span
                    class="services-title white--text"
                    v-html="title"
                  />
                  <div class="white--text mt-1" v-if="subTitle">
                    {{subTitle}}
                  </div>
                </v-col>
                <v-col v-if="description && description !== ''" align-self="end" cols="12">
                  <span
                    class="white--text"
                  >
                    {{description}}
                  </span>
                </v-col>
                <v-col
                  v-if="buttonLabel && buttonLabel !== ''"
                  align-self="end" class="text-right">
                  <v-btn
                    tile
                    depressed
                    color="white"
                    @click="$emit('buttonClick', buttonLabel)"
                  >
                    {{buttonLabel}}
                  </v-btn>
                </v-col>
              </v-row>
          </v-img>
        <v-row no-gutters class="pt-6">
          <span
            v-if="text && text !== ''"
            class="services-description sjrBlue--text"
            v-html="text"
          >
          </span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImageTextTileCol',
  props: {
    title: {
      type: String,
      required: true
    },
    secondaryTitle: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String
    },
    description: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    backgroundImageSource: {
      type: String,
      required: true
    },
    darken: {
      type: Boolean,
      default: true
    }
  },
  computed: {
  }
}
</script>
