<template>
  <div>
    <vue-headful
      title="SJR Ulm - Mitmachen"
      description="Erfahrt, wie ihr Teil des Stadtjugendrings Ulm e.V. werden könnt."
    />
    <TitleToolbar
      v-bind:title="title"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            secondaryTitle="Ich möchte"
            title="TEILNEHMEN"
            text="Du suchst nach einer Möglichkeit, die nächsten Ferien kreativ zu verbringen? Du möchtest neue Leute kennenlernen und deinen Horizont erweitern? Oder willst Du wissen, welche Jugendgruppen sich in Ulm treffen? Dann check doch mal unseren Veranstaltungskalender!"
            backgroundImageSource="/img/mitmachen_teilnehmen.jpg"
            buttonLabel="Veranstaltungen"
            @buttonClick="buttonHandler"
            :darken="false"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            secondaryTitle="Ich möchte mich"
            title="ENGAGIEREN"
            text="Du bist neu in der Stadt und möchtest in der Jugendarbeit aktiv werden? Du suchst nach einem Praktikumsplatz oder einer FSJ-Stelle? Dann melde Dich bei uns, wir helfen gerne weiter. Mail an info@sjr-ulm.de"
            backgroundImageSource="/img/mitmachen_engagieren.jpg"
            :darken="false"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            secondaryTitle="Wir möchten"
            title="MITGLIED BEIM SJR WERDEN"
            text="Der Stadtjugendring ist der Dachverband für Vereine, Initiativen und Gruppierungen der Kinder- und Jugendarbeit in Ulm. Wenn auch ihr interessiert seid und mehr über die Vorteile und Konditionen erfahren wollt füllt einfach das folgende Formular aus oder schreibt eure Fragen an info@sjr-ulm.de"
            backgroundImageSource="/img/mitmachen_mitglied.jpg"
            buttonLabel="Mitgliedsantrag"
            @buttonClick="buttonHandler"
            :darken="false"
          />
        </v-col>
        <v-col cols="12" md="6">
          <ImageTextTileCol
            secondaryTitle="Wir möchten"
            title="PARTNER WERDEN"
            text="Der Stadtjugendring Ulm e.V. realisiert Projekte im schulischen und außerschulischen Bereich. Vom medienpädagogischen Angebot der „e.tage Medienbildung“ über die gendergerechten Ferienangebote von „Feuerrot und Neonblau“ bis zur Jugendmesse für Auslandsaufenthalte „hin&weg“ bieten wir in Ergänzung zu den Programmen unserer Mitglieder viele Möglichkeiten. Für eine optimale Durchführung sind wir auf PartnerInnen aus der Wirtschaft und anderen Bereichen angewiesen. Wenn Sie sich vorstellen können, uns mit Spenden oder mit Know-how zu unterstützen freuen wir uns über eine Nachricht an info@sjr-ulm.de"
            backgroundImageSource="/img/mitmachen_partner.jpg"
            :darken="false"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import TitleToolbar from '@/components/TitleToolbar'
import ImageTextTileCol from '@/components/ImageTextTileCol'

export default {
  name: 'JoinUs',
  data: () => ({
    title: 'MITMACHEN',
    showForm: false
  }),
  components: {
    TitleToolbar,
    ImageTextTileCol
  },
  methods: {
    buttonHandler (payload) {
      if (payload === 'Mitgliedsantrag') {
        this.$router.push('/mitgliedschaft')
      } else if (payload === 'Veranstaltungen') {
        this.$router.push('/veranstaltungen')
      }
    }
  }
}
</script>
