<template>
  <div>
    <v-form ref="profileForm" v-model="formValid">
      <v-card flat tile>
        <v-card-text>
          <v-row class="mb-5">
            <v-col class="primary--text section-title">
              Profil-Informationen
            </v-col>
          </v-row>
          <v-row v-if="selectedUser && !selectedUser.isAccepted">
            <v-col>
              <v-alert
                color="warning"
                dark
              >
                Profil ist noch nicht akzeptiert. Hierfür bitte eine Organisation zuweisen und speichern.
                <template v-if="selectedUser.message && selectedUser.message !== ''">
                  Der Nutzer hat "{{selectedUser.message}}" angegeben.
                </template>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="firstName"
                outlined
                dense
                label="Vorname"
                :rules="[rules.required, rules.longText]"
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="lastName"
                outlined
                dense
                label="Nachname"
                :rules="[rules.required, rules.longText]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                label="email"
                v-model="email"
                :rules="[rules.required, rules.email]"
                :error-messages="emailErrors"
                color="secondary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Passwort"
                v-model="password"
                :placeholder="selectedUser ? '********': ''"
                type="password"
                color="secondary"
                class="optional-field"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                dense
                outlined
                v-model="organisation"
                label="Organisation auswählen"
                :items="organisations.filter(obj => obj.isActive)"
                item-text="name"
                item-value="_id"
                color="secondary"
                item-color="secondary"
                :rules="[rules.required]"
                :disabled="
                  !$route.params.id ||
                  !(
                    ac.can(user.role).updateAny((role ? role : 'admin')).attributes.includes('*') ||
                    ac.can(user.role).updateAny((role ? role : 'admin')).attributes.includes('organisation') ||
                    (
                      computedUser &&
                      user._id === computedUser._id &&
                      (
                        ac.can(user.role).updateOwn((role ? role : 'admin')).attributes.includes('*') ||
                        ac.can(user.role).updateOwn((role ? role : 'admin')).attributes.includes('organisation')
                      )
                    )
                  )
                "
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                dense
                outlined
                v-model="role"
                label="Rolle auswählen"
                :items="roles"
                item-text="text"
                item-value="value"
                color="secondary"
                item-color="secondary"
                :rules="[rules.required]"
                :disabled="
                  !$route.params.id ||
                  !(
                    ac.can(user.role).updateAny((role ? role : 'admin')).attributes.includes('*') ||
                    ac.can(user.role).updateAny((role ? role : 'admin')).attributes.includes('role') ||
                    (
                      computedUser &&
                      user._id === computedUser._id &&
                      (
                        ac.can(user.role).updateOwn((role ? role : 'admin')).attributes.includes('*') ||
                        ac.can(user.role).updateOwn((role ? role : 'admin')).attributes.includes('role')
                      )
                    )
                  )
                "
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-hover
            v-if="selectedUser"
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="selectedUser.isActive"
              tile
              depressed
              :disabled="processingArchive"
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :loading="processingArchive"
              @click="showDeleteDialog = true"
            >
              Archivieren
            </v-btn>
          </v-hover>
          <v-hover
            v-if="selectedUser"
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="
                !selectedUser.isVerified &&
                (
                    ac.can(user.role).updateAny((role ? role : 'admin')).granted ||
                    ac.can(user.role).updateAny((role ? role : 'admin')).granted ||
                    (
                      computedUser &&
                      user.organisation === computedUser.organisation &&
                      (
                        ac.can(user.role).updateOwn((role ? role : 'admin')).granted ||
                        ac.can(user.role).updateOwn((role ? role : 'admin')).granted
                      )
                    )
                  )
              "
              tile
              depressed
              :disabled="processingVerify"
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :loading="processingVerify"
              @click="showVerifyDialog = true"
            >
              Verifizieren
            </v-btn>
          </v-hover>
          <v-hover
            v-if="selectedUser"
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="!selectedUser.isActive"
              tile
              depressed
              :disabled="processingActivate"
              :color="hover ? 'success' : 'info'"
              class="white--text"
              :loading="processingActivate"
              @click="activateUser()"
            >
              Aktivieren
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!formValid || processingSave"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="saveUser()"
              :loading="processingSave"
            >
              Speichern
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="showDeleteDialog" max-width="350px">
      <v-card>
        <v-card-title>Wirklich archivieren?</v-card-title>
        <v-card-text v-if="selectedUser && selectedUser._id === user._id">
          Du wirst anschließend ausgeloggt und kannst deinen Zugang nicht weiter nutzen.
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showDeleteDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="processingDelete"
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :loading="processingDelete"
              @click="archiveUser()"
            >
              Archivieren
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showVerifyDialog" max-width="350px">
      <v-card>
        <v-card-title>Wirklich verifizieren?</v-card-title>
        <v-card-actions class="px-6 pb-6">
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showVerifyDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="processingVerify"
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :loading="processingVerify"
              @click="verifyUser()"
            >
              Verifizieren
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ProfileEditor',
  props: [
    'showEditor'
  ],
  data () {
    return {
      firstName: '',
      lastName: '',
      formValid: false,
      email: null,
      password: null,
      organisation: null,
      roles: [{ text: 'Mitglied', value: 'member' }, { text: 'Admin', value: 'admin' }],
      role: null,
      emailErrors: [],
      processingActivate: false,
      processingArchive: false,
      processingVerify: false,
      processingSave: false,
      showDeleteDialog: false,
      showVerifyDialog: false
    }
  },
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('users', {
      createUser: 'create',
      patchUser: 'patch'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    ...mapMutations({
      setShow: 'SET_SHOW'
    }),
    adapt () {
      this.selectedUser = this.getUser(this.$route.params.id)
      if (this.selectedUser) {
        this.firstName = this.selectedUser.firstName
        this.lastName = this.selectedUser.lastName
        this.email = this.selectedUser.email
        this.organisation = this.selectedUser.organisation
        this.role = this.selectedUser.role
      } else {
        this.$refs.profileForm.reset()
      }
    },
    async verifyUser () {
      this.processingVerify = true
      try {
        await this.patchUser([
          this.selectedUser._id,
          {
            isVerified: true,
            verifyToken: null,
            verifyExpires: null
          },
          {}
        ])
        this.showVerifyDialog = false
      } catch (e) {
        this.createLog({ type: 'error', text: 'Verify user manually: ' + e })
        this.processingVerify = false
        this.showSnackbar({ mode: 'verify', success: false })
        return
      }
      this.processingVerify = false
      this.showSnackbar({ mode: 'verify', success: true })
    },
    async archiveUser () {
      this.processingArchive = true
      try {
        await this.patchUser([
          this.selectedUser._id,
          {
            isActive: false
          },
          {}
        ])
        if (this.selectedUser._id === this.user._id) {
          await this.$router.push('/')
          this.setShow(false)
          try {
            await this.logout()
          } catch (e) {
            this.createLog({ type: 'error', text: 'Logout: ' + e })
          }
          location.reload()
        }
        this.showDeleteDialog = false
      } catch (e) {
        this.createLog({ type: 'error', text: 'Archive user: ' + e })
        this.processingArchive = false
        this.showSnackbar({ mode: 'archive', success: false })
        return
      }
      this.processingArchive = false
      this.showSnackbar({ mode: 'archive', success: true })
    },
    async activateUser () {
      this.processingActivate = true
      try {
        await this.patchUser([
          this.selectedUser._id,
          {
            isActive: true
          },
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Activate user: ' + e })
        this.processingActivate = false
        this.showSnackbar({ mode: 'activate', success: false })
        return
      }
      this.processingActivate = false
      this.showSnackbar({ mode: 'activate', success: true })
    },
    async saveUser () {
      this.processingSave = true
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email
      }
      // Accept
      if (this.user.role === 'admin' && this.selectedUser && !this.selectedUser.isAccepted) {
        map.accepted = true
      }
      // Password
      if (this.password) {
        map.password = this.password
      }
      try {
        if (!this.selectedUser) {
          map.organisation = this.organisation
          map.role = this.role
          // Create user
          map.entry = 'invitation'
          await this.createUser(map)
          this.$closeEditor(this.$route, this.$router)
        } else {
          if (this.selectedUser.organisation !== this.organisation) {
            map.organisation = this.organisation
          }
          if (this.selectedUser.role !== this.role) {
            map.role = this.role
          }
          // Patch user
          await this.patchUser([
            this.selectedUser._id,
            map,
            {}
          ])
        }
      } catch (e) {
        if (e.className === 'conflict') {
          if (e.errors.email) {
            this.emailErrors.push('Diese Email-Adresse existiert bereits.')
          }
        } else {
          this.createLog({ type: 'error', text: 'Create user: ' + e })
        }
        this.processingSave = false
        this.showSnackbar({ mode: 'save', success: false })
        return
      }
      this.processingSave = false
      this.showSnackbar({ mode: 'save', success: true })
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('organisations', {
      organisations: 'list'
    }),
    ...mapGetters('users', {
      getUser: 'get'
    })
  },
  watch: {
    '$route.params.id' () {
      this.adapt()
    },
    email () {
      this.emailErrors = []
    }
  }
}
</script>
