<template>
  <div>
    <v-toolbar
      flat
      color="primary"
      v-if="
        ac &&
        user &&
        ac.can(user.role).createAny('voter').granted
      "
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="$router.push('/stimmberechtigte/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>Neue Stimmberechtigte</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="info"
        v-if="showEditor"
        @click="$closeEditor($route, $router)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      v-show="showEditor"
      class="pa-5"
      tile
      color="primary"
    >
      <v-form ref="voterForm" v-model="formValid">
        <v-card flat tile>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  outlined
                  dense
                  label="Organisation"
                  :rules="[rules.required, rules.longText]"
                  color="secondary"
                  :disabled="
                    !$route.params.id ||
                    !(
                      ac.can(user.role).updateAny('voter').attributes.includes('*') ||
                      ac.can(user.role).updateAny('voter').attributes.includes('name')
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  outlined
                  dense
                  label="Email für die Delegierten-Wahl"
                  :rules="[rules.required, rules.longText]"
                  :disabled="
                    !$route.params.id ||
                    !(
                      ac.can(user.role).updateAny('voter').attributes.includes('*') ||
                      ac.can(user.role).updateAny('voter').attributes.includes('email')
                    )
                  "
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  label="Anzahl der Delegierten"
                  v-model="votes"
                  clearable
                  color="secondary"
                  item-color="secondary"
                  :items="[2, 3, 4]"
                  :disabled="
                    !$route.params.id ||
                    !(
                      ac.can(user.role).updateAny('voter').attributes.includes('*') ||
                      ac.can(user.role).updateAny('voter').attributes.includes('votes')
                    )
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            class="px-4 pb-4"
          >
            <v-hover
              v-if="selectedVoter"
              v-slot:default="{ hover }"
            >
              <v-btn
                v-if="selectedVoter.isActive"
                tile
                depressed
                :disabled="processingArchive"
                :color="hover ? 'warning' : 'info'"
                class="white--text"
                :loading="processingArchive"
                @click="showArchiveDialog = true"
              >
                Archivieren
              </v-btn>
            </v-hover>
            <v-hover
              v-if="selectedVoter"
              v-slot:default="{ hover }"
            >
              <v-btn
                v-if="!selectedVoter.isActive"
                tile
                depressed
                :disabled="processingActivate"
                :color="hover ? 'success' : 'info'"
                class="white--text"
                :loading="processingActivate"
                @click="activateVoter()"
              >
                Aktivieren
              </v-btn>
            </v-hover>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :disabled="!formValid || processingSave"
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="saveVoter()"
                :loading="processingSave"
              >
                Speichern
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-sheet>
    <v-dialog v-model="showArchiveDialog" max-width="350px">
      <v-card>
        <v-card-title>Wirklich archivieren?</v-card-title>
        <v-card-text>
          Die Organisation wird bei Abstimmungen dann nicht mehr berücksichtigt.
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showArchiveDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="processingArchive"
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :loading="processingArchive"
              @click="archiveVoter()"
            >
              Archivieren
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'VoterEditor',
  props: [
    'showEditor'
  ],
  data () {
    return {
      name: null,
      email: null,
      votes: null,
      formValid: false,
      processingArchive: false,
      processingActivate: false,
      processingSave: false,
      selectedVoter: undefined,
      showArchiveDialog: false
    }
  },
  mounted () {
    this.adapt()
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('voters', {
      getVoter: 'get'
    })
  },
  methods: {
    ...mapActions([
      'showSnackbar'
    ]),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('voters', {
      createVoter: 'create',
      removeVoter: 'remove',
      patchVoter: 'patch'
    }),
    adapt () {
      this.selectedVoter = this.getVoter(this.$route.params.id)
      if (this.selectedVoter) {
        this.name = this.selectedVoter.name
        this.email = this.selectedVoter.email
        this.votes = this.selectedVoter.votes
      } else {
        this.$refs.voterForm.reset()
      }
    },
    async saveVoter (confirmed) {
      this.processingSave = true
      // Create map
      const map = {
        name: this.name,
        email: this.email,
        votes: this.votes
      }
      // Update or create
      try {
        if (this.selectedVoter) {
          this.selectedVoter = await this.patchVoter([
            this.selectedVoter._id,
            map,
            {}
          ])
        } else {
          this.selectedVoter = await this.createVoter(map)
        }
      } catch (e) {
        this.createLog({ type: 'error', text: 'Patch / create voter: ' + e.toString() + ' ' + JSON.stringify(map) })
        this.processingSave = false
        this.showSnackbar({ mode: 'save', success: false })
        return
      }
      this.processingSave = false
      this.showSnackbar({ mode: 'save', success: true })
      this.$closeEditor(this.$route, this.$router)
    },
    async archiveVoter () {
      this.processingArchive = true
      try {
        await this.patchVoter([
          this.selectedVoter._id,
          {
            isActive: false
          },
          {}
        ])
        this.showArchiveDialog = false
      } catch (e) {
        this.createLog({ type: 'error', text: 'Archive voter: ' + e })
        this.processingArchive = false
        this.showSnackbar({ mode: 'archive', success: false })
        return
      }
      this.processingArchive = false
      this.showSnackbar({ mode: 'archive', success: true })
    },
    async activateVoter () {
      this.processingActivate = true
      try {
        await this.patchVoter([
          this.selectedVoter._id,
          {
            isActive: true
          },
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Activate voter: ' + e })
        this.processingActivate = false
        this.showSnackbar({ mode: 'activate', success: false })
        return
      }
      this.processingActivate = false
      this.showSnackbar({ mode: 'activate', success: true })
    }
  },
  watch: {
    '$route.params.id' () {
      this.adapt()
    }
  }
}
</script>
