<template>
  <v-dialog
    v-model="showDownloads"
    fullscreen
  >
    <v-sheet
      dark
      height="100%"
      width="100%"
      color="primary"
      tile
      style="overflow-y: scroll;"
    >
      <v-container fluid class="px-6">
        <v-row>
          <v-toolbar
            color="transparent"
            flat
          >
            <span class="section-title">DOWNLOADS</span>
            <v-btn
              v-if="
                user &&
                ac &&
                ac.can(user.role).createAny('download').granted
              "
              @click="showDownloadEditorDialog = true"
              icon
              color="white"
            >
              <v-icon
                small
              >
                fas fa-plus
              </v-icon>
            </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            large
            @click="$router.replace({ query: { downloads: undefined } })"
          >
            <v-icon color="white">fas fa-times</v-icon>
          </v-btn>
          </v-toolbar>
        </v-row>
        <v-list
          color="transparent"
          class="mt-12 pb-4"
          two-line
        >
          <template
            v-for="(download, i) in $sortByProperty(computedFilteredDownloads, 'title')"
          >
            <v-hover
              :key="i"
              v-slot:default="{ hover }"
            >
              <v-list-item
                :ref="download._id"
                class="px-1"
                flat
                :style="hover ? 'background-color:rgba(255,255,255,0.3)' : 'background-color:transparent'"
              >
                <v-list-item-content>
                  <v-list-item-title white class="main-committee-primary">
                    <span class="mr-3">
                      {{download.title}}
                    </span>
                    <v-icon
                      small
                      v-if="
                        user &&
                        ac &&
                        ac.can(user.role).createAny('download').granted
                      "
                    >
                      {{ download.isInternal ? 'fas fa-eye-slash': 'fas fa-eye' }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action
                  v-if="
                    ac &&
                    user &&
                    ac.can(user.role).createAny('download').granted
                  "
                >
                  <v-btn
                    :color="hover ? 'white' : 'secondaryAccent'"
                    icon
                    outlined
                    tile
                    class="mr-2"
                    @click="openEditor(download)"
                  >
                    <v-icon
                      small
                    >fas fa-edit</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn
                    :color="hover ? 'white' : 'secondaryAccent'"
                    icon
                    outlined
                    tile
                    download
                    target="_blank"
                    :href="computedServerUri + '/downloads/' + download.fileName"
                  >
                    <v-icon
                      small
                    >fas fa-download</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-hover>
            <v-divider
              :key="i+'divider'"
              v-if="i !== (computedFilteredDownloads.length-1)"
              :color="info"
              class="mx-4"
            />
          </template>
        </v-list>
        <v-dialog
          tile
          overlay-color="primary"
          overlay-opacity=".91"
          v-model="showDownloadEditorDialog"
          max-width="450px"
        >
          <v-card
            tile
            flat
          >
            <v-form v-model="downloadEditorFormValid" ref="downloadEditorForm">
              <v-card-text>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      icon
                      @click="showDownloadEditorDialog = false"
                    >
                      <v-icon>
                        fas fa-times
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="mb-3"></v-divider>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      v-model="newTitle"
                      label="Titel"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="pt-0" cols="12">
                    <v-checkbox
                      outlined
                      dense
                      v-model="isInternal"
                      label="Intern (nicht in Download-Liste anzeigen)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0" cols="12">
                    <span
                      class="body-1"
                      v-if="oldFile"
                    >
                      Dateiname: {{ oldFile.name }}
                    </span>
                    <Dropzone
                      v-else
                      :serverUri="computedServerUri + '/uploads/'"
                      uploadFilePath="temporary"
                      maxFiles=1
                      maxFileSize=10
                      acceptedMimeTypes='application/pdf'
                      addFileMessage='Download-Datei'
                      :uniqueFileNameSuffix="true"
                      :existingFiles="computedExistingFile"
                      :filesUploadedCallback="fileUploaded"
                      :fileRemovedCallback="fileRemoved"
                      :isInUse="showDownloadEditorDialog"
                    />
                  </v-col>
                </v-row>
                <v-card-actions class="px-0 mt-3">
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      v-if="selectedDownload"
                      tile
                      depressed
                      :color="hover ? 'error' : 'info'"
                      class="white--text"
                      @click="deleteDownload(selectedDownload)"
                    >
                      Löschen
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'warning' : 'info'"
                      class="white--text"
                      @click="showDownloadEditorDialog = false"
                    >
                      Abbrechen
                    </v-btn>
                  </v-hover>
                  <v-spacer></v-spacer>
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      tile
                      depressed
                      :disabled="!downloadEditorFormValid || (!oldFile && !newFile)"
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="saveDownload()"
                    >
                      Speichern
                    </v-btn>
                  </v-hover>
                </v-card-actions>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import feathersClient from '@/feathers-client'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import Dropzone from './Dropzone'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Downloads',
  mixins: [makeFindMixin({ service: 'downloads', watch: true })],
  components: {
    Dropzone
  },
  data () {
    return {
      uploadService: undefined,
      newFile: undefined,
      newTitle: undefined,
      oldFile: undefined,
      isInternal: undefined,
      selectedDownload: undefined,
      downloadEditorFormValid: false,
      showDownloadEditorDialog: false
    }
  },
  mounted () {
    this.uploadService = feathersClient.service('uploads')
  },
  computed: {
    downloadsParams () {
      if (this.ac && this.user && this.ac.can(this.user.role).createAny('download').granted) {
        return { query: { }, paginate: false }
      } else {
        return { query: { $or: [{ isInternal: { $exists: false } }, { isInternal: false }] }, paginate: false }
      }
    },
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters([
      'rules',
      'showDownloads'
    ]),
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    },
    computedExistingFile () {
      return (this.oldFile)
        ? [
            {
              name: this.oldFile.fileName,
              id: this.oldFile.id,
              filePath: 'downloads/'
            }
          ]
        : []
    },
    computedFilteredDownloads () {
      if (this.ac && this.user && this.ac.can(this.user.role).createAny('download').granted) {
        return this.downloads
      } else {
        return this.downloads.filter(obj => !obj.isInternal)
      }
    }
  },
  methods: {
    ...mapMutations({
      setShowDownloads: 'SET_SHOW_DOWNLOADS'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('downloads', {
      createDownload: 'create',
      removeDownload: 'remove',
      patchDownload: 'patch'
    }),
    async deleteDownload (download) {
      await this.uploadService.remove({ fileName: download.fileName, path: 'downloads' })
      await this.removeDownload(download._id)
      this.showSnackbar({ mode: 'delete', success: true })
      this.showDownloadEditorDialog = false
    },
    openEditor (download) {
      this.selectedDownload = download
      this.newTitle = download.title
      this.isInternal = !download.isInternal ? false : download.isInternal
      this.showDownloadEditorDialog = true
      if (download) {
        this.oldFile = {
          name: download.fileName,
          id: uuidv4()
        }
      }
    },
    async saveDownload () {
      await this.$nextTick()
      if (this.selectedDownload) {
        const map = { title: this.newTitle, isInternal: !this.isInternal ? false : this.isInternal }
        try {
          await this.patchDownload([this.selectedDownload, map, {}])
        } catch (e) {
          this.createLog({ type: 'error', text: 'Patch download: ' + e })
        }
      } else {
        const map = {
          title: this.newTitle,
          fileName: this.newFile.name,
          isInternal: !this.isInternal ? false : this.isInternal
        }
        try {
          await this.createDownload(map)
          await this.moveFile(this.newFile.name)
        } catch (e) {
          this.createLog({ type: 'error', text: 'Create download: ' + e })
        }
      }
      this.showSnackbar({ mode: 'save', success: true })
      this.showDownloadEditorDialog = false
    },
    async moveFile (fileName) {
      try {
        await this.uploadService.patch(
          fileName,
          {
            oldFilePath: 'temporary',
            newFilePath: 'downloads/',
            newFileName: fileName
          }
        )
      } catch (e) {
        this.createLog({ type: 'error', text: 'Move download file: ' + e })
      }
    },
    fileUploaded (file) {
      this.newFile = { name: file.name, id: file.id }
      this.oldFile = null
    },
    fileRemoved (file) {
      if (this.oldFile && (this.oldFile.id === file.id)) {
        this.oldFile = null
      } else {
        this.newFile = null
      }
    }
  },
  watch: {
    showDownloadEditorDialog () {
      if (!this.showDownloadEditorDialog) {
        this.newTitle = undefined
        this.newFile = undefined
        this.oldFile = undefined
        this.selectedDownload = undefined
        this.$refs.downloadEditorForm.reset()
      }
    }
  }
}
</script>
