<template>
  <div>
    <v-toolbar
      flat
      color="#f5f7fa"
    >
      <span
        class="section-title primary--text"
        cols="6"
      >
        NUTZER
      </span>
    </v-toolbar>
    <v-container fluid>
      <v-row dense>
        <v-col class="text-right">
          <v-btn
            text
            :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
            @click="showFilters = true"
          >
            FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
          </v-btn>
          <v-btn
            v-if="computedFiltersActive > 0"
            icon
            color="secondary"
            @click="resetFilters()"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Suche"
            :color="search === '' ? 'secondary' : 'primary'"
            class="text-field-search"
            hide-details
            v-model="search"
            clearable
          >
            <template slot="append">
              <v-icon
                v-if="search === ''"
                class="m-3"
                small
                color="secondary"
              >
                fa-search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div ref="usersEditorContainer">
      <UserEditor
        v-show="
          ac &&
          user &&
          (
            ac.can(user.role).createAny('admin').granted ||
            (
              user._id === $route.params.id &&
              ac.can(user.role).updateOwn(user.role).granted
            )
          )
        "
        ref="userEditor"
        :showEditor="showEditor"
      />
    </div>
    <template v-if="mode === 'list'">
      <v-list>
        <v-list-item
          v-for="user in computedFilteredUsers"
          :key="user._id"
          :ref="user._id"
        >
        <v-list-item-content>
          <v-row>
            <v-col
              align-self="center"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'"
            >
              {{user.firstName}} {{user.lastName}}
              <span
                :class="{'font-italic': !getOrganisation(user.organisation)}"
              >
                ({{getOrganisation(user.organisation) ? getOrganisation(user.organisation).name : 'keiner Organisation zugeordnet' }})
              </span>
              <v-btn
                icon
                color="secondary"
                @click="$router.push('/nutzer/editor/' + user._id)"
                v-if="!$route.params.id"
                class="mb-1"
              >
                <v-icon small>fas fa-edit</v-icon>
              </v-btn>
            </v-col>
            <v-col
              align-self="center"
              cols="12"
              sm="6"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'pt-0'"
            >
              <v-chip
                small
                :outlined="user.isAccepted"
                class="mr-3 mb-1"
                :color="user.isAccepted ? 'success': 'warning'"
              >{{user.isAccepted ? 'Freigeschaltet': 'Nicht freigeschaltet'}}</v-chip>
              <v-chip
                small
                :outlined="user.isVerified"
                class="mr-3 mb-1"
                :color="user.isVerified ? 'success': 'warning'"
              >{{user.isVerified ? 'Verifiziert': 'Nicht verifiziert'}}</v-chip>
              <v-chip
                small
                :outlined="user.isActive"
                class="mr-3 mb-1"
                :color="user.isActive ? 'success': 'warning'"
              >{{user.isActive ? 'Aktiv': 'Nicht aktiv'}}</v-chip>
            </v-col>
          </v-row>
        </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Organisation(en)"
                multiple
                v-model="filterOrganisations"
                clearable
                color="secondary"
                item-color="secondary"
                :items="computedOrganisations"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                color="secondary"
                label="Akzeptierte ausblenden"
                v-model="filterAccepted"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                color="secondary"
                label="Verifizierte ausblenden"
                v-model="filterVerified"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                color="secondary"
                label="Aktive ausblenden"
                v-model="filterActive"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                color="secondary"
                label="Archivierte einblenden"
                v-model="filterArchived"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserEditor from '@/components/UserEditor'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Users',
  components: {
    UserEditor
  },
  data () {
    return {
      mode: 'list',
      showEditor: false,
      search: '',
      showFilters: false,
      filterOrganisations: [],
      filterAccepted: false,
      filterVerified: false,
      filterActive: false,
      filterArchived: false
    }
  },
  mounted () {
    this.adaptParams()
    this.findUsers()
  },
  methods: {
    ...mapActions('users', {
      findUsers: 'find'
    }),
    resetFilters () {
      this.filterOrganisations = []
      this.filterAccepted = false
      this.filterVerified = false
      this.filterActive = false
      this.filterArchived = false
    },
    openEditor (user) {
      this.showEditor = true
      if (user) {
        this.$refs[user._id][0].$el.parentNode.insertBefore(this.$refs.userEditor.$el, this.$refs[user._id][0].$el.nextSibling)
      }
      setTimeout(() => {
        this.$vuetify.goTo(this.$refs.userEditor.$el, { offset: 60 })
      }, 100)
    },
    closeEditor () {
      this.showEditor = false
      this.$refs.usersEditorContainer.parentNode.insertBefore(this.$refs.userEditor.$el, this.$refs.usersEditorContainer.nextSibling)
    },
    adaptParams () {
      if (this.$route.params.id) {
        this.openEditor(this.getUser(this.$route.params.id))
      } else {
        this.closeEditor()
      }
    }
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('users', {
      users: 'list',
      getUser: 'get'
    }),
    ...mapGetters('organisations', {
      organisations: 'list',
      getOrganisation: 'get'
    }),
    computedOrganisations () {
      return this.organisations.filter(obj => obj.isActive).map(obj => { return { value: obj._id, text: obj.name } })
    },
    computedFilteredUsers () {
      return this.$sortByProperty(
        this.users
          .filter(obj => {
            if (this.filterArchived === false && this.filterActive === false) {
              if (obj.isActive === true) {
                return true
              } else {
                return false
              }
            } else if (this.filterArchived === true && this.filterActive === false) {
              return true
            } else if (this.filterArchived === false && this.filterActive === true) {
              return false
            } else if (this.filterArchived === true && this.filterActive === true) {
              if (obj.isActive) {
                return false
              } else {
                return true
              }
            } else {
              return false
            }
          })
          .filter(obj =>
            (this.filterOrganisations.length === 0 || this.filterOrganisations.includes(obj.organisation)) &&
            (!this.filterAccepted || !obj.isAccepted) &&
            (!this.filterVerified || !obj.isVerified) &&
            (this.search === '' || !this.search || obj.firstName.toLowerCase().includes(this.search.toLowerCase()))
          ), 'firstName'
      )
    },
    computedFiltersActive () {
      let count = 0
      if (this.filterOrganisations.length !== 0) { ++count }
      if (this.filterAccepted) { ++count }
      if (this.filterVerified) { ++count }
      if (this.filterActive) { ++count }
      if (this.filterArchived) { ++count }
      return count
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    user () {
      this.findUsers()
    }
  }
}
</script>

<style>
  .v-item--active {
    color: #31aec0 !important;
  }
</style>
