import feathersClient, { makeServicePlugin, BaseModel } from '@/feathers-client'

class Vote extends BaseModel {
}
// Required for $FeathersVuex plugin to work after production transpile.
Vote.modelName = 'Vote'
const servicePath = 'votes'
const servicePlugin = makeServicePlugin({
  Model: Vote,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [
      context => {
        context.params.query = {}
        context.params.query.delegateCode = context.arguments[1].delegateCode
        return context
      }
    ]
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
