import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import feathersVuex from 'feathers-vuex'

const socket = io(process.env.VUE_APP_SERVER_URL, { transports: ['websocket'] })

const feathersClient = feathers()
  .configure(socketio(socket, { timeout: 60000 }))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        (context) => {
          if (['create', 'update', 'patch'].includes(context.method)) {
            delete context.data.__id
            delete context.data.__isTemp
          }
        }
      ]
    }
  })

export default feathersClient

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: '_id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options', '$expr', '$exists', '$and', '$or']
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
