import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

const vuetify = new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#003151',
        secondary: '#31aec0',
        accent: '#f7d21d',
        secondaryAccent: '#78838c',
        lightAccent: '#f5f7fa',
        warning: '#f59b47',
        error: '#ee6d64',
        info: '#b3c1cc',
        sjrBlue: '#003151',
        blueGrey: '#ebf0f8'
      },
      dark: {
        primary: '#003151',
        secondary: '#31aec0',
        accent: '#f7d21d',
        secondaryAccent: '#78838c',
        lightAccent: '#f5f7fa',
        warning: '#f59b47',
        error: '#ee6d64',
        info: '#b3c1cc',
        sjrBlue: '#003151',
        blueGrey: '#ebf0f8'
      }
    }
  }
})

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'fa'
})

export default vuetify
