<template>
  <div>
    <vue-dropzone
      ref="vueDropzone"
      id="vueDropzone"
      :options="dropzoneOptions"
      :headers="dropzoneOptions.headers"
      @vdropzone-removed-file="fileRemoved"
      @vdropzone-file-added="fileAdded"
      @vdropzone-success="fileUploaded"
      @vdropzone-error="fileError"
      @vdropzone-sending="fileSending"
      @vdropzone-complete="fileComplete"
      @vdropzone-max-files-exceeded="maxFilesExceeded"
      @vdropzone-queue-complete="queueComplete"
    />
    <template>
      <v-hover
        v-slot:default="{ hover }"
      >
        <div @click="$refs.vueDropzone.$el.click()" ref="interactionArea" class="previewTemplateContainer previewTemplateAddBox">
          <div>
            <i class="fas fa-images"></i>
            <div :class="hover ? 'secondary--text' : 'info--text'">+ <span class="vueDz-add-message">Datei hinzufügen</span></div>
          </div>
        </div>
      </v-hover>
    </template>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import feathersClient from '@/feathers-client'
import { v4 as uuidv4 } from 'uuid'
import EXIF from 'exif-js/exif'
import { mapActions } from 'vuex'

const path = require('path')

const customPreviewTemplate = `
  <div class="previewTemplateContainer dz-preview">
    <i class="fas fa-times-circle previewTemplateDelete">
      <span data-dz-remove />
    </i>
    <div class="previewTemplateContentContainer dz-file-preview">
      <div class="previewTemplateImage">
        <img data-dz-thumbnail />
      </div>
      <div class="previewTemplateDetails">
        <div class="previewTemplateFileName">
          <span data-dz-name></span>
        </div>
        <div class="previewTemplateSize">
          <span data-dz-size></span>
        </div>
      </div>
      <div class="previewTemplateProgress dz-progress">
        <span class="previewTemplateUpload" data-dz-uploadprogress></span>
      </div>
      <div class="previewTemplateErrorMessage">
        <span data-dz-errormessage></span>
      </div>
      <div class="previewTemplateSuccessMark">
        <i class="fas fa-check-circle"></i>
      </div>
      <div class="previewTemplateErrorMark">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <div class="previewTemplateExistingIcon">
        <i class="fas fa-check"></i>
      </div>
    </div>
  </div>
`

const imageExtensions = new Set([
  '.bmp',
  '.gif',
  '.ico',
  '.jpeg',
  '.jpg',
  '.jfif',
  '.tga',
  '.tiff',
  '.tif',
  '.eps',
  '.svg',
  '.png',
  '.webp',
  '.tga'
])

export default {
  name: 'Dropzone',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    serverUri: {
      type: String,
      required: true
    },
    isInUse: {
      type: Boolean,
      default: true
    },
    uploadFilePath: {
      type: String,
      default: '/'
    },
    maxFileSize: {
      type: Number,
      default: 100
    },
    resizeQuality: {
      type: Number,
      default: 80
    },
    resizeWidth: {
      type: Number,
      default: null
    },
    resizeHeight: {
      type: Number,
      default: null
    },
    maxFiles: {
      type: Number,
      default: 1
    },
    fileNamePrefix: {
      type: String
    },
    convertFileName: {
      type: Boolean,
      default: false
    },
    uniqueFileNameSuffix: {
      type: Boolean,
      default: false
    },
    temporaryFileSuffx: {
      type: String,
      default: ''
    },
    existingFiles: {
      type: Array
    },
    acceptedMimeTypes: {
      type: String
    },
    filesUploadedCallback: {
      type: Function,
      required: true
    },
    fileRemovedCallback: {
      type: Function,
      required: true
    },
    addFileMessage: {
      type: String,
      default: 'Datei hinzufügen.'
    },
    invalidFileTypeMessage: {
      type: String,
      default: 'Dateien dieses Typs nicht erlaubt.'
    }
  },
  data: function () {
    return {
      uploadService: null,
      uuidFilesMap: new Map(),
      dropzoneOptions: {
        paramName: 'uri',
        addRemoveLinks: false,
        maxFilesize: this.maxFileSize,
        timeout: 240000,
        renameFile: this.processFileName,
        maxFiles: this.maxFiles,
        acceptedFiles: this.acceptedMimeTypes,
        dictDefaultMessage: '',
        dictFallbackMessage: '',
        dictInvalidFileType: this.invalidFileTypeMessage,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('feathers-jwt')
        },
        url: this.serverUri
      }
    }
  },
  mounted: function () {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    if (iOS) {
      window.EXIF = EXIF
    }
    this.uploadService = feathersClient.service('uploads')
    this.$refs.vueDropzone.setOption('previewTemplate', customPreviewTemplate)
    this.updateExistingFiles(this.existingFiles, [])
    const addMessageElement = this.$refs.interactionArea.getElementsByClassName('vueDz-add-message')
    if (addMessageElement.length > 0) {
      addMessageElement[0].innerText = this.addFileMessage
    }
    this.$refs.vueDropzone.$el.appendChild(this.$refs.interactionArea)
  },
  methods: {
    ...mapActions('logger', {
      createLog: 'create'
    }),
    updateExistingFiles (newFiles, oldFiles) {
      if (!oldFiles || !newFiles) return

      // Check for removal first
      const newFileIds = newFiles.map((newFile) => { return newFile.id })
      for (let i = 0; i < oldFiles.length; i++) {
        if (!oldFiles[i].name || !oldFiles[i].id) {
          continue
        }

        const indexHit = newFileIds.indexOf(oldFiles[i].id)
        if (indexHit === -1) {
          const removedFile = oldFiles[i]
          const removableFile = this.$refs.vueDropzone.dropzone.files.find(
            (file) => { return (file.id === removedFile.id) }
          )
          // Might be removed from existing files list from outside
          if (removableFile) {
            this.$refs.vueDropzone.dropzone.removeFile(removableFile)
          }
        }
      }

      // Check for added files next
      const oldFileIds = oldFiles.map((oldFile) => { return oldFile.id })
      for (let i = 0; i < newFiles.length; i++) {
        if (!newFiles[i].name || !newFiles[i].id) {
          continue
        }

        const indexAddedFile = oldFileIds.indexOf(newFiles[i].id)
        if (indexAddedFile === -1) {
          if (this.uuidFilesMap.has(newFiles[i].id)) {
            if (!this.uuidFilesMap.get(newFiles[i].id).isMock) {
              this.uuidFilesMap.get(newFiles[i].id).removeSilently = true
              this.$refs.vueDropzone.dropzone.removeFile(this.uuidFilesMap.get(newFiles[i].id))
            }
          }

          const addedFile = newFiles[i]
          this.addMockFile(addedFile)
        }
      }
    },
    removeTemporaryFiles () {
      this.$refs.vueDropzone.dropzone.files.forEach((file) => {
        if (!file.isMock) {
          file.removeSilently = true
          this.$refs.vueDropzone.dropzone.removeFile(file)
        }
      })
    },
    addMockFile (rawMockFile) {
      if (rawMockFile.name) {
        const mockFile = {
          name: rawMockFile.name,
          size: rawMockFile.size,
          isMock: true,
          status: 'success',
          accepted: true,
          id: rawMockFile.id ? rawMockFile.id : uuidv4()
        }

        this.$refs.vueDropzone.dropzone.emit('addedfile', mockFile)
        const fileExtension = path.extname(rawMockFile.name).toLowerCase()
        if (imageExtensions.has(fileExtension)) {
          this.$refs.vueDropzone.dropzone.options.thumbnail.call(
            this.$refs.vueDropzone.dropzone,
            mockFile,
            this.computedServerUri + rawMockFile.filePath + rawMockFile.name
          )
        }
        this.$refs.vueDropzone.dropzone.emit('complete', mockFile)
        this.$refs.vueDropzone.dropzone.files.push(mockFile)
        this.checkInteractionButtonVisibility()
      }
    },
    checkInteractionButtonVisibility () {
      if (this.$refs.vueDropzone) {
        const acceptedFilesNumber = this.$refs.vueDropzone.getAcceptedFiles().length
        if ((this.$refs.vueDropzone.dropzone.options.maxFiles - acceptedFilesNumber) > 0) {
          this.$refs.interactionArea.style.display = 'inline-block'
        } else {
          this.$refs.interactionArea.style.display = 'none'
        }
      }
    },
    prepareFileRemoval (event, file) {
      file.previewElement.classList.add('removePreviewElement')
      setTimeout(() => {
        event.target.firstElementChild.click()
      }, 1100)
    },
    fileAdded (file) {
      // Move Add-Box to end of row
      this.$refs.vueDropzone.$el.appendChild(this.$refs.interactionArea)
      // Proceed adding file
      const headerOption = this.$refs.vueDropzone.dropzone.options.headers.Authorization
      if ((headerOption === 'Bearer null') || (headerOption === 'Bearer undefined')) {
        this.$refs.vueDropzone.dropzone.options.headers.Authorization = 'Bearer ' + localStorage.getItem('feathers-jwt')
      }
      const iconElements = file.previewElement.getElementsByClassName('previewTemplateDelete')
      if (iconElements.length !== 0) {
        const deleteIcon = iconElements[0]
        deleteIcon.addEventListener('click', (event) => { this.prepareFileRemoval(event, file) })
      }
      if (file.isMock) {
        file.previewElement.classList.add('previewTemplateMockFile')
      }
      if (file.size) {
        file.previewElement.classList.add('previewTemplateHasSize')
      }
      if (file.upload && file.upload.filename && (file.upload.filename !== file.name)) {
        const fileNameElements = file.previewElement.getElementsByClassName('previewTemplateFileName')
        if (fileNameElements.length !== 0) {
          const fileNameElement = fileNameElements[0]
          fileNameElement.innerHTML = file.upload.filename // Deletes the default data-dz-name element
        }
      }
      if (!file.id) {
        file.id = uuidv4()
      }
      file.maxWidth = this.resizeWidth
      file.maxHeight = this.resizeHeight
      file.quality = this.resizeQuality
      this.uuidFilesMap.set(file.id, file)
    },
    async fileRemoved (file) {
      // Handle removal of temporarily uploaded files
      if (file && !file.isMock && (file.status !== 'error')) {
        await this.uploadService.remove({ fileName: file.upload.filename, path: this.uploadFilePath })
        if (this.uuidFilesMap.has(file.id)) {
          this.uuidFilesMap.delete(file.id)
          if (this.fileRemovedCallback && !file.removeSilently) {
            this.fileRemovedCallback({
              name: file.upload.filename,
              id: file.id
            })
          }
        }
      // Handle removal of mock files
      } else if (file && file.isMock) {
        if (this.uuidFilesMap.has(file.id)) {
          const removedFile = this.uuidFilesMap.get(file.id)
          if (this.fileRemovedCallback && !file.removeSilently) {
            this.fileRemovedCallback({
              name: removedFile.name,
              id: removedFile.id
            })
          }
          this.uuidFilesMap.delete(file.id)
        }
      }
      this.checkInteractionButtonVisibility()
    },
    fileUploaded (file, response) {
      if (file && !file.isMock) {
        if ((file.status !== 'error') && this.filesUploadedCallback) {
          return this.filesUploadedCallback({
            name: file.upload.filename,
            id: file.id
          })
        }
      }
    },
    fileError (file, errorMessage, xhr) {
      this.createLog({ type: 'error', text: 'Dropzone file (' + file + ') error: ' + errorMessage })
    },
    fileSending (file, xhr, formData) {
      formData.append('maxWidth', this.resizeWidth)
      formData.append('maxHeight', this.resizeHeight)
      formData.append('quality', this.resizeQuality)
      formData.append('path', this.uploadFilePath)
      formData.append('id', file.upload.filename)
    },
    fileComplete (file) {
    },
    maxFilesExceeded (file) {
      this.$refs.vueDropzone.removeFile(file)
    },
    queueComplete () {
      this.$refs.vueDropzone.$el.appendChild(this.$refs.interactionArea)
      this.checkInteractionButtonVisibility()
    },
    processFileName (file) {
      const originalName = file.name
      const fileExtenstion = path.extname(originalName)
      let fileBaseName
      if (this.convertFileName) {
        fileBaseName = uuidv4()
      } else {
        fileBaseName = path.basename(originalName, fileExtenstion)
      }
      if (this.fileNamePrefix) {
        fileBaseName = this.fileNamePrefix + fileBaseName
      }
      if (this.uniqueFileNameSuffix) {
        const now = new Date()
        const fileNameSuffix = '_' + now.getFullYear() +
        '-' + (now.getMonth() + 1) +
        '-' + now.getDate() +
        '_' + now.getHours() +
        '_' + now.getMinutes() +
        '_' + now.getSeconds() +
        '_' + now.getMilliseconds()
        fileBaseName += fileNameSuffix
      }
      return fileBaseName + fileExtenstion + this.temporaryFileSuffx
    }
  },
  computed: {
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL + '/'
    }
  },
  watch: {
    existingFiles: {
      deep: true,
      handler (newFiles, oldFiles) {
        this.updateExistingFiles(newFiles, oldFiles)
      }
    },
    isInUse: {
      handler (newValue, oldValue) {
        if (!newValue) {
          this.removeTemporaryFiles()
        }
      }
    }
  }
}
</script>

<style>
  @keyframes pulsingDisappear {
    0%   { transform: scale(1); }
    10%  { transform: scale(1); }
    25%  { transform: scale(1.1); }
    100% { transform: scale(0); }
  }

  @-webkit-keyframes passing-through {
    0%       { opacity: 0; -webkit-transform: translateY( 40px); transform: translateY( 40px); }
    30%, 70% { opacity: 1; -webkit-transform: translateY(  0px); transform: translateY(  0px); }
    100%     { opacity: 0; -webkit-transform: translateY(-40px); transform: translateY(-40px); }
  }
  @keyframes passing-through {
    0%       { opacity: 0; -webkit-transform: translateY( 40px); transform: translateY( 40px); }
    30%, 70% { opacity: 1; -webkit-transform: translateY(  0px); transform: translateY(  0px); }
    100%     { opacity: 0; -webkit-transform: translateY(-40px); transform: translateY(-40px); }
  }

  @-webkit-keyframes slide-in {
    0%   { opacity: 0; -webkit-transform: translateY(40px); transform: translateY(40px); }
    100% { opacity: 1; -webkit-transform: translateY( 0px); transform: translateY (0px); }
  }
  @keyframes slide-in {
    0%   { opacity: 0; -webkit-transform: translateY(40px); transform: translateY(40px); }
    100% { opacity: 1; -webkit-transform: translateY( 0px); transform: translateY( 0px); }
  }

  @-webkit-keyframes pulse {
    0%   { -webkit-transform: scale(1  ); transform: scale(1  ); }
    35%  { -webkit-transform: scale(1.1); transform: scale(1.1); }
    80%  { -webkit-transform: scale(1  ); transform: scale(1  ); }
    100% { -webkit-transform: scale(1  ); transform: scale(1  ); }
  }
  @keyframes pulse {
    0%   { -webkit-transform: scale(1  ); transform: scale(1  ); }
    35%  { -webkit-transform: scale(1.1); transform: scale(1.1); }
    80%  { -webkit-transform: scale(1  ); transform: scale(1  ); }
    100% { -webkit-transform: scale(1  ); transform: scale(1  ); }
  }

.vue-dropzone {
  font-family: inherit !important;
  letter-spacing: inherit !important;
}
.dropzone {
  position: relative;
  min-height: 244px !important;
  border: none;
  background: white !important;
  padding: 10px 2px 10px 2px !important;
  border: none !important;
  outline-style: dashed !important;
  outline-width: 2px;
  outline-color: #31aec000;
  background-color: #31aec000;
  transition: outline-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.dropzone.dz-drag-hover {
  outline-color: #31aec0FF !important;
  background-color: #31aec011 !important;
}
.dropzone div.dz-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0px !important;
}

.previewTemplateAddBox {
  position: relative;
  vertical-align: top;
  border: 2px dashed #b3c1cc !important;
  cursor: pointer !important;
}
.previewTemplateAddBox:hover {
  border: 2px dashed #31aec0 !important;
  cursor: pointer !important;
}
.previewTemplateAddBox:hover div,
.previewTemplateAddBox:hover i,
.previewTemplateAddBox:hover span {
  cursor: pointer !important;
}

.previewTemplateAddBox > div {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  transform: translateY(-50%);
  text-align: center;
}

.previewTemplateAddBox > div > i {
  font-size: 24px;
}

.previewTemplateContainer {
  position: relative;
  display: inline-block;
  margin-top: 15px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 20px !important;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  transition: margin-left 0.3s ease-in 0.8s, margin-right 0.3s ease-in 0.8s, width 0.3s ease-in-out 0.8s;
}

.removePreviewElement.previewTemplateContainer {
  width: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.removePreviewElement .previewTemplateDelete {
  transform: perspective(50px) rotateY(90deg) !important;
}
.removePreviewElement .previewTemplateContentContainer {
  transform: scale(0) !important;
  animation: pulsingDisappear 0.8s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .previewTemplateContentContainer {
  position: absolute;
  display: inline-block;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transform-origin: 100% 0%;
  vertical-align: top;
  border-radius: 0px;
}
.dropzone .previewTemplateContainer.previewTemplateMockFile .previewTemplateContentContainer {
  border: 2px solid #666666;
}
.dropzone .previewTemplateContainer:not(.previewTemplateMockFile) .previewTemplateContentContainer {
  border: 2px dotted #666666;
}

.dropzone .previewTemplateDelete {
  position: absolute;
  display: inline-block;
  top: -12px;
  right: -12px;
  font-size: 24px;
  color: #FF8888;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: perspective(0px) rotateY(0deg);
  transition: color 0.2s ease-out, transform 0.3s ease-in-out 0.7s;
  cursor: pointer;
  z-index: 5;
}
.dropzone .previewTemplateDelete:hover {
  color: #FF0000;
}

.dropzone .dz-image-preview.previewTemplateContainer:not(.previewTemplateHasSize) .previewTemplateDetails {
  display: none;
}
.dropzone .previewTemplateContentContainer .previewTemplateDetails {
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  width: 100%;
  padding-left: 0.8em;
  padding-right: 0.8em;
  background-color: #FFFFFF99;
  opacity: 1;
  z-index: 4;
  transition: background-color 0.3s ease-out;
}
.dropzone .previewTemplateContentContainer:hover .previewTemplateDetails {
  background-color: #FFFFFFBB;
}

.dropzone .dz-image-preview .previewTemplateDetails .previewTemplateFileName {
  display: none;
}
.dropzone .previewTemplateContentContainer .previewTemplateDetails .previewTemplateFileName {
  font-size: 14px;
  color: #222222;
  letter-spacing: -0.5px;
  padding-top: 0.4em;
  padding-bottom: 0.2em;
  transition: padding-top 0.3s ease-out, padding-bottom 0.3s ease-out;
}
.dropzone .previewTemplateContentContainer:hover .previewTemplateDetails .previewTemplateFileName {
  padding-top: 0.8em;
  padding-bottom: 0.4em;
}

.dropzone .previewTemplateContainer:not(.previewTemplateHasSize) .previewTemplateDetails .previewTemplateSize {
  display: none;
}
.dropzone .previewTemplateContentContainer .previewTemplateDetails .previewTemplateSize {
  font-size: 16px;
  color: #666666;
  line-height: 0%;
  opacity: 0;
  padding-top: 0em;
  padding-bottom: 0em;
  transition: padding-top 0.3s ease-out, padding-bottom 0.3s ease-out, opacity 0.3s ease-out, line-height 0.3s ease-out;
}
.dropzone .previewTemplateContentContainer:hover .previewTemplateDetails .previewTemplateSize {
  opacity: 1;
  line-height: 100%;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.dropzone .previewTemplateContentContainer .previewTemplateImage {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
}
.dropzone .previewTemplateContentContainer.dz-file-preview .previewTemplateImage {
  background: #BBBBBB;
  background: linear-gradient(to bottom, #BBBBBB, #999999);
}
.dropzone .previewTemplateContentContainer .previewTemplateImage img {
  display: block;
  filter: blur(0px) !important;
  transform: scale(1.05);
  transition: filter 0.3s ease-out, transform 0.3s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.dropzone .previewTemplateContentContainer:hover .previewTemplateImage img {
  transform: scale(1.1);
  filter: blur(6px) !important;
}

.dropzone .previewTemplateMockFile .previewTemplateContentContainer .previewTemplateImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone .previewTemplateContainer.dz-success .previewTemplateSuccessMark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .previewTemplateContainer.dz-error .previewTemplateErrorMark {
  opacity: 1;
  -webkit-animation: slide-in 1s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .previewTemplateContentContainer .previewTemplateSuccessMark,
.dropzone .previewTemplateContentContainer .previewTemplateErrorMark {
  position: absolute;
  display: block;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
  opacity: 0;
  z-index: 3;
  pointer-events: none;
}
.dropzone .previewTemplateContentContainer .previewTemplateSuccessMark i,
.dropzone .previewTemplateContentContainer .previewTemplateErrorMark i {
  display: inline-block;
  font-size: 54px;
  color: rgba(255, 255, 255, 0.8);
}

.dropzone .previewTemplateContainer.previewTemplateMockFile .previewTemplateProgress {
  display: none;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
  -webkit-animation: pulse 0.4s ease;
  animation: pulse 0.4s ease;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
}

.dropzone .previewTemplateContentContainer .previewTemplateProgress {
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px !important;
  margin-left: -40px !important;
  width: 80px;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  z-index: 5;
  pointer-events: none;
}
.dropzone .previewTemplateContentContainer .previewTemplateProgress .previewTemplateUpload {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  -webkit-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
}
.dropzone .previewTemplateContentContainer .previewTemplateErrorMessage {
  position: absolute;
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: white;
  text-align: center;
  width: 150px;
  padding: 0.6em 0.6em;
  bottom: 0.8em;
  left: 50%;
  transform: translateX(-50%);
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  opacity: 0;
  pointer-events: none;
  z-index: 5;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.dropzone .previewTemplateContainer.dz-error:hover .previewTemplateErrorMessage {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .previewTemplateContentContainer .previewTemplateErrorMessage:after {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.dropzone .previewTemplateContainer.previewTemplateMockFile .previewTemplateExistingIcon {
  position: absolute;
  display: inline-block;
  bottom: 0px;
  right: 0px;
  padding: 0.4em 0.4em;
  font-size: 15px;
  line-height: 15px;
  color: #FFFFFF;
  background-color: #666666;
  z-index: 4;
}

.dropzone .previewTemplateContainer:not(.previewTemplateMockFile) .previewTemplateExistingIcon {
  display: none !important;
}

</style>
