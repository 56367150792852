<template>
  <v-container
    class="pa-0 ma-0"
  >
    <a
      :href="partner.link ? partner.link : 'javascript:void(0);'"
      :target="partner.link ? '_blank' : ''"
      :style="'text-decoration:none;' + (partner.link ? '' : 'cursor:default !important;')"
    >
    <v-hover v-slot:default="{ hover }">
      <v-card
        tile
        outlined
        :class="hover ? 'elevation-3' : ''"
      >
        <v-card-text>
          <v-col
            class="text-center"
          >
            <v-img
              v-if="partner.pic"
              :src="s3 + partner.pic.name"
              height="200"
              contain
            />
            <v-icon
              v-else
              size="200"
            >
              fas fa-user
            </v-icon>
          </v-col>
          <v-card-text
            class="primary--text card-title partner-container-content"
          >
            <v-row dense>
              <v-col class="card-title">
                {{partner.name}}
              </v-col>
            </v-row>
            <v-row
              dense
              v-if="partner.phone || partner.email || partner.position"
            >
            </v-row>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-hover>
    </a>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PartnerContainer',
  props: [
    'partner'
  ],
  computed: {
    ...mapGetters([
      'ac',
      's3'
    ])
  }
}
</script>

<style scoped>
  .partner-container-content {
    box-sizing: border-box;
  }
</style>
