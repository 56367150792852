<template>
  <div class="no-overflow">
    <v-row
    >
      <v-col
        cols="12"
        md="5"
        class="pa-0"
      >
        <v-sheet
          tile
          :color="backgroundColor"
        >
          <v-row>
            <v-col
              v-if="showPreHeadline"
              class="
                px-10
                pt-10
                mb-n10
                header-pre-title
                white--text
              "
            >
              {{preHeadline}}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="
              pa-10
              header-title
              white--text
            "
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
              v-html="headline"
            >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="
              pa-10
              pt-0
              header-body
              white--text
            "
            >
              {{headlineSubtitle}}
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        md="7"
        class="pa-0"
        :style="$vuetify.breakpoint.mdAndUp ? 'background-image:url(' + imageSource + '); background-size:cover; background-position: center' : ''"
      >
        <v-img
          v-if="!$vuetify.breakpoint.mdAndUp"
          :src="imageSource"
          max-height="350"
          :aspect-ratio="16/9"
        >
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    preHeadline: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      required: true
    },
    headlineSubtitle: {
      type: String,
      required: true
    },
    imageSource: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  }
}
</script>
