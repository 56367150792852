<template>
  <div>
    <v-form ref="baseForm" v-model="formValid">
      <v-card flat tile>
        <v-card-text>
          <v-row class="mb-5">
            <v-col class="primary--text section-title">
              Basis-Informationen
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                outlined
                dense
                label="Organisations-Name"
                :rules="[rules.required, rules.longText]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="slug"
                outlined
                dense
                label="Organisations-Slug (URL in https://sjr-ulm.de/URL)"
                :rules="[rules.longText, rules.noSpecials]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="type"
                :items="[
                  {text: 'SjR Ulm', value: 'sjr'},
                  {text: 'Einrichtung', value: 'institution'},
                  {text: 'Mitglied', value: 'member'},
                  {text: 'Kooperation', value: 'cooperation'}
                ]"
                color="secondary"
                item-color="secondary"
                label="Typ"
                dense
                outlined
                :rules="[rules.required]"
                :disabled="
                  !$route.params.id ||
                  !(
                    ac.can(user.role).updateAny('organisation').attributes.includes('*') ||
                    ac.can(user.role).updateAny('organisation').attributes.includes('type') ||
                    (
                      organisation &&
                      user.organisation === organisation._id &&
                      (
                        ac.can(user.role).updateOwn('organisation').attributes.includes('*') ||
                        ac.can(user.role).updateOwn('organisation').attributes.includes('type')
                      )
                    )
                  )
                "
              ></v-select>
            </v-col>
          </v-row>
          <template v-if="type === 'cooperation' || type === 'own'">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="link"
                  outlined
                  dense
                  label="Link"
                  :rules="[rules.required, rules.longText]"
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="type === 'member' || type === 'institution'">
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="categories"
                  :items="organisationCategories"
                  multiple
                  dense
                  color="secondary"
                  item-color="secondary"
                  label="Kategorien"
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="Straße"
                  v-model="addressStreet"
                  :rules="[rules.shortText, rules.required]"
                  color="secondary"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="Hausnummer"
                  v-model="addressNumber"
                  :rules="[rules.shortText, rules.required]"
                  color="secondary"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="PLZ"
                  v-model="addressPostal"
                  :rules="[rules.shortText, rules.required]"
                  color="secondary"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  label="Stadt"
                  v-model="addressCity"
                  :rules="[rules.shortText, rules.required]"
                  color="secondary"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                Die Werte können manuell eingetragen oder auf Grundlage der vollständig angegebenen Adresse automatisch ermittelt werden.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  dense
                  label="Breitengrad (lat)"
                  v-model="lat"
                  :rules="[rules.required]"
                  type="number"
                  color="secondary"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  outlined
                  dense
                  label="Längengrad (lng)"
                  v-model="lng"
                  :rules="[rules.required]"
                  type="number"
                  color="secondary"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    @click="createGeocode()"
                    block
                    title
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    depressed
                    :disabled="!addressStreet || !addressNumber || !addressPostal || !addressCity"
                  >
                    Ermitteltn
                  </v-btn>
                </v-hover>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="12" class="primary--text section-sub-title">Logo</v-col>
            <v-col cols="12">
              <Dropzone
                :serverUri="computedServerUri + '/uploads/'"
                uploadFilePath="temporary"
                maxFiles=1
                resizeWidth=400
                resizeHeight=400
                maxFileSize=2
                acceptedMimeTypes='image/png, image/jpeg'
                addFileMessage='Organisations-Logo'
                :convertFileName="true"
                :existingFiles="computedExistingFiles"
                :filesUploadedCallback="fileUploaded"
                :fileRemovedCallback="fileRemoved"
                :isInUse="showEditor"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="primary--text section-sub-title">Buchungs-Kontakt</v-col>
            <v-col cols="12">
              <v-text-field
                v-model="bookingEmail"
                outlined
                dense
                label="Email für Buchungs-Benachrichtigungen (ist keine angegeben, werden diese an alle Vereins-Mitglieder versendet)"
                :rules="[rules.longText, rules.email]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="primary--text section-sub-title">Zahlungsinformationen (Bankverbindung etc.)</v-col>
            <v-col cols="12" class="mb-6 py-6 ">
              <tiptap-vuetify
                placeholder="Text"
                v-model="paymentInformation"
                :card-props="{ tile: true, flat: true, outlined: true }"
                :extensions="extensions"
              >
              </tiptap-vuetify>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="primary--text section-sub-title">Rechtliches (AGBs etc. - nicht Zahlungsinformationen)</v-col>
            <v-col cols="12" class="mb-6 py-6 ">
              <tiptap-vuetify
                placeholder="Text"
                v-model="bookingInformation"
                :card-props="{ tile: true, flat: true, outlined: true }"
                :extensions="extensions"
              >
              </tiptap-vuetify>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-hover
            v-if="organisation"
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="organisation.isActive &&
              (
                ac.can(user).updateAny('organisation').attributes.includes('*') ||
                ac.can(user).updateAny('organisation').attributes.includes('isActive')
              )"
              tile
              depressed
              :disabled="processingArchive"
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :loading="processingArchive"
              @click="archiveOrganisation()"
            >
              Archivieren
            </v-btn>
          </v-hover>
          <v-hover
            v-if="organisation"
            v-slot:default="{ hover }"
          >
            <v-btn
              v-if="
              !organisation.isActive &&
              (
                ac.can(user).updateAny('organisation').attributes.includes('*') ||
                ac.can(user).updateAny('organisation').attributes.includes('isActive')
              )"
              tile
              depressed
              :disabled="processingActivate"
              :color="hover ? 'success' : 'info'"
              class="white--text"
              :loading="processingActivate"
              @click="activateOrganisation()"
            >
              Aktivieren
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!formValid || processingSave || (!pic && !newPic)"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              :loading="processingSave"
              @click="saveOrganisation()"
            >
              Speichern
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import feathersClient from '@/feathers-client'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from './Dropzone'
import { v4 as uuidv4 } from 'uuid'
import { TiptapVuetify, Bold, Italic, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'

export default {
  name: 'BaseEditor',
  props: [
    'organisation',
    'showEditor'
  ],
  components: {
    Dropzone,
    TiptapVuetify
  },
  data () {
    return {
      id: null,
      type: null,
      link: null,
      name: '',
      slug: null,
      bookingEmail: null,
      ts: null,
      addressNumber: null,
      addressStreet: null,
      addressPostal: null,
      addressCity: null,
      lat: null,
      lng: null,
      pic: undefined,
      newPic: undefined,
      uploadService: undefined,
      geocoderService: undefined,
      formValid: false,
      categories: [],
      processingSave: false,
      processingArchive: false,
      processingActivate: false,
      paymentInformation: undefined,
      bookingInformation: undefined,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList
      ]
    }
  },
  mounted () {
    this.ts = Date.now()
    this.uploadService = feathersClient.service('uploads')
    this.geocoderService = feathersClient.service('geocoder')
    this.adapt()
  },
  methods: {
    ...mapActions('organisations', {
      createOrganisation: 'create',
      patchOrganisation: 'patch'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    async createGeocode () {
      const location = await this.geocoderService.create({
        street: this.addressStreet,
        number: this.addressNumber,
        postal: this.addressPostal,
        city: this.addressCity
      })
      this.lat = location.lat
      this.lng = location.lng
    },
    adapt () {
      if (this.organisation) {
        this.id = this.organisation._id
        this.name = this.organisation.name
        this.slug = this.organisation.slug
        this.bookingEmail = this.organisation.bookingEmail
        this.type = this.organisation.type
        this.paymentInformation = this.organisation.paymentInformation
        this.bookingInformation = this.organisation.bookingInformation
        this.pic = { name: this.organisation.pic, id: uuidv4() }
        if (this.organisation.address) {
          this.addressPostal = this.organisation.address.postal
          this.addressStreet = this.organisation.address.street
          this.addressNumber = this.organisation.address.number
          this.addressCity = this.organisation.address.city
        }
        if (this.organisation.location) {
          this.lat = this.organisation.location.lat
          this.lng = this.organisation.location.lng
        }
        if (this.organisation.categories && this.organisation.categories.length > 0) {
          this.categories = this.organisation.categories
        } else {
          this.categories = []
        }
        if (this.organisation.link) {
          this.link = this.organisation.link
        }
      } else {
        this.$refs.baseForm.reset()
        this.pic = undefined
      }
      this.newPic = undefined
    },
    async saveOrganisation () {
      this.processingSave = true
      let originalPic
      // Delete old
      if (!this.pic && this.organisation && this.organisation.pic) {
        try {
          await this.uploadService.remove({ fileName: this.organisation.pic, path: 'pics/' + this.organisation._id + '/logo' })
          await this.patchOrganisation([
            this.organisation._id,
            {
              pic: null
            },
            {}
          ])
        } catch (e) {
          this.createLog({ type: 'error', text: 'Delete old organisation pic: ' + e })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      }
      // Save data
      const map = {
        name: this.name,
        slug: this.slug,
        bookingEmail: this.bookingEmail,
        link: this.link,
        pic: null,
        categories: this.categories,
        address: {
          street: this.addressStreet,
          number: this.addressNumber,
          postal: this.addressPostal,
          city: this.addressCity
        },
        location: {
          lat: this.lat,
          lng: this.lng
        },
        paymentInformation: this.paymentInformation,
        bookingInformation: this.bookingInformation
      }
      if (!this.organisation) {
        map.type = this.type
        // Create organisation
        try {
          const tmpOrganisation = await this.createOrganisation(map)
          this.id = tmpOrganisation._id
        } catch (e) {
          this.createLog({ type: 'error', text: 'Create organisation: ' + e })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      } else {
        originalPic = this.organisation.pic
        if (this.type !== this.organisation.type) {
          map.type = this.type
        }
        // Patch organisation
        try {
          await this.patchOrganisation([
            this.organisation._id,
            map,
            {}
          ])
        } catch (e) {
          console.log(e)
          this.createLog({ type: 'error', text: 'Patch Organisation: ' + e.toString() })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      }
      // Keep old
      if (this.pic && this.organisation && originalPic) {
        await this.patchOrganisationPic(originalPic, this.organisation._id)
      } else if (this.newPic) {
        await this.moveFile(this.newPic.name, this.id)
        this.pic = this.newPic
        await this.patchOrganisationPic(this.newPic.name, this.id)
      }
      this.processingSave = false
      this.showSnackbar({ mode: 'save', success: true })
      this.$router.push({ params: { id: this.id } })
    },
    async moveFile (newPicName, organisationId) {
      try {
        await this.uploadService.patch(
          newPicName,
          {
            oldFilePath: 'temporary',
            newFilePath: 'pics/' + organisationId + '/logo/',
            newFileName: newPicName
          }
        )
      } catch (e) {
        this.createLog({ type: 'error', text: 'Move organisation pic: ' + e })
      }
    },
    async patchOrganisationPic (newPicName, organisationId) {
      try {
        await this.patchOrganisation([
          organisationId,
          { pic: newPicName },
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Patch organisation pic: ' + e })
      }
    },
    async archiveOrganisation () {
      this.processingArchive = true
      try {
        await this.patchOrganisation([
          this.organisation._id,
          {
            isActive: false
          },
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Archive organisation: ' + e })
        this.processingArchive = false
        this.showSnackbar({ mode: 'archive', success: false })
        return
      }
      this.processingArchive = false
      this.showSnackbar({ mode: 'archive', success: true })
    },
    async activateOrganisation () {
      this.processingActivate = true
      try {
        await this.patchOrganisation([
          this.organisation._id,
          {
            isActive: true
          },
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Activate organisation: ' + e })
        this.processingActivate = false
        this.showSnackbar({ mode: 'activate', success: false })
        return
      }
      this.processingActivate = false
      this.showSnackbar({ mode: 'activate', success: true })
    },
    fileUploaded (file) {
      this.newPic = { name: file.name, id: file.id }
      this.pic = null
    },
    fileRemoved (file) {
      if (this.pic && this.pic.id === file.id) {
        this.pic = null
      } else {
        this.newPic = null
      }
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac',
      'organisationCategories'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    },
    computedExistingFiles () {
      return (this.pic && this.id)
        ? [
            {
              name: this.pic.name,
              id: this.pic.id,
              filePath: 'pics/' + this.id + '/logo/'
            }
          ]
        : []
    }
  },
  watch: {
    organisation () {
      this.adapt()
    },
    bookingInformation () {
      if (this.bookingInformation) {
        this.bookingInformation = this.$sanitize(this.bookingInformation)
      }
    }
  }
}
</script>
