import feathersClient, { makeServicePlugin, BaseModel } from '@/feathers-client'

class Votings extends BaseModel {
}
// Required for $FeathersVuex plugin to work after production transpile.
Votings.modelName = 'Voting'
const servicePath = 'votings'
const servicePlugin = makeServicePlugin({
  Model: Votings,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [
      context => {
        context.params.query = {}
        context.params.query.boardCode = context.arguments[1].boardCode
        context.params.query.delegateCode = context.arguments[1].delegateCode
        return context
      }
    ],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
