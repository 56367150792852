// Bring in the imports from the feathers-client.js file.
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

import Store from '../store'
const AC = require('accesscontrol')

// Extend the base class
class AccessControl extends BaseModel {
}
AccessControl.modelName = 'AccessControl'
const servicePath = 'accesscontrol'
const servicePlugin = makeServicePlugin({
  Model: AccessControl,
  service: feathersClient.service(servicePath),
  servicePath
})

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [(context) => {
      const ac = new AC(context.result[0])
      ac.lock()
      Store.commit('SET_ACCESS_CONTROL', ac)
    }],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
