<template>
  <div>
    <vue-headful
      title="SJR Ulm - Neuigkeiten"
      description="Hier findet ihr Neuigkeiten rund um den Stadtjugendring Ulm e.V. und seine Einrichtungen und Mitglieder."
    />
    <Header
      headline="WILLKOMMEN <wbr>BEIM <wbr>STADT<wbr>JUGEND<wbr>RING<wbr> ULM E.V."
      headlineSubtitle="Hier könnt Ihr Euch über unsere Mitglieder, Einrichtungen, über aktuelle Angebote und die vielen Projekte und Angebote der Kinder- und Jugendarbeit in Ulm informieren."
      imageSource="/img/slider_1.jpg"
      backgroundColor="#f7d21d"
    />
    <News/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import News from '@/components/News.vue'

export default {
  name: 'Home',
  components: {
    Header,
    News
  }
}
</script>
