import Vue from 'vue'

const globals = {
  install (Vue) {
    Vue.prototype.$allEvents = (events) => {
      const allEvents = []
      for (const event of events) {
        for (const date of event.dates) {
          const tmpEvent = JSON.parse(JSON.stringify(event))
          if (!tmpEvent.date) {
            tmpEvent.date = {}
          }
          tmpEvent.date.start = date.start
          tmpEvent.date.end = date.end
          delete tmpEvent.dates
          allEvents.push(tmpEvent)
        }
      }
      return allEvents
    }
    Vue.prototype.$sortByProperty = (unsortedArray, property) => {
      try {
        return unsortedArray.sort((a, b) => {
          if (Object.byString(a, property).toLowerCase() < Object.byString(b, property).toLowerCase()) { return -1 }
          if (Object.byString(a, property).toLowerCase() > Object.byString(b, property).toLowerCase()) { return 1 }
          return 0
        })
      } catch (e) {
        return unsortedArray
      }
    }
    Vue.prototype.$truncate = (string, length) => {
      if (string.length > length) {
        string = string.substr(string, length) + ' ...'
      }
      return string
    }
    Vue.prototype.$closeEditor = (route, router) => {
      let tmpRoute = route.fullPath
      tmpRoute = tmpRoute.split('/')
      tmpRoute.pop()
      tmpRoute = tmpRoute.join('/')
      tmpRoute = tmpRoute.replace('/editor', '')
      router.push(tmpRoute)
    }
    Vue.prototype.$filterFullName = (item, queryText, itemText) => {
      const textOne = item.firstName.toLowerCase()
      const textTwo = item.lastName.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    }
  }
}

Object.byString = function (o, s) {
  try {
    s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    s = s.replace(/^\./, '') // strip a leading dot
    const a = s.split('.')
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  } catch (e) {
    return o
  }
}

Vue.use(globals)
