<template>
  <div>
    <v-container>
      <v-card v-if="computedMode === 'verifying'">
        <v-card-title>
          Verifiziere E-Mail-Adresse
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-progress-circular
                indeterminate
                size="100"
                color="primary"
              >
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="computedMode === 'verified'">
        <v-card-title>
          Verifiziert
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-icon
                size="100px"
                color="success"
              >fas fa-check-circle</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              Vielen Dank!
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              Autmatische Weiterleitung in {{countdown}} Sekunden ...
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="$router.push('/neuigkeiten?login=true')"
                >
                  Zum Login
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-form v-model="resetFormValid">
      <v-card v-if="computedMode === 'resetting'">
        <v-card-title>
          Passwort zurücksetzen
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required, rules.password]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Passwort wiederholen"
                type="password"
                v-model="passwordConfirm"
                :rules="[rules.required, rules.password, (e) => e === password || 'Passwörter nicht identisch']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!resetFormValid"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="resetPassword()"
            >
              Passwort ändern
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
      </v-form>
      <v-card v-if="computedMode === 'resetted'">
        <v-card-title>
          Passwort erfolgreich geändert
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-icon
                size="100px"
                color="success"
              >fas fa-check-circle</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              Vielen Dank! Du kannst dich nun am System anmelden!
            </v-col>
            <v-col>
              Autmatische Weiterleitung in {{countdown}} Sekunden ...
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="$router.push('/neuigkeiten?login=true')"
                >
                  Zum Login
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="computedMode === 'error'">
        <v-card-title>
          Fehler
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-icon
                size="100px"
                color="error"
              >fas fa-exclamation-triangle</v-icon>
            </v-col>
            <v-col cols="12">
              {{errorText}}
            </v-col>
            <template  v-if="errorText.startsWith('Der Aktivierungs-Link ist abgelaufen.')">
              <v-col cols="12">
                <v-text-field
                  label="E-Mail"
                  v-model="resendEmail"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    tile
                    depressed
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    @click="resendVerify()"
                    :disabled="!resendEmail"
                  >
                    Neuen Link senden
                  </v-btn>
                </v-hover>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Verify',
  data: () => ({
    done: 0,
    countdown: 5,
    errorText: '',
    resetFormValid: false,
    password: '',
    passwordConfirm: '',
    resendEmail: null
  }),
  mounted () {
    setTimeout(() => {
      if (this.computedMode === 'verifying') {
        setTimeout(() => {
          this.sendVerifytoServer()
        }, 1000)
      }
    }, 1000)
  },
  methods: {
    ...mapActions('authManagement', {
      createAuth: 'create'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    resetPassword () {
      this.createAuth({
        action: 'resetPwdLong',
        value: {
          token: this.$route.params.token,
          password: this.password
        }
      })
        .then(() => {
          this.done = 1
          const redirectInterval = setInterval(() => {
            this.countdown -= 1
            if (this.countdown === 0) {
              clearInterval(redirectInterval)
              this.$router.push('/neuigkeiten?login=true')
            }
          }, 1000)
        })
        .catch((e) => {
          this.createLog({ type: 'error', text: 'Reset pwd: ' + e })
          this.done = -1
          this.errorText = e.message
        })
    },
    resendVerify () {
      this.createAuth({
        action: 'resendVerifySignup',
        value: { email: this.resendEmail }
      })
        .then(() => {
          this.done = 1
          const redirectInterval = setInterval(() => {
            this.countdown -= 1
            if (this.countdown === 0) {
              clearInterval(redirectInterval)
              this.$router.push('/neuigkeiten?login=true')
            }
          }, 1000)
        })
        .catch((e) => {
          this.createLog({ type: 'error', text: 'Resend verify: ' + e })
          this.done = -1
          this.errorText = e.message
        })
    },
    sendVerifytoServer  () {
      this.createAuth({
        action: 'verifySignupLong',
        value: this.$route.params.token
      })
        .then((result) => {
          this.done = 1
          const redirectInterval = setInterval(() => {
            this.countdown -= 1
            if (this.countdown === 0) {
              clearInterval(redirectInterval)
              this.$router.push('/neuigkeiten?login=true')
            }
          }, 1000)
        })
        .catch((e) => {
          this.createLog({ type: 'error', text: 'Verify: ' + e + ' ' + this.$route.params.token })
          if (e.message.startsWith('User not found')) {
            this.errorText = 'Nutzer nicht bekannt oder bereits verifiziert.'
          } else if (e.message.startsWith('Verification token has expired')) {
            this.errorText = 'Der Aktivierungs-Link ist abgelaufen. Bitte gib deine Email-Adresse ein, damit wir dir einen neuen zusenden können.'
          } else {
            this.errorText = e.message
          }
          this.done = -1
        })
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ]),
    computedMode () {
      if (this.$route.params.type === 'verify' && this.done === 0) {
        return 'verifying'
      } else if (this.$route.params.type === 'verify' && this.done === 1) {
        return 'verified'
      } else if (this.$route.params.type === 'reset' && this.done === 0) {
        return 'resetting'
      } else if (this.$route.params.type === 'reset' && this.done === 1) {
        return 'resetted'
      } else if (this.done === -1) {
        return 'error'
      } else {
        return ''
      }
    }
  }
}
</script>
