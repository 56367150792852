<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          Ernennung der Delegierten
        </v-card-title>
        <template v-if="error">
          <v-card-text>
            <v-row>
              <v-col>
                <v-alert color="error" dark>
                  {{error}}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-alert tile outlined color="primary">
                Hallo {{voter ? voter.name : ''}},<br><br>
                bitte trage in die unten stehende Liste deine {{voter ? voter.votes : '-'}} Delegierten für die nachfolgende Abstimmung ein und drücke anschließend jeweils auf das Flieger-Symbol.
                Die Person erhält anschließend eine Email mit einem Link, über den Sie dann online ihre Stimme abgeben kann.<br><br>
                <v-sheet
                  v-if="voting"
                  color="#eee"
                  tile
                  class="pa-3 mb-5"
                >
                  <v-row>
                    <v-col class="title">
                      {{voting ? voting.title : ''}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-html="$sanitize(voting.description)"></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="(question, i) in voting.questions"
                      :key="i"
                      cols="12"
                    >
                      <v-sheet
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col cols="12">{{question.title}}</v-col>
                          <v-col cols="12" v-if="question.description && question.description !== ''">{{question.description}}</v-col>
                          <template
                            v-if="question.links && question.links.length > 0"
                          >
                            <v-col
                              cols="12"
                              v-for="(link, j) in question.links"
                              :key="j"
                            >
                              <a
                                :href="link.url"
                                target="_blank"
                              >
                                <v-icon x-small color="primary" class="mr-1">fas fa-external-link-alt</v-icon>
                                {{link.text ? link.text : link.url}}
                              </a>
                            </v-col>
                          </template>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
                Bei Fragen kannst du dich gerne jederzeit an uns wenden.<br>
                Dein Stadtjugendring
                </v-alert>
              </v-col>
            </v-row>
            <template
              v-if="voter"
            >
              <template
                v-for="(delegate, i) in delegates"
              >
                <v-form :key="i" v-model="formValid[i]">
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        label="Vor- und Nachname"
                        v-model="delegates[i].name"
                        :rules="[rules.required]"
                        color="secondary"
                        outlined
                        dense
                        :disabled="delegate.sent"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-text-field
                        label="Email-Adresse"
                        v-model="delegates[i].email"
                        color="secondary"
                        outlined
                        dense
                        :disabled="delegate.sent"
                        :rules="[rules.required, rules.email, v => delegates.filter(obj => obj.email === v).length <= 1 || 'Verschiedene Email-Adressen erorderlich']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0" v-if="delegate.sent && !(sendingDelegate === delegate.code)" cols="12" md="3">
                      Gesendet am {{$moment(delegate.sent).format('DD.MM.YYYY, HH:mm')}} Uhr
                    </v-col>
                    <v-col class="py-0" v-else-if="delegate.error && !(sendingDelegate === delegate.code)" cols="12" md="3">
                      {{delegate.error}} <v-btn dense small text class="px-1 primary--text" @click="sendDelegate(delegate)">WIEDERHOLEN</v-btn>
                    </v-col>
                    <v-col class="text-right" v-else cols="12" md="3">
                      <v-btn
                        block
                        text
                        color="secondary"
                        @click="sendDelegate(delegate)"
                        class="mb-1"
                        :disabled="sendingDelegate || !formValid[i]"
                        :loading="sendingDelegate && sendingDelegate === delegate.code"
                      >
                        <v-icon small>fa fa-paper-plane</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="py-4" :key="i + '_divider'" v-if="!$vuetify.breakpoint.mdAndUp && i < delegates.length -1">
                    </v-col>
                  </v-row>
                </v-form>
              </template>
            </template>
          </v-card-text>
        </template>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Delegates',
  data: () => ({
    voting: undefined,
    boardCode: undefined,
    voter: undefined,
    error: false,
    delegates: [],
    formValid: [],
    sendingDelegate: undefined
  }),
  mounted () {
    this.adaptParams()
  },
  methods: {
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('votings', {
      getVoting: 'get'
    }),
    ...mapActions('voters', {
      getVoter: 'get'
    }),
    ...mapActions('votes', {
      patchVotes: 'patch'
    }),
    async sendDelegate (delegate) {
      this.sendingDelegate = delegate.code
      const map = {
        name: delegate.name,
        email: delegate.email,
        code: delegate.code
      }
      try {
        await this.patchVotes([this.voting._id, { type: 'delegates', organisation: this.voter._id, delegate: map }, {}])
      } catch (e) {
        console.log(e)
      }
      const result = await this.getVoting([this.$route.params.voting, { boardCode: this.$route.params.boardCode }])
      this.voting = undefined
      this.$nextTick(() => {
        this.voting = result
      })
      this.sendingDelegate = undefined
    },
    async adaptParams () {
      if (this.$route.params.voting && this.$route.params.boardCode) {
        this.boardCode = this.$route.params.boardCode
        try {
          this.voting = await this.getVoting([this.$route.params.voting, { boardCode: this.$route.params.boardCode }])
        } catch (e) {
          console.log(e)
          this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
        }
        const index = this.voting.codes.findIndex(obj => obj.boardCode.code === this.boardCode)
        if (index === undefined || index === -1) {
          this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
          return
        }
        if (this.voting.type === 'members') {
          try {
            this.voter = await this.getVoter(this.voting.codes[index].organisation)
          } catch (e) {
            this.error = 'Die Organisation konnte nicht ermittelt werden.'
            return
          }
        } else if (this.voting.type === 'custom') {
          this.voter = {
            name: this.voting.custom.name,
            email: this.voting.custom.email,
            votes: this.voting.custom.votes
          }
        }
        for (const delegateCode of this.voting.codes[index].delegateCodes) {
          const delegate = this.delegates.find(obj => obj.code === delegateCode.code)
          if (delegate) {
            if (delegateCode.name) {
              delegate.name = delegateCode.name
            }
            if (delegateCode.email) {
              delegate.email = delegateCode.email
            }
            if (delegateCode.sent) {
              delegate.sent = delegateCode.sent
            }
            if (delegateCode.error) {
              delegate.error = delegateCode.error
            }
          } else {
            this.delegates.push({
              name: delegateCode.name || '',
              email: delegateCode.email || '',
              sent: delegateCode.sent,
              error: delegateCode.error,
              code: delegateCode.code
            })
          }
        }
        for (let i = 0; i <= this.delegates.length; i++) {
          this.formValid.push(false)
        }
      } else {
        this.error = 'Die Abstimmung konnte nicht gefunden werden oder der Code ist ungültig.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ])
  },
  watch: {
    voting () {
      this.adaptParams()
    }
  }
}
</script>
