<template>
  <v-toolbar
    flat
    :color="color ? color: '#f5f7fa'"
    class="flexible-toolbar"
  >
    <v-icon
      color="primary"
      class="pr-4"
      v-if="showBackIcon"
      @click="$router.push(backUrl)"
    >
      fas fa-chevron-left
    </v-icon>
    <v-col>
      <v-row no-gutters>
        <span
          class="text-uppercase section-title primary--text align-self-center"
        >
          {{title}}
        </span>
        <span
          class="px-2 section-title info--text "
          v-if="showTitleSuffix"
        >
        -
        </span>
        <span
          class="section-title info--text align-self-center"
          v-if="showTitleSuffix"
        >
          {{titleSuffix}}
        </span>
      </v-row>
      <v-row
        class="section-sub-title-secondary secondaryAccent--text align-self-bottom"
        style="white-space: normal;"
        v-if="showSubTitle"
        no-gutters
      >
        <v-col class="mt-3">
          {{subTitle}}
        </v-col>
      </v-row>
    </v-col>
    <v-spacer
      v-if="showButton"
    />
    <v-btn
      text
      color="secondary"
      v-if="showButton"
    >
      {{buttonLabel}}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TitleToolbar',
  props: [
    'color',
    'title',
    'titleSuffix',
    'subTitle',
    'buttonLabel',
    'backUrl'
  ],
  computed: {
    showTitleSuffix () {
      return this.titleSuffix !== '' && this.titleSuffix
    },
    showSubTitle () {
      return this.subTitle !== '' && this.subTitle
    },
    showButton () {
      return this.buttonLabel !== '' && this.buttonLabel
    },
    showBackIcon () {
      return this.backUrl !== '' && this.backUrl
    }
  }
}
</script>
