var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pa-6",attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-row',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"pa-3 no-overflow",class:hover ? 'elevation-3' : '',attrs:{"outlined":"","tile":""}},[(_vm.organisation.pic)?_c('v-img',{staticClass:"event-box-image clickable",class:hover || _vm.organisation.type !== 'institution' ? '' : 'event-box-image-grayscaled',attrs:{"src":_vm.computedServerUri + '/pics/' + _vm.organisation._id + '/logo/' + _vm.organisation.pic,"contain":""},on:{"click":function($event){return _vm.showOrganisation(_vm.organisation)}}}):_vm._e(),(_vm.organisation.type === 'institution')?_c('v-img',{staticClass:"event-box-logo",class:hover ? 'event-box-logo-hover' : '',attrs:{"src":"/img/label.svg","contain":"","width":"20%","aspectRatio":"1"}}):_vm._e()],1)]}}])})],1),_c('v-row',[_c('v-col',{staticClass:"px-0 section-sub-title primary--text text-uppercase"},[_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showOrganisation(_vm.organisation)}}},[_vm._v(" "+_vm._s(_vm.organisation.name)+" ")]),(
            !_vm.$route.params.id &&
            _vm.ac &&
            _vm.user &&
            (
              _vm.ac.can(_vm.user.role).updateAny('organisation').granted ||
              (
                _vm.ac.can(_vm.user.role).updateOwn('organisation').granted &&
                _vm.user.organisation === _vm.organisation._id
              )
            )
          )?_c('v-btn',{staticClass:"mb-1",attrs:{"color":"secondary","icon":""},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/editor/' + _vm.organisation._id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-edit")])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }