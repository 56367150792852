<template>
  <div
    v-if="event"
  >
    <v-form ref="bookingForm" v-model="formValid">
      <v-card flat tile>
        <v-card-text>
          <v-row class="mb-5">
            <v-col cols="12" class="primary--text section-title">
              Buchung für "{{event.title}}"
            </v-col>
          </v-row>
          <v-row
            v-if="event.seats !== undefined && event.seats !== null"
          >
            <v-col>
              <v-alert
                dark
                :color="event.seats - event.bookings > 0 ? 'success' : 'warning'"
                v-if="event.bookings + participants.length > 0"
              >
                Es sind aktuell noch {{event.seats - event.bookings > 0 ? event.seats - event.bookings : 0}} Plätze verfügbar.
              </v-alert>
              <v-alert
                color="info"
                icon="fas fa-info-circle"
                v-if="event.bookings + participants.length > 0"
              >
                Es können auch noch Plätze gebucht werden, wenn keine mehr verfügbar sind. Dies sind dann automatisch Wartelistenplätze. Sollten Plätze frei werden, melden wir uns über die angegebenen Kontaktmöglichkeiten.
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="12" class="primary--text section-sub-title">
              Anmeldende Person / Kontakt
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="firstName"
                outlined
                dense
                label="Vorname"
                :rules="[rules.required, rules.longText]"
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="lastName"
                outlined
                dense
                label="Nachname"
                :rules="[rules.required, rules.longText]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Email"
                v-model="email"
                :rules="[rules.required, rules.email]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Telefonische Erreichbarkeit (auch im Notfall während der Veranstaltung)"
                v-model="phone"
                :rules="[rules.required]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                label="Straße, Hausnummer"
                v-model="street"
                :rules="[rules.required]"
                color="secondary"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="postalCode"
                outlined
                dense
                label="PLZ"
                :rules="[rules.required]"
                color="secondary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="city"
                outlined
                dense
                label="Ort"
                :rules="[rules.required]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert
                color="blueGrey"
              >
              <v-checkbox
                color="secondary"
                :rules="[rules.required]"
              >
                <template v-slot:label>
                  <div>
                    Ich habe die
                    <v-btn
                      text
                      small
                      color="secondary"
                      class="px-1 pt-1"
                      @click.stop="showBookingInformation = true"
                    >
                      Anmeldebedingungen
                    </v-btn>
                    gelesen und akzeptiere diese.
                  </div>
                </template>
              </v-checkbox>
            </v-alert>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col cols="12" class="primary--text section-sub-title">
              Teilnehmer*innen
            </v-col>
          </v-row>
          <template
            v-for="(participant, i) in participants"
          >
            <v-row :key="i + 'delete'" v-if="participants.length > 1">
              <v-col class="text-right">
                <v-btn
                  right
                  icon
                  color="secondary"
                  @click="participants.splice(i, 1)"
                >
                  <v-icon>fa fa-times</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row :key="i + 'name'">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="participant.firstName"
                  outlined
                  dense
                  label="Vorname"
                  :rules="[rules.required, rules.longText]"
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="participant.lastName"
                  outlined
                  dense
                  label="Nachname"
                  :rules="[rules.required, rules.longText]"
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row :key="i + 'birthday'">
              <v-col cols="12">
                <birthday-picker
                  v-model="participant.birthday"
                ></birthday-picker>
              </v-col>
            </v-row>
            <v-row :key="i + 'note'">
              <v-col>
                <v-textarea
                  outlined
                  dense
                  label="Anmerkungen (optional)"
                  v-model="participant.note"
                  color="secondary"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="event.discounts" :key="i + 'discount'">
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="participant.discount"
                  label="Ermäßigung"
                  :items="[{ text: 'Keine Ermäßigung', value: null}].concat(event.discounts)"
                  color="secondary"
                  item-color="secondary"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
            <div class="divider" :key="i+'divider'"></div>
          </template>
          <v-row>
            <v-col class="my-8" align-self="center" cols="12">
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  tile
                  depressed
                  :disabled="
                    participants.find(obj => !obj.firstName || !obj.lastName || !obj.birthday)
                  "
                  :color="hover ? 'secondary' : 'info'"
                  class="white--text"
                  @click="participants.push({})"
                >
                  Weitere Teilnehmer*in
                  <v-icon
                    class="ml-3"
                    small
                  >
                    fas fa-plus
                  </v-icon>
                </v-btn>
              </v-hover>
              <span
                class="warning--text ml-3"
                v-if="
                  event.seats !== undefined &&
                  event.seats !== null &&
                  event.bookings + participants.length >= event.seats
                "
              >
                Keine weiteren Plätze verfügbar - die nächsten Plätze sind Wartelistenplätze.
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-hover
            v-slot:default="{ hover }"
          >
            <v-btn
              tile
              depressed
              :color="hover ? 'warning' : 'info'"
              class="white--text"
              :disabled="processingSave"
              @click="$router.go(-1)"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!formValid || processingSave"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="saveBooking()"
              :loading="processingSave"
            >
              Verbindlich absenden
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog
      tile
      persistent
      max-width="800"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showConfirmation"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="mt-6 primary--text section-title" cols="12">
              Vielen Dank für deine Buchung!
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="body-1">
              Dein Buchungscode lautet <span class="font-weight-bold">{{code}}</span> - bitte gib diesen stets bei der Zahlung an!
            </v-col>
            <v-col cols="12" class="body-1">
              Wir haben eine Bestätigung an die angegebene Email versendet. Bitte prüfe im Zweifelsfall deinen Spam-Ordner. Darin findest du nochmals alle wichtigen Informationen, sowie die Zahlungsmöglichkeit.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-6 primary--text section-sub-title">
              Bestätigte Anmeldungen
            </v-col>
          </v-row>
          <template
            v-if="bookings.filter(p => !p.isWaiting).length > 0"
          >
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Teilnehmer*in
                          </th>
                          <th class="text-left">
                            Ermäßigt
                          </th>
                          <th class="text-left">
                            Preis
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(booking, i) in bookings.filter(p => !p.isWaiting)"
                          :key="i"
                        >
                          <td>{{ booking.participant.firstName }} {{ booking.participant.lastName }}</td>
                          <td>
                            <v-icon
                              size="16"
                            >
                              {{ booking.participant.discount?.text || 'fas fa-minus' }}
                            </v-icon>
                          </td>
                          <td>{{ booking.participant.discount?.value || event.price }} €</td>
                        </tr>
                        <tr
                          class="font-weight-bold"
                        >
                          <td>Gesamt</td>
                          <td></td>
                          <td>{{bookings.filter(p => !p.isWaiting).reduce((prev, current) => prev + (current.participant.discount?.value || event.price), 0)}} €</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </template>
          <template v-else>
            -
          </template>
          <template
            v-if="bookings.filter(p => p.isWaiting).length > 0"
          >
            <v-row>
              <v-col class="mt-6 primary--text section-sub-title">
                Auf der Warteliste
              </v-col>
            </v-row>
            <v-row
              v-for="(booking, i) of bookings.filter(p => p.isWaiting)"
              :key="i"
            >
              <v-col>
                - {{booking.participant.firstName}} {{booking.participant.lastName}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert
                  color="error"
                  dark
                  icon="fas fa-exclamation-triangle"
                >
                  Bitte die Gebühren für Wartelistenplätze noch NICHT überweisen! Überweisungen nur für bestätigte Teilnehmerplätze vornehmen.
                </v-alert>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col class="mt-6 primary--text section-sub-title">
              Zahlungsmöglichkeit
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col class="body-1">
              Bitte begleiche den Betrag für bestätigte Anmeldungen (nicht für Wartelistenplätze) mit Hilfe der nachfolgenden Zahlungsmöglichkeit:
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert
                tile
                outlined
                color="primary"
                v-html="$sanitize(organisation.paymentInformation)"
              ></v-alert>
            </v-col>
          </v-row>
          <template
            v-if="event.bookingInformation"
          >
            <v-row>
              <v-col class="mt-6 primary--text section-sub-title">
                Spezifische Veranstaltungsinformationen
              </v-col>
          </v-row>
          <v-row>
            <v-col
              v-html="$sanitize(event.bookingInformation)"
            >
            </v-col>
          </v-row>
          </template>
          <template
            v-if="organisation.bookingInformation"
          >
            <v-row>
              <v-col class="mt-6 primary--text section-sub-title">
                Allgemeine Informationen
              </v-col>
          </v-row>
          <v-row>
            <v-col
              v-html="$sanitize(organisation.bookingInformation)"
            >
            </v-col>
          </v-row>
          </template>
          <template
            v-if="event.bookingAttachments && event.bookingAttachments.length > 0"
          >
            <v-row>
              <v-col class="mt-6 primary--text section-sub-title">
                Dateianhänge
              </v-col>
          </v-row>
          <v-row
            v-for="(attachment, i) of event.bookingAttachments"
            :key="i"
          >
            <v-col>
              - <a target="_blank" :href="computedS3Url + attachment.value">{{attachment.text}}</a>
            </v-col>
          </v-row>
          </template>
          <v-row>
            <v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    tile
                    depressed
                    :color="hover ? 'secondary' : 'info'"
                    class="white--text"
                    @click="$router.back()"
                    :loading="processingSave"
                  >
                    Zurück zur Veranstaltung
                  </v-btn>
                </v-hover>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      tile
      max-width="800"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showBookingInformation"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="mt-6 primary--text section-title" cols="12">
              Anmeldebedingungen
            </v-col>
          </v-row>
          <template
            v-if="organisation.bookingInformation"
          >
          <v-row>
            <v-col
              v-html="$sanitize(organisation.bookingInformation)"
            >
            </v-col>
          </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BirthdayPicker from '../components/BirthdayPicker.vue'
export default {
  components: { BirthdayPicker },
  name: 'BookingEditor',
  data () {
    return {
      showBookingInformation: false,
      processingSave: false,
      formValid: false,
      participants: [{}],
      event: undefined,
      organisation: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
      street: undefined,
      postalCode: undefined,
      city: undefined,
      showConfirmation: false,
      code: undefined,
      bookings: []
    }
  },
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('events', {
      requestEvent: 'get'
    }),
    ...mapActions('bookings', {
      createBooking: 'create'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    ...mapMutations({
      setShow: 'SET_SHOW'
    }),
    async adapt () {
      this.event = this.getEvent(this.$route.params.id)
      if (!this.event) {
        this.event = await this.requestEvent(this.$route.params.id)
      }
      this.organisation = this.getOrganisation(this.event.organisation)
    },
    async saveBooking () {
      this.processingSave = true
      const tmpBookings = this.participants.map(participant => {
        const tmpParticipant = {
          organisation: this.organisation._id,
          event: this.event._id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          address: {
            street: this.street,
            postalCode: this.postalCode,
            city: this.city
          },
          participant: {
            firstName: participant.firstName,
            lastName: participant.lastName,
            birthday: participant.birthday,
            note: participant.note
          }
        }
        if (participant.discount && participant.discount.value !== null && participant.discount.value !== undefined) {
          tmpParticipant.participant.discount = participant.discount
        }
        return tmpParticipant
      })
      try {
        const result = await this.createBooking([tmpBookings, {}])
        if (Array.isArray(result)) {
          this.code = result[0].code
          this.bookings = result
        } else {
          this.code = result.code
          this.bookings = [result]
        }
        this.showConfirmation = true
      } catch (e) {
        this.createLog({ type: 'error', text: 'Create booking: ' + e })
        this.processingSave = false
        this.showSnackbar({ mode: 'save', success: false })
        console.log(e)
        return
      }
      this.processingSave = false
      this.showSnackbar({ mode: 'save', success: true })
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('organisations', {
      getOrganisation: 'get'
    }),
    ...mapGetters('events', {
      getEvent: 'get'
    }),
    computedS3Url () {
      return process.env.VUE_APP_S3_URL
    }
  },
  watch: {
    '$route.params.id' () {
      this.adapt()
    },
    participants: {
      handler () {
        if (this.$refs.bookingForm) {
          this.$nextTick(() => {
            this.$refs.bookingForm.validate()
          })
        }
      },
      deep: true
    }
  }
}
</script>
