<template>
  <div class="droparea-container mb-4">
    <v-container>
      <v-row>
        <v-col
          class="my-6 d-flex justify-end"
        >
          <v-btn
            icon
            fixed
            large
            @click="close()"
          >
            <v-icon>
              fas fa-times
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet
            width="100%"
            outlined
            class="droparea py-3"
            :class="files.length < maxFiles ? 'clickable' : ''"
            @dragover.prevent
            @drop.prevent="addFile($event, 'drop')"
            @dragenter.prevent="isInsideDroparea = true"
            @dragleave.prevent="isInsideDroparea = false"
            :color="isInsideDroparea ? (files.length < maxFiles ? 'customGreyLight' : 'error'): bgColor"
            @click.self="$refs.hiddenInput.click()"
          >
          <input
            multiple
            :disabled="files.length >= maxFiles"
            type="file"
            ref="hiddenInput"
            style="display:none"
            @change="addFile($event, 'click')"
          />
            <v-row
              dense
              class="pt-4"
              v-if="files.length < maxFiles"
            >
              <v-col
                cols=12
                class="text-center title font-weight-bold"
              >
                Klicken oder Datei(en) hier ablegen
              </v-col>
              <v-col
                cols=12
                class="text-center title"
              >
                {{maxFiles}} x {{maxFileSize}} MB
              </v-col>
              <v-col
                cols=12
                class="text-center"
              >
                {{acceptedMimeTypes.join(', ')}}
              </v-col>
            </v-row>
            <v-container
              fluid
            >
              <v-form
                ref="fileUploadForm"
                v-model="isValid"
              >
                <v-row>
                  <v-col
                    v-for="(file, i) in files"
                    :key="i"
                    cols="12"
                    sm="4"
                    style="cursor: auto !important"

                  >
                    <v-card
                      style="cursor: auto !important"
                      color="grey-lighten-2"
                    >
                      <v-img
                        cover
                        height="180px"
                        class="text-center align-center"
                        :src="file.type.startsWith('image') ? (file.state === 'uploaded' ? computedS3Url + file.value : file.uri) : ''"
                        :gradient="file.errors.length > 0 ? 'to top, red, transparent' : ''"
                      >
                        <v-sheet
                          height="100%"
                        >
                          <v-icon
                            v-if="!file.type.startsWith('image')"
                            size="180"
                          >
                            mdi-file
                          </v-icon>
                        </v-sheet>
                        <v-progress-circular
                          style="position:absolute; left: 50%; top: 50%; transform:translate(-50%,-50%)"
                          v-if="file.uploading"
                          :model-value="file.progress"
                          :width="10"
                          :size="60"
                          color="white"
                        ></v-progress-circular>
                        <v-tooltip
                          v-else-if="file.state"
                          bottom
                        >
                          <template v-slot:activator="{ props }">
                            <v-icon
                              style="position:absolute; left: 50%; top: 50%; transform:translate(-50%,-50%)"
                              class="pointer"
                              v-bind="props"
                              color="white"
                              size="50"
                            >
                              {{fileStates[file.state].icon}}
                            </v-icon>
                          </template>
                          <div>{{fileStates[file.state].tooltip}}{{file.errors && file.errors.length > 0 ? ':' : ''}}</div>
                          <div
                            v-for="error in file.errors"
                            :key="error"
                          >
                            {{error}}
                          </div>
                        </v-tooltip>
                      </v-img>
                      <v-card-text>
                        <v-row>
                          <v-col
                            class="text-center"
                          >
                            {{file.type}}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="text-center"
                          >
                            {{file.size / 1000}} kb
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Angezeigter Dateiname"
                              v-model="file.text"
                              :rules="[rules.required, rules.shortText]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Interner Dateiname"
                              v-model="file.value"
                              :rules="[rules.required, rules.noSpecials]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn
                              block
                              variant="tonal"
                              @click="files.splice(i, 1)"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                              REMOVE
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-card
                      v-if="files.length > 0 || isLoading"
                      class="fill-height"
                      :flat="!isLoading"
                      :color="bgColor"
                      @click.self="$refs.hiddenInput.click()"
                    >
                      <v-img
                        min-height="200"
                        height="100%"
                        class="text-center align-center"
                      >
                        <v-progress-circular
                          v-if="isLoading"
                          indeterminate
                          :width="5"
                          :size="60"
                          color="customGrey"
                        ></v-progress-circular>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            variant="tonal"
            v-if="isUploaded"
            block
            @click="close()"
          >
            Schließen
          </v-btn>
          <v-btn
            v-else
            variant="tonal"
            :disabled="files.length === 0 || !isValid"
            block
            @click="uploadFiles()"
          >
            Upload
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'FileUploadComponent',

  props: {
    maxFiles: {
      type: Number,
      default: 100
    },
    maxFileSize: {
      type: Number,
      default: 100
    },
    acceptedMimeTypes: {
      type: Array,
      default: () => ['*/*']
    },
    bgColor: {
      type: String,
      default: '#cccccc'
    }
  },

  components: {
  },

  data () {
    return {
      isValid: false,
      isUploaded: false,
      FilesModel: undefined,
      isLoading: false,
      isInsideDroparea: false,
      files: [],
      isUploadingFile: false,
      fileStates: {
        uploaded: {
          icon: 'mdi-check-circle',
          tooltip: 'Uploaded'
        },
        pending: {
          icon: 'mdi-cloud-upload',
          tooltip: 'Pending'
        },
        error: {
          icon: 'mdi-alert',
          tooltip: 'Error'
        }
      }
    }
  },

  async created () {
    this.files = []
  },

  computed: {
    ...mapGetters([
      'rules'
    ]),
    computedS3Url () {
      return process.env.VUE_APP_S3_URL
    }
  },

  methods: {
    ...mapActions('uploads3', {
      createUpload: 'create',
      removeUpload: 'remove'
    }),
    close () {
      this.files = []
      this.isUploaded = false
      this.$emit('closeDialog')
    },
    fakeClick () {
      this.$refs.hiddenInput.click()
    },
    async uploadFiles () {
      for (const file of this.files.filter(file => file.state === 'pending')) {
        file.uploading = true
        let presignedUrlObject
        try {
          presignedUrlObject = await this.createUpload({ name: file.value })
        } catch (e) {
          file.errors = [`Could not create upload url: ${e.message}`]
          file.uploading = false
          file.progress = 0
          console.log('Could not create upload url', e)
          throw e
        }
        try {
          await axios.put(presignedUrlObject.url, file.file, {
            onUploadProgress: (p) => {
              file.progress = (p.loaded / p.total) * 100
            }
          })
        } catch (e) {
          file.errors = [`Could not upload file: ${e.message}`]
          file.uploading = false
          file.progress = 0
          console.log('Could not upload file', e)
          throw e
        }
        try {
          file.state = 'uploaded'
          delete file.uploading
          delete file.progress
          delete file.file
          delete file.uri
          // Emit object
          this.$emit('fileUploaded', file)
        } catch (e) {
          file.errors = [`Could not save file data: ${e.message}`]
          try {
            await this.removeUpload(file.value)
          } catch (e) {
            const errorMessage = `Could not rollback file upload ${e.message}`
            console.log(errorMessage)
            file.errors.push(errorMessage)
            throw e
          }
          throw e
        }
      }
      this.isUploaded = true
      this.close()
    },
    async addFile (e, type) {
      this.isInsideDroparea = false
      if (this.files.length >= this.maxFiles) return
      let files
      if (type === 'click') {
        files = this.$refs.hiddenInput.files
      } else if (type === 'drop') {
        files = e.dataTransfer.files
      }
      if (!files || files.length === 0) return
      this.isLoading = true
      const filesWithDataUrl = await this.readAsDataURL([...files])
      this.files = this.files.concat(filesWithDataUrl)
      this.$refs.hiddenInput.value = null
      this.isInsideDroparea = false
      this.isLoading = false
    },
    fileToDataURL (file) {
      const reader = new FileReader()
      return new Promise(function (resolve, reject) {
        reader.onload = function (event) {
          resolve(event.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
    readAsDataURL (files) {
      return Promise.all(files.map(async file => {
        const errors = []
        if (file.size / 1024 / 1024 > this.maxFileSize) {
          errors.push('fileTooBigError')
        }
        if (!this.acceptedMimeTypes.includes('*/*') && !this.acceptedMimeTypes.includes(file.type)) {
          errors.push('fileTypeNotAcceptedError')
        }
        return {
          value: file.name.split('.').splice(0, file.name.split('.').length - 1).join('.').replace(/ /g, '_') + '_' + Date.now() + '.' + file.name.split('.').splice(-1),
          state: errors.length === 0 ? 'pending' : 'error',
          type: file.type,
          size: file.size,
          errors,
          uri: file.type.startsWith('image') ? await this.fileToDataURL(file) : '',
          file
        }
      }))
    }
  },
  watch: {
    files: {
      handler () {
        this.$nextTick(() => {
          if (this.$refs.fileUploadForm) {
            this.$refs.fileUploadForm.validate()
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style>
  .droparea-container {
    border-radius: 3px;
  }
  .droparea * {pointer-events: none;}
  .droparea .v-card * {pointer-events: auto}
</style>
