<template>
  <div>
    <v-toolbar
      flat
      color="primary"
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="$router.push($route.path + '/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>Neue Veranstaltung</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="info"
        v-if="showEditor"
        @click="$closeEditor($route, $router)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      v-show="showEditor"
      tile
      color="primary"
      class="pa-5"
    >
        <v-card flat tile>
          <v-card-text>
            <v-form ref="eventForm" v-model="mainFormValid">
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  Veranstalter
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-autocomplete
                    v-model="organisation"
                    outlined
                    dense
                    label="Organisation"
                    :rules="[rules.required]"
                    color="secondary"
                    :items="$sortByProperty(organisations, 'name').filter(obj => obj.isActive)"
                    item-value="_id"
                    item-text="name"
                    :disabled="
                      !(
                        ac &&
                        user &&
                        ac.can(user.role).createAny('event').granted
                      )
                    "
                  >
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="my-5">
                <v-col>
                  <v-autocomplete
                    outlined
                    dense
                    :filter="$filterFullName"
                    label="Autor"
                    :items="$sortByProperty(users, 'firstName').filter(obj => obj.isActive)"
                    v-model="authorId"
                    item-value="_id"
                    color="secondary"
                    item-color="secondary"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac &&
                        user &&
                        ac.can(user.role).createAny('news').granted
                      )
                    "
                  >
                    <template slot="selection" slot-scope="data">
                        {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  Titel und Text der Veranstaltung
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-text-field
                    v-model="title"
                    outlined
                    dense
                    label="Veranstaltungs-Titel"
                    :rules="[rules.longText, rules.required]"
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-text-field
                    v-model="subTitle"
                    outlined
                    dense
                    label="Veranstaltungs-Untertitel (optional)"
                    :rules="[rules.longText]"
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mb-6 py-6">
                  <tiptap-vuetify
                    placeholder="Veranstaltung-Text"
                    v-model="info"
                    label="Text"
                    :card-props="{ tile: true, flat: true, outlined: true }"
                    :extensions="extensions"
                  >
                  </tiptap-vuetify>
                </v-col>
              </v-row>
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  Veranstaltungs-Bilder
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <Dropzone
                    :serverUri="computedServerUri + '/uploads/'"
                    uploadFilePath="temporary"
                    :isInUse="showEditor"
                    maxFiles=20
                    resizeWidth=1280
                    resizeHeight=720
                    maxFileSize=2
                    acceptedMimeTypes='image/png, image/jpeg'
                    addFileMessage='Bild hinzufügen'
                    :convertFileName="true"
                    :existingFiles="computedExistingFiles"
                    :filesUploadedCallback="fileUploaded"
                    :fileRemovedCallback="fileRemoved"
                  />
                </v-col>
              </v-row>
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  Veranstaltungs-Informationen
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <div class="mb-3 card-text">
                    Zielgruppen-Alter<span class="optional-field" />
                  </div>
                  <v-range-slider
                    v-model="ageRange"
                    :max="maxAge"
                    :min="minAge"
                    hide-details
                    class="align-center"
                    color="secondary"
                    track-color="info"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        v-model="ageRange[0]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 40px"
                        color="secondary"
                        :rules="[rules.minimumAge]"
                        :placeholder="0"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        v-model="ageRange[1]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 40px"
                        color="secondary"
                        :rules="[rules.maximumAge]"
                        :placeholder="99"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="mb-3 card-text">
                    Tags<span class="optional-field" />
                  </div>
                    <v-combobox
                      outlined
                      dense
                      v-model="tags"
                      :items="tags"
                      label="Tag hinzufügen"
                      multiple
                      chips
                      hide-details
                      color="secondary"
                      item-color="secondary"
                    ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <div class="mb-3 card-text">
                    Kapazität<span class="optional-field" />
                  </div>
                  <v-text-field
                    outlined dense
                    color="secondary"
                    type="number"
                    label="Anzahl der Plätze eingeben"
                    v-model="seats"
                    :rules="[rules.positive]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="mb-3 card-text">
                    Kosten<span class="optional-field" />
                  </div>
                  <v-row>
                    <v-col class="pb-0" cols="12">
                      <v-text-field
                        outlined
                        dense
                        color="secondary"
                        type="number"
                        label="Regulär in €"
                        v-model="price"
                        :rules="[rules.positive]"
                      ></v-text-field>
                    </v-col>
                    <v-col v-for="(discount, i) in discounts" :key="i" class="py-0" cols="12">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            outlined
                            dense
                            color="secondary"
                            label="Bezeichnung"
                            v-model="discounts[i].text"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-text-field
                            outlined
                            dense
                            color="secondary"
                            type="number"
                            label="Preis in €"
                            v-model="discounts[i].value"
                            :rules="[rules.positive, rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col class="text-right">
                          <v-btn
                            right
                            icon
                            color="secondary"
                            @click="discounts.splice(i, 1)"
                          >
                            <v-icon>fa fa-times</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="mb-4 pt-0" cols="12">
                      <v-hover v-slot:default="{ hover }">
                        <v-btn
                          tile
                          depressed
                          :disabled="discounts.find(obj => !obj.text || !obj.value)"
                          :color="hover ? 'secondary' : 'info'"
                          class="white--text"
                          @click="discounts.push({})"
                        >
                          Weitere Preiskategorie hinzufügen
                          <v-icon
                            class="ml-3"
                            small
                          >
                            fas fa-plus
                          </v-icon>
                        </v-btn>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-3 card-text">
                    Ort<span class="optional-field" />
                  </div>
                  <v-text-field
                    outlined
                    dense
                    color="secondary"
                    label="Name, Straße, Ort"
                    v-model="location"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <div class="mb-3 card-text">
                    Kontakt<span class="optional-field" />
                  </div>
                  <v-text-field
                    outlined
                    dense
                    color="secondary"
                    label="Name / Telefon / Email (Freitext)"
                    v-model="contact"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <div class="mb-3 card-text">
                    Kategorien<span class="optional-field" />
                  </div>
                  <v-autocomplete
                    outlined
                    dense
                    color="secondary"
                    item-color="secondary"
                    label="Kategorien auswählen"
                    multiple
                    :items="eventCategories"
                    v-model="categories"
                  >
                  </v-autocomplete>
                  <div class="mb-3 card-text">
                    Anmeldung<span class="optional-field" />
                  </div>
                  <v-text-field
                    outlined
                    dense
                    color="secondary"
                    label="Website / Telefon / Email (Freitext)"
                    v-model="registration"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="mb-3 card-text">
                    Buchung über die Webseite<span class="optional-field" />
                  </div>
                  <v-row
                    v-if="
                      organisation &&
                      getOrganisation(organisation) &&
                      getOrganisation(organisation).paymentInformation &&
                      price
                    "
                  >
                    <v-col cols="12" md="4">
                      <v-checkbox
                        hide-details
                        dense
                        color="secondary"
                        v-model="isBookable"
                        label="Direkt buchbar"
                      ></v-checkbox>
                    </v-col>
                    <template v-if="isBookable">
                      <v-col cols="12" md="4">
                        <date-time-picker
                          v-model="bookingStart"
                          label="Anmeldungsbeginn"
                          clearText="Zurücksetzen"
                          okText="Übernehmen"
                          dateFormat="dd.MM.yyyy,"
                          timeFormat="HH:mm"
                          color="error"
                          :datePickerProps="{ 'header-color': 'rgba(0, 0, 0, 0.54)' }"
                          :timePickerProps="{ 'header-color': 'rgba(0, 0, 0, 0.54)', 'format': '24hr' }"
                        ></date-time-picker>
                      </v-col>
                      <v-col cols="12" md="4">
                        <date-time-picker
                          v-model="bookingEnd"
                          label="Anmeldungsende"
                          clearText="Zurücksetzen"
                          okText="Übernehmen"
                          dateFormat="dd.MM.yyyy,"
                          timeFormat="HH:mm"
                          color="error"
                          :datePickerProps="{ 'header-color': 'rgba(0, 0, 0, 0.54)' }"
                          :timePickerProps="{ 'header-color': 'rgba(0, 0, 0, 0.54)', 'format': '24hr' }"
                        ></date-time-picker>
                      </v-col>
                    </template>
                  </v-row>
                  <div v-else>
                    Nur verfügbar, wenn in der Organisation Zahlungsinformationen hinterlegt sind und ein regulärer Veranstaltungspreis eingetragen ist (kann auch 0 sein).
                  </div>
                </v-col>

                <v-col v-if="isBookable" cols="12">
                  <div class="mb-3 card-text">
                    Zusatzinformationen für Buchungsbestätigung (z.B: Hinweis auf Dateien / Formulare)<span class="optional-field" />
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <tiptap-vuetify
                        placeholder="Text"
                        v-model="bookingInformation"
                        label="Text"
                        :card-props="{ tile: true, flat: true, outlined: true }"
                        :extensions="extensions"
                      >
                      </tiptap-vuetify>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col v-if="isBookable" cols="12">
                  <div class="mb-3 card-text">
                    Datei-Anhänge für Buchungsbestätigung<span class="optional-field" />
                  </div>
                  <v-row>
                    <v-col v-if="event && event.bookingAttachments && event.bookingAttachments.length > 0" cols="12">
                      <v-list>
                        <v-list-item
                          v-for="(file, i) in event.bookingAttachments"
                          :key="i"
                          @click="() => {}"
                          style="cursor: default"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{file.text}}</v-list-item-title>
                            <v-list-item-subtitle>{{file.value}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              color="secondary"
                              @click="deleteFile(file.value)"
                            >
                              <v-icon
                                small
                              >
                                fas fa-trash
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" class="mb-6">
                      <v-hover v-slot:default="{ hover }">
                        <v-btn
                          tile
                          depressed
                          @click="showUploadDialog = true"
                          :color="hover ? 'secondary' : 'info'"
                          class="white--text"
                        >
                          Datei hinzufügen
                        </v-btn>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <div class="mb-3 card-text">
                    Zusatzinformationen<span class="optional-field" />
                  </div>
                  <v-checkbox hide-details dense v-model="educational" color="secondary" label="Bildungsangebot"></v-checkbox>
                  <v-checkbox hide-details dense v-model="accessible" color="secondary" label="Barrierefrei"></v-checkbox>
                  <v-checkbox hide-details dense v-model="internal" color="secondary" label="Nur für angemeldete Mitglieder anzeigen"></v-checkbox>
                  <v-select
                    class="mt-3"
                    v-model="genderTarget"
                    label="Zielgruppe"
                    :rules="[rules.required]"
                    :items="[{ text: 'Für alle', value: 'a' }, { text: 'Für Mädels', value: 'f' }, { text: 'Für Jungs', value: 'm' }]"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- Further free -->
              <v-row class="my-5 mb-0">
                <v-col class="primary--text section-sub-title">
                  Individuelle Kurz-Informationen<span class="optional-field" />
                </v-col>
              </v-row>
              <v-row>
                <template
                  v-for="(customElement, i) in custom"
                >
                  <v-col :key="i+'desc'" align-self="center" class="py-2 body-1" cols="12" sm="6">
                    {{customElement.description}}
                  </v-col>
                  <v-col :key="i+'text'" align-self="center" class="py-2 body-1" cols="12" sm="5">
                    {{customElement.text}}
                  </v-col>
                  <v-col :key="i" class="py-2 text-center" cols="12" sm="1">
                    <v-btn
                      small
                      icon
                      color="secondary"
                      @click="removeCustomEntry(i)"
                    >
                      <v-icon
                        small
                      >
                        fas fa-trash
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <div class="divider" :key="i+'divider'"></div>
                </template>
              </v-row>
              <v-row>
                <v-col class="py-4 pb-0" cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Beschreibung"
                    v-model="customEntryDescription"
                    :rules="[rules.shortText]"
                    color="secondary"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-4 pb-0" cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Inhalt"
                    v-model="customEntryText"
                    :rules="[rules.shortText]"
                    color="secondary"
                  >
                  </v-text-field>
                </v-col>
                <div class="divider" :key="i+'divider'"></div>
                <v-col class="pt-5" align-self="center" cols="12">
                  <v-hover v-slot:default="{ hover }">
                    <v-btn
                      tile
                      depressed
                      :disabled="!customEntryDescription || !customEntryText"
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="addCustomEntry()"
                    >
                      Weitere
                      <v-icon
                        class="ml-3"
                        small
                      >
                        fas fa-plus
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
              <!-- Type -->
              <v-row class="my-10 mb-0">
                <v-col class="primary--text section-sub-title">
                  Art der Veranstaltung
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="isScheduled"
                    :rules="[rules.radio]"
                  >
                  <v-radio
                    color="secondary"
                    label="Einzelne Veranstaltung"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    color="secondary"
                    label="Wiederkehrende Veranstaltung"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
            <!-- Time -->
            <div v-show="isScheduled !== null">
              <v-row class="my-5">
                <v-col class="primary--text section-sub-title">
                  {{ isScheduled ? 'Dauer' : 'Datum'}} und Uhrzeit
                </v-col>
              </v-row>
              <!-- Date -->
              <v-form ref="timeFormUnScheduled" v-model="timeFormUnScheduledValid">
                <template v-if="!isScheduled">
                  <v-row class="">
                    <v-col class="py-1 card-text">
                      Veranstaltungsdatum
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-dialog
                        ref="startDateDialog"
                        v-model="showStartDateDialog"
                        :return-value.sync="startDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="startDatePicker"
                            outlined
                            dense
                            color="secondary"
                            :value="computedStartDate"
                            :label="'Datum Veranstaltungsbeginn auswählen'"
                            append-icon="fas fa-calendar-day"
                            readonly
                            v-on="on"
                            :rules="[
                              () => {
                                if (
                                  !isScheduled &&
                                  startDate &&
                                  endDate &&
                                  $moment(startDate).isAfter($moment(endDate))
                                ) {
                                  return 'Beginn liegt nach Ende.'
                                } else {
                                  return true
                                }
                              },
                              rules.required
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-if="showStartDateDialog"
                          v-model="startDate"
                          full-width
                          format="24hr"
                          color="secondary"
                          first-day-of-week="1"
                        >
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="showStartDateDialog = false"
                            >
                              Abbrechen
                            </v-btn>
                          </v-hover>
                          <v-spacer></v-spacer>
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :disabled="!startDate"
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="$refs.startDateDialog.save(startDate)"
                            >
                              OK
                            </v-btn>
                          </v-hover>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-dialog
                        ref="endDateDialog"
                        v-model="showEndDateDialog"
                        :return-value.sync="endDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="endDatePicker"
                            outlined
                            dense
                            color="secondary"
                            :value="computedEndDate"
                            label="Datum Veranstaltungsende auswählen"
                            append-icon="fas fa-calendar-day"
                            readonly
                            v-on="on"
                            :rules="[
                              () => {
                                if (
                                  !isScheduled &&
                                  startDate &&
                                  endDate &&
                                  $moment(startDate).isAfter($moment(endDate))
                                ) {
                                  return 'Ende liegt vor Beginn.'
                                } else {
                                  return true
                                }
                              },
                              rules.required
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-if="showEndDateDialog"
                          v-model="endDate"
                          full-width
                          format="24hr"
                          :min="startDate"
                          color="secondary"
                          first-day-of-week="1"
                        >
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="showEndDateDialog = false"
                            >
                              Abbrechen
                            </v-btn>
                          </v-hover>
                          <v-spacer></v-spacer>
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :disabled="!endDate"
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="$refs.endDateDialog.save(endDate)"
                            >
                              OK
                            </v-btn>
                          </v-hover>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-form>
              <v-form ref="timeFormScheduled1" v-model="timeFormScheduledValid1">
                <template v-if="isScheduled">
                  <v-row class="">
                    <v-col class="py-1 card-text">
                      Veranstaltungsdauer (Ende nach 0 Uhr -> zwei Tage)
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        label="Dauer in Tagen auswählen"
                        clearable
                        :items="new Array(21).fill().map((e,i) => {
                          return i + 1
                        })"
                        v-model="duration"
                        v-on:change="validateDatePicker"
                        :rules="[
                          (e) => {
                            if (
                              startTime &&
                              endTime &&
                              (duration < 2) &&
                              $moment($moment().format('YYYY-MM-DD') + ' ' + startTime).isAfter($moment($moment().format('YYYY-MM-DD') + ' ' + endTime))
                            ) {
                              return 'Dauer muss mindestens zwei Tage betragen.'
                            } else {
                              return true
                            }
                          },
                          rules.required
                        ]"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </template>
              </v-form>
              <v-row class="">
                <v-col class="py-1 card-text">
                  Veranstaltungsuhrzeit
                </v-col>
              </v-row>
              <v-form ref="timeForm" v-model="timeFormValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-dialog
                      ref="startTimeDialog"
                      v-model="showStartTimeDialog"
                      :return-value.sync="startTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="startTimePicker"
                          outlined
                          dense
                          color="secondary"
                          v-model="startTime"
                          label="Uhrzeit Veranstaltungsbeginn auswählen"
                          append-icon="fas fa-clock"
                          readonly
                          v-on="on"
                          :rules="[
                            rules.required,
                            (e) => {
                              if (
                                !isScheduled &&
                                startTime &&
                                startDate &&
                                endTime &&
                                endDate &&
                                $moment(startDate + ' ' + startTime).isAfter($moment(endDate + ' ' + endTime))
                              ) {
                                return 'Beginn liegt nach Ende.'
                              } else {
                                return true
                              }
                            },
                            (e) => {
                              if (
                                isScheduled &&
                                startTime &&
                                endTime &&
                                (duration < 2) &&
                                $moment($moment().format('YYYY-MM-DD') + ' ' + startTime).isAfter($moment($moment().format('YYYY-MM-DD') + ' ' + endTime))
                              ) {
                                return 'Beginn liegt nach Ende oder Dauer muss mindestens zwei Tage betragen.'
                              } else {
                                return true
                              }
                            }
                          ]"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="showStartTimeDialog"
                        v-model="startTime"
                        full-width
                        format="24hr"
                        color="secondary"
                      >
                        <v-hover
                          v-slot:default="{ hover }"
                        >
                          <v-btn
                            tile
                            depressed
                            :color="hover ? 'secondary' : 'info'"
                            class="white--text"
                            @click="showStartTimeDialog = false"
                          >
                            Abbrechen
                          </v-btn>
                        </v-hover>
                        <v-spacer></v-spacer>
                        <v-hover
                          v-slot:default="{ hover }"
                        >
                          <v-btn
                            tile
                            depressed
                            :disabled="!startTime"
                            :color="hover ? 'secondary' : 'info'"
                            class="white--text"
                            @click="$refs.startTimeDialog.save(startTime)"
                          >
                            OK
                          </v-btn>
                        </v-hover>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-dialog
                      ref="endTimeDialog"
                      v-model="showEndTimeDialog"
                      :return-value.sync="endTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          ref="endTimePicker"
                          outlined
                          dense
                          color="secondary"
                          v-model="endTime"
                          label="Uhrzeit Veranstaltungsende auswählen"
                          append-icon="fas fa-clock"
                          readonly
                          v-on="on"
                          :rules="[
                            rules.required,
                            (e) => {
                              if (
                                !isScheduled &&
                                startTime &&
                                startDate &&
                                endTime &&
                                endDate &&
                                $moment(startDate + ' ' + startTime).isAfter($moment(endDate + ' ' + endTime))
                              ) {
                                return 'Ende liegt vor Beginn.'
                              } else {
                                return true
                              }
                            },
                            (e) => {
                              if (
                                isScheduled &&
                                startTime &&
                                endTime &&
                                (duration < 2) &&
                                $moment($moment().format('YYYY-MM-DD') + ' ' + startTime).isAfter($moment($moment().format('YYYY-MM-DD') + ' ' + endTime))
                              ) {
                                return 'Ende liegt vor Beginn oder Dauer muss mindestens zwei Tage betragen.'
                              } else {
                                return true
                              }
                            }
                          ]"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="showEndTimeDialog"
                        v-model="endTime"
                        full-width
                        format="24hr"
                        color="secondary"
                      >
                        <v-hover
                          v-slot:default="{ hover }"
                        >
                          <v-btn
                            tile
                            depressed
                            :color="hover ? 'secondary' : 'info'"
                            class="white--text"
                            @click="showEndTimeDialog = false"
                          >
                            Abbrechen
                          </v-btn>
                        </v-hover>
                        <v-spacer></v-spacer>
                        <v-hover
                          v-slot:default="{ hover }"
                        >
                          <v-btn
                            tile
                            depressed
                            :disabled="!endTime"
                            :color="hover ? 'secondary' : 'info'"
                            class="white--text"
                            @click="$refs.endTimeDialog.save(endTime)"
                          >
                            OK
                          </v-btn>
                        </v-hover>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-form>
              <!-- Scheduling -->
              <v-form ref="timeFormScheduled2" v-model="timeFormScheduledValid2">
                <template v-if="isScheduled">
                  <v-row class="my-5">
                    <v-col class="primary--text section-sub-title">
                      Regeln für die Wiederholungen
                    </v-col>
                  </v-row>
                  <!-- Interval range -->
                  <v-row class="">
                    <v-col class="py-1 card-text">
                      Dauer der Veranstaltungs-Reihe
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-dialog
                        ref="startIntervalDialog"
                        v-model="showStartIntervalDialog"
                        :return-value.sync="startInterval"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="startIntervalPicker"
                            outlined
                            dense
                            color="secondary"
                            :value="computedStartInterval"
                            :label="'Beginn der Veranstaltungsreihe auswählen'"
                            append-icon="fas fa-calendar-day"
                            readonly
                            v-on="on"
                            :rules="[
                              () => {
                                if (
                                  startInterval &&
                                  endInterval &&
                                  $moment(startInterval).isAfter($moment(endInterval))
                                ) {
                                  return 'Beginn liegt nach Ende.'
                                } else {
                                  return true
                                }
                              },
                              rules.required
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-if="showStartIntervalDialog"
                          v-model="startInterval"
                          full-width
                          format="24hr"
                          color="secondary"
                          :min="$moment().format('YYYY-MM-DD')"
                          first-day-of-week="1"
                        >
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="showStartIntervalDialog = false"
                            >
                              Abbrechen
                            </v-btn>
                          </v-hover>
                          <v-spacer></v-spacer>
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :disabled="!startInterval"
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="$refs.startIntervalDialog.save(startInterval)"
                            >
                              OK
                            </v-btn>
                          </v-hover>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-dialog
                        ref="endIntervalDialog"
                        v-model="showEndIntervalDialog"
                        :return-value.sync="endInterval"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="endIntervalPicker"
                            outlined
                            dense
                            color="secondary"
                            :value="computedEndInterval"
                            label="Ende der Veranstaltungsreihe auswählen"
                            append-icon="fas fa-calendar-day"
                            readonly
                            v-on="on"
                            :rules="[
                              () => {
                                if (
                                  startInterval &&
                                  endInterval &&
                                  $moment(startInterval).isAfter($moment(endInterval))
                                ) {
                                  return 'Ende liegt vor Beginn.'
                                } else {
                                  return true
                                }
                              },
                              rules.required
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-if="showEndIntervalDialog"
                          v-model="endInterval"
                          full-width
                          format="24hr"
                          color="secondary"
                          :max="$moment().add(2, 'years').format('YYYY-MM-DD')"
                          first-day-of-week="1"
                        >
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="showEndIntervalDialog = false"
                            >
                              Abbrechen
                            </v-btn>
                          </v-hover>
                          <v-spacer></v-spacer>
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-btn
                              tile
                              depressed
                              :disabled="!endInterval"
                              :color="hover ? 'secondary' : 'info'"
                              class="white--text"
                              @click="$refs.endIntervalDialog.save(endInterval)"
                            >
                              OK
                            </v-btn>
                          </v-hover>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <!-- Schedule settings -->
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="mb-3 card-text">
                        Art der Wiederholung
                      </div>
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        item-color="secondary"
                        label="Interval auswählen"
                        v-model="freq"
                        :items="[
                          { value: 3, text: 'täglich' },
                          { value: 2, text: 'wöchentlich' },
                          { value: 1, text: 'monatlich' },
                          { value: 0, text: 'jährlich' }
                        ]"
                        :rules="[rules.required]"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-3 card-text">
                        Häufigkeit der Wiederholung
                      </div>
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        :items="[
                          { value: 1, text: 'Jedes mal' },
                          { value: 2, text: 'Jedes 2. mal' },
                          { value: 3, text: 'Jedes 3. mal' },
                          { value: 4, text: 'Jedes 4. mal' },
                          { value: 5, text: 'Jedes 5. mal' },
                          { value: 6, text: 'Jedes 6. mal' },
                          { value: 7, text: 'Jedes 7. mal' },
                          { value: 8, text: 'Jedes 8. mal' },
                          { value: 9, text: 'Jedes 9. mal' },
                          { value: 10, text: 'Jedes 10. mal' },
                          { value: 11, text: 'Jedes 11. mal' },
                          { value: 12, text: 'Jedes 12. mal' }
                        ]"
                        label="Findet jedes X. mal des Intervalls statt"
                        v-model="interval"
                        :rules="[rules.required]"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="my-5">
                    <v-col class="primary--text section-sub-title">
                      Zusätzliche Regeln
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="mb-3 card-text">
                        Nach Wochentag<span class="optional-field" />
                      </div>
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        item-color="secondary"
                        label="Immer am [Wochentag(e)]"
                        multiple
                        v-model="byweekday"
                        :items="[
                          { value: 0, text: 'Montags' },
                          { value: 1, text: 'Dienstags' },
                          { value: 2, text: 'Mittwochs' },
                          { value: 3, text: 'Donnerstags' },
                          { value: 4, text: 'Freitags' },
                          { value: 5, text: 'Samstags' },
                          { value: 6, text: 'Sonntags' },
                        ]"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-3 card-text">
                        Nach Monat<span class="optional-field" />
                      </div>
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        item-color="secondary"
                        label="Immer im [Monat(e)]"
                        multiple
                        v-model="bymonth"
                        :items="[
                          { value: 1, text: 'Januar' },
                          { value: 2, text: 'Februar' },
                          { value: 3, text: 'März' },
                          { value: 4, text: 'April' },
                          { value: 5, text: 'Mai' },
                          { value: 6, text: 'Juni' },
                          { value: 7, text: 'Juli' },
                          { value: 8, text: 'August' },
                          { value: 9, text: 'September' },
                          { value: 10, text: 'Oktober' },
                          { value: 11, text: 'November' },
                          { value: 12, text: 'Dezember' }
                        ]"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="mb-3 card-text">
                        Nach Monatstag<span class="optional-field" />
                      </div>
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        label="Immer am X. Tag des Monats"
                        clearable
                        :items="new Array(31).fill().map((e,i) => {
                          return {value: i + 1, text: 'Immer am ' + (i + 1) + '. Tag des Monats'}
                        })"
                        v-model="bymonthday"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-3 card-text">
                        Nach Jahrestag<span class="optional-field" />
                      </div>
                      <v-autocomplete
                        outlined
                        dense
                        color="secondary"
                        clearable
                        :items="new Array(365).fill().map((e,i) => {
                          return {value: i + 1, text: 'Immer am ' + (i + 1) + '. Tag des Jahres'}
                        })"
                        label="Immer am X. Tag des Jahres"
                        v-model="byyearday"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.text }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="translatedRule">
                    <v-col>
                      <div class="card-text mb-3">Kontrolle der Wiederholungen</div>
                      <v-alert
                        fixed
                        icon="fas fa-info-circle"
                        dark
                        color="primary"
                      >
                        {{translatedRule}}
                      </v-alert>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="
                  user &&
                  !internal &&
                  (
                    (event && (!organisation || !['institution', 'sjr', 'cooperation'].includes(getOrganisation(organisation).type))) ||
                    !['institution', 'sjr', 'cooperation'].includes(getOrganisation(user.organisation).type)
                    || (event && event.isAccepted === false)
                  )
                ">
                  <v-row class="my-5">
                    <v-col class="primary--text section-sub-title">
                      Freigabe
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-checkbox
                        dense
                        :disabled="
                          !ac.can(user.role).updateAny('event').granted
                        "
                        v-model="isAccepted"
                        label="Freigegeben"
                        color="secondary"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </template>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions
            class="px-4 pb-4"
          >
            <v-spacer></v-spacer>
            <v-hover
              v-if="event"
              v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showDeleteDialog = true"
              >
                Löschen
              </v-btn>
            </v-hover>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :disabled="!computedValidation || processingSave || info === '' || info === '<p></p>'"
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                :loading="processingSave"
                @click="saveEvent(false)"
              >
                Speichern
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
    </v-sheet>
    <v-dialog v-model="showDeleteDialog" max-width="350px">
      <v-card>
        <v-card-title>Veranstaltung löschen?</v-card-title>
        <v-card-actions>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showDeleteDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="processingDelete"
              :color="hover ? 'error' : 'info'"
              class="white--text"
              :loading="processingDelete"
              @click="deleteEvent()"
            >
              Löschen
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNewConfirmationDialog" max-width="350px">
      <v-card>
        <v-card-title>Event gespeichert</v-card-title>
        <v-card-text>Du erhältst eine Email, sobald deine Veranstaltung von einem Admin freigegeben wurde.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              :loading="processingDelete"
              @click="showNewConfirmationDialog = false; $closeEditor($route, $router)"
            >
              Verstanden
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUpdateConfirmationDialog" max-width="350px">
      <v-card>
        <v-card-title>Event speichern?</v-card-title>
        <v-card-text>Ein Admin muss deine Änderungen erst freigeben. Bis dahin wird dein Event nicht öffentlich sichtbar sein.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="showUpdateConfirmationDialog = false"
            >
              Abbrechen
            </v-btn>
          </v-hover>
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'success' : 'info'"
              class="white--text"
              @click="() => { showUpdateConfirmationDialog = false; saveEvent(true) }"
            >
              Speichern
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showUploadDialog"
      persistent
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col>
              <file-upload-component
                :maxFileSize="10"
                :maxFiles="1"
                @closeDialog="showUploadDialog = false"
                @fileUploaded="addFile"
              ></file-upload-component>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClient from '@/feathers-client'
import { mapGetters, mapActions } from 'vuex'
import { RRule } from 'rrule'
import Dropzone from './Dropzone'
import { v4 as uuidv4 } from 'uuid'
import { TiptapVuetify, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem } from 'tiptap-vuetify'
import DateTimePicker from './DateTimePicker.vue'
import FileUploadComponent from './FileUploadComponent.vue'

export default {
  name: 'EventEditor',
  props: [
    'event',
    'showEditor'
  ],
  components: {
    Dropzone,
    TiptapVuetify,
    DateTimePicker,
    FileUploadComponent
  },
  data () {
    return {
      showUploadDialog: false,
      organisation: null,
      pics: [],
      picsToUpload: [],
      uploadService: undefined,
      mainFormValid: false,
      timeFormScheduledValid1: false,
      timeFormScheduledValid2: false,
      timeFormUnScheduledValid: false,
      timeFormValid: false,
      organistion: null,
      title: '',
      subTitle: undefined,
      info: '',
      ageRange: [0, 99],
      minAge: 0,
      maxAge: 99,
      isBookable: null,
      bookingStart: undefined,
      bookingEnd: undefined,
      categories: [],
      registration: null,
      tags: [],
      educational: null,
      genderTarget: 'a',
      accessible: null,
      contact: null,
      location: null,
      seats: null,
      price: null,
      discounts: [],
      isScheduled: false,
      startTime: null,
      showStartTimeDialog: false,
      endTime: null,
      showEndTimeDialog: false,
      startDate: null,
      showStartDateDialog: false,
      endDate: null,
      showEndDateDialog: false,
      startInterval: null,
      showStartIntervalDialog: false,
      endInterval: null,
      showEndIntervalDialog: false,
      freq: null,
      interval: null,
      byweekday: [],
      bymonth: [],
      bymonthday: null,
      byyearday: null,
      permanent: false,
      internal: false,
      translatedRule: '',
      weekdays: { MO: 0, TU: 1, WE: 2, TH: 3, FR: 4, SA: 5, SU: 6 },
      firstLoad: true,
      processingDelete: false,
      processingSave: false,
      showDeleteDialog: false,
      showNewConfirmationDialog: false,
      showUpdateConfirmationDialog: false,
      isAccepted: false,
      authorId: null,
      custom: [],
      customEntryDescription: null,
      customEntryText: null,
      bookingInformation: null,
      extensions: [
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList
      ]
    }
  },
  mounted () {
    this.adapt()
    this.uploadService = feathersClient.service('uploads')
  },
  methods: {
    ...mapActions('events', {
      createEvent: 'create',
      patchEvent: 'patch',
      removeEvent: 'remove'
    }),
    ...mapActions('translations', {
      createTranslation: 'create'
    }),
    ...mapActions('logger', {
      createLog: 'create'
    }),
    ...mapActions('uploads3', {
      removeFile: 'remove'
    }),
    ...mapActions([
      'showSnackbar'
    ]),
    async addFile (file) {
      try {
        const tmpAttachments = this.event?.bookingAttachments || []
        tmpAttachments.push(file)
        await this.patchEvent([
          this.event._id,
          {
            bookingAttachments: tmpAttachments
          },
          {}]
        )
        this.showUploadDialog = false
      } catch (e) {
        console.log(e)
        this.createLog({ type: 'error', text: 'Create booking attachment: ' + e })
        this.showSnackbar({ mode: 'save', success: false })
      }
    },
    async deleteFile (value) {
      try {
        await this.removeFile(value)
        const tmpAttachments = this.event.bookingAttachments.filter(attachment => attachment.value !== value)
        await this.patchEvent([
          this.event._id,
          {
            bookingAttachments: tmpAttachments
          },
          {}
        ])
        this.showSnackbar({ mode: 'upload', success: true })
      } catch (e) {
        console.log(e)
        this.createLog({ type: 'error', text: 'Delete booking attachment: ' + e })
        this.showSnackbar({ mode: 'delete', success: false })
      }
    },
    adapt () {
      if (this.event) {
        const pics = []
        JSON.parse(JSON.stringify(this.event.pics)).forEach((picName) => {
          pics.push({
            name: picName,
            id: uuidv4()
          })
        })
        if (this.event.custom) {
          this.custom = this.event.custom
        } else {
          this.custom = []
        }
        this.isBookable = this.event.isBookable
        this.bookingStart = this.event.bookingStart
        this.bookingEnd = this.event.bookingEnd
        this.organisation = this.event.organisation
        this.title = this.event.title
        this.subTitle = this.event.subTitle
        this.bookingInformation = this.event.bookingInformation
        this.info = this.event.info
        this.pics = pics
        this.startTime = this.$moment(this.event.time.start).format('HH:mm')
        this.endTime = this.$moment(this.event.time.end).format('HH:mm')
        if (this.event.schedule) {
          this.startInterval = this.$moment(this.event.interval.start).format('YYYY-MM-DD')
          this.endInterval = this.$moment(this.event.interval.end).format('YYYY-MM-DD')
          this.schedule = RRule.fromString(this.event.schedule)
          this.isScheduled = true
          this.freq = this.schedule.origOptions.freq
          this.interval = this.schedule.origOptions.interval
          if (this.schedule.origOptions.byweekday) {
            this.byweekday = this.schedule.origOptions.byweekday.map(obj => this.weekdays[obj])
          }
          this.bymonth = this.schedule.origOptions.bymonth
          this.bymonthday = this.schedule.origOptions.bymonthday
          this.byyearday = this.schedule.origOptions.byyearday
          this.duration = this.event.duration
        } else {
          this.isScheduled = false
          this.startDate = this.$moment(this.event.date.start).format('YYYY-MM-DD')
          this.endDate = this.$moment(this.event.date.end).format('YYYY-MM-DD')
        }
        this.ageRange = [this.event.age.from, this.event.age.until]
        this.categories = this.event.categories
        this.registration = this.event.registration
        this.accessible = this.event.accessible
        this.educational = this.event.educational
        this.genderTarget = this.event.genderTarget || 'a'
        this.location = this.event.location
        this.seats = this.event.seats
        this.price = this.event.price
        if (this.event.discounts) {
          this.discounts = this.event.discounts
        }
        this.contact = this.event.contact
        this.tags = this.event.tags
        this.internal = this.event.internal
        this.isAccepted = this.event.isAccepted
        if (this.event) {
          if (this.ac.can(this.user.role).createAny('event').granted) {
            this.authorId = this.event.author
          } else {
            this.authorId = this.user._id
          }
        }
      } else {
        this.$refs.eventForm.reset()
        this.$refs.timeFormScheduled1.reset()
        this.$refs.timeFormScheduled2.reset()
        this.$refs.timeFormUnScheduled.reset()
        this.$refs.timeForm.reset()
        if (this.user) {
          this.organisation = this.user.organisation
          this.authorId = this.user._id
        } else {
          this.organisation = null
        }
        this.pics = []
        this.categories = []
        this.registration = null
        this.tags = []
        this.startTime = null
        this.showStartTimeDialog = false
        this.endTime = null
        this.showEndTimeDialog = false
        this.startDate = null
        this.showStartDateDialog = false
        this.endDate = null
        this.showEndDateDialog = false
        this.startInterval = null
        this.showStartIntervalDialog = false
        this.endInterval = null
        this.showEndIntervalDialog = false
        this.freq = null
        this.interval = null
        this.byweekday = []
        this.bymonth = []
        this.bymonthday = null
        this.byyearday = null
        this.duration = null
        this.isAccepted = this.internal
        this.ageRange = [0, 99]
        this.$nextTick(() => {
          this.isScheduled = false
          this.genderTarget = 'a'
        })
      }
      this.picsToUpload = []
    },
    addCustomEntry () {
      this.custom.push(
        {
          description: this.customEntryDescription,
          text: this.customEntryText
        }
      )
      this.customEntryDescription = ''
      this.customEntryText = ''
    },
    removeCustomEntry (i) {
      this.custom.splice(i, 1)
    },
    fileUploaded (newFile) {
      const hitIndex = this.picsToUpload.findIndex((file) => { return (file.id && (file.id === newFile.id)) })
      if (hitIndex === -1) {
        this.picsToUpload.push({
          name: newFile.name,
          id: newFile.id
        })
      } else {
        // Update name of existing file
        this.picsToUpload[hitIndex].name = newFile.name
      }
    },
    fileRemoved (file) {
      // Check temporary files first
      const indexHitTemp = this.picsToUpload.findIndex((value) => { return (value.id && (value.id === file.id)) })
      if (indexHitTemp !== -1) {
        this.picsToUpload.splice(indexHitTemp, 1)
        return
      }
      // Check existing files next
      const indexHitExist = this.pics.findIndex((value) => { return (value.id && (value.id === file.id)) })
      if (indexHitExist !== -1) {
        this.pics.splice(indexHitExist, 1)
      }
    },
    async saveEvent (confirmed) {
      this.processingSave = true
      let needUpdateConfirmation
      let needCreateConfirmation
      if (this.event) {
        needCreateConfirmation = false
        needUpdateConfirmation = this.needUpdateConfirmation()
      } else {
        needUpdateConfirmation = false
        needCreateConfirmation = this.needCreateConfirmation()
      }
      // Check if confirmation will be neccessary (update)
      if (!confirmed) {
        if (needUpdateConfirmation) {
          if (this.checkDirty()) {
            this.processingSave = false
            this.showUpdateConfirmationDialog = true
            return
          }
        }
      }
      // Remove existing pics
      if (this.event) {
        const temporaryPics = this.event.pics
        const picNames = this.pics.map((pic) => { return pic.name })
        for (const existingPic of this.event.pics) {
          if (!picNames.includes(existingPic)) {
            const index = temporaryPics.indexOf(existingPic)
            temporaryPics.splice(index, 1)
            try {
              await this.uploadService.remove({ fileName: existingPic, path: 'pics/' + this.organisation + '/events' })
              await this.patchEvent([
                this.event._id,
                {
                  pics: temporaryPics
                },
                {}
              ])
            } catch (e) {
              this.createLog({ type: 'error', text: 'Remove event pic: ' + e })
              this.processingSave = false
              this.showSnackbar({ mode: 'save', success: false })
              return
            }
          }
        }
      }
      // Create new pic path array
      let picPaths = this.picsToUpload.map((file) => { return file.name })
      if (this.event) {
        picPaths = picPaths.concat(this.pics.map((file) => { return file.name }))
      }
      // Move files and remove corrupted (e.g. missing files)
      if (this.event && (this.event.organisation !== this.organisation)) {
        const corruptFileNames = await this.moveFiles()
        for (const corruptFileName of corruptFileNames) {
          const indexCorrupt = picPaths.indexOf(corruptFileName)
          if (indexCorrupt !== -1) {
            picPaths.splice(indexCorrupt, 1)
          }
        }
      }
      // Add data
      if (this.customEntryDescription && this.customEntryText) {
        this.addCustomEntry()
      }
      // Patch files (move)
      for (let i = 0; i < this.picsToUpload.length; i++) {
        try {
          await this.uploadService.patch(
            this.picsToUpload[i].name,
            {
              oldFilePath: 'temporary',
              newFilePath: 'pics/' + this.organisation + '/events/',
              newFileName: this.picsToUpload[i].name
            }
          )
        } catch (e) {
          this.createLog({ type: 'error', text: 'Move event pics: ' + e })
        }
      }
      // Create map
      const map = {
        title: this.title,
        subTitle: this.subTitle,
        bookingInformation: this.bookingInformation,
        info: this.info,
        isBookable: this.isBookable || false,
        bookingStart: this.bookingStart,
        bookingEnd: this.bookingEnd,
        pics: picPaths,
        time: {
          start: this.$moment(this.$moment().format('YYYY-MM-DD') + ' ' + this.startTime).toISOString(),
          end: this.$moment(this.$moment().format('YYYY-MM-DD') + ' ' + this.endTime).toISOString()
        },
        interval: {
          start: this.$moment(this.startInterval).toISOString(),
          end: this.$moment(this.endInterval).toISOString()
        },
        age: {
          from: this.ageRange[0],
          until: this.ageRange[1]
        },
        categories: this.categories,
        registration: this.registration,
        accessible: this.accessible || false,
        educational: this.educational || false,
        genderTarget: this.genderTarget || false,
        location: this.location,
        seats: this.seats,
        price: this.price,
        contact: this.contact,
        tags: this.tags,
        internal: this.internal || false,
        custom: this.custom
      }
      if (this.discounts) {
        map.discounts = this.discounts
      }
      if (this.isScheduled) {
        map.schedule = this.computedRule.toString()
        map.duration = this.duration
      } else {
        map.date = {
          start: this.$moment(this.startDate + ' ' + this.startTime).toISOString(),
          end: this.$moment(this.endDate + ' ' + this.endTime).toISOString()
        }
        map.schedule = null
      }
      // Accept
      if (this.ac.can(this.user.role).updateAny('event').granted) {
        map.isAccepted = this.isAccepted || false
      }
      // Send to server
      if (this.event) {
        if (this.event.organisation !== this.organisation) {
          map.organisation = this.organisation
        }
        if (this.event.author !== this.authorId) {
          map.author = this.authorId
        }
        try {
          await this.patchEvent([
            this.event._id,
            map,
            {}
          ])
        } catch (e) {
          this.createLog({ type: 'error', text: 'Patch event: ' + e + ', map: ' + JSON.stringify(map) + ',  user: ' + JSON.stringify(this.user) })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      } else {
        map.organisation = this.organisation
        map.author = this.authorId
        try {
          await this.createEvent(map)
        } catch (e) {
          this.createLog({ type: 'error', text: 'Create event: ' + e })
          this.processingSave = false
          this.showSnackbar({ mode: 'save', success: false })
          return
        }
      }

      this.processingSave = false
      // Check if confirmation will be neccessary (create)
      if (needCreateConfirmation) {
        this.showNewConfirmationDialog = true
      } else {
        this.showSnackbar({ mode: 'save', success: true })
        this.$closeEditor(this.$route, this.$router)
      }
    },
    async deleteEvent () {
      this.processingDelete = true
      for (const existingPic of this.event.pics) {
        try {
          await this.uploadService.remove({ fileName: existingPic, path: 'pics/' + this.organisation + '/events' })
        } catch (e) {
          this.createLog({ type: 'error', text: 'Delete event: ' + e })
          this.processingDelete = false
          this.showSnackbar({ mode: 'delete', success: false })
          return
        }
      }
      try {
        await this.removeEvent([
          this.event._id,
          {}
        ])
      } catch (e) {
        this.createLog({ type: 'error', text: 'Remove event: ' + e })
        this.processingDelete = false
        this.showSnackbar({ mode: 'delete', success: false })
        return
      }
      this.processingDelete = false
      this.showDeleteDialog = false
      this.showSnackbar({ mode: 'delete', success: true })
      this.$router.push('/veranstaltungen/')
    },
    openEditor () {
      this.$router.push({ params: { id: 'neu' } })
    },
    validateDatePicker (newValue, oldValue) {
      if (this.isScheduled) {
        this.$refs.timeFormScheduled1.validate()
        this.$refs.timeFormScheduled2.validate()
      } else {
        this.$refs.timeFormUnScheduled.validate()
      }
      this.$refs.timeForm.validate()
    },
    checkDirty () {
      if (this.event) {
        if (this.event.title !== this.title) return true
        if (this.event.subTitle !== this.subTitle) return true
        if (this.event.info !== this.info) return true
        if (!this.arraysEqual(this.event.pics, this.pics)) return true
        if (this.picsToUpload.length !== 0) return true
      }
      return false
    },
    needUpdateConfirmation () {
      if (this.event && this.event.isAccepted === false) {
        return false
      }
      if (!this.ac.can(this.user.role).updateAny('event').granted) {
        if (this.ac.can(this.user.role).updateOwn('event').granted) {
          if (this.event.organisation === this.user.organisation) {
            const userOrganisation = this.getOrganisation(this.user.organisation)
            if (userOrganisation) {
              if (userOrganisation.type === 'sjr') return false
              if (userOrganisation.type === 'cooperation') return false
              if (userOrganisation.type === 'institution') return false
            }
          }
        }
        return true
      }
      return false
    },
    needCreateConfirmation () {
      if (!this.ac.can(this.user.role).createAny('event').granted) {
        if (this.ac.can(this.user.role).createOwn('event').granted) {
          if (this.organisation === this.user.organisation) {
            const userOrganisation = this.getOrganisation(this.user.organisation)
            if (userOrganisation) {
              if (userOrganisation.type === 'sjr') return false
              if (userOrganisation.type === 'cooperation') return false
              if (userOrganisation.type === 'institution') return false
            }
          }
        }
        return true
      }
      return false
    },
    arraysEqual (a, b) {
      if (a === b) return true
      if (a === null || b === null) return false
      if (a.length !== b.length) return false

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
      }
      return true
    },
    async moveFiles () {
      const corruptFileNames = []
      if (this.event && (this.event.organisation !== this.organisation)) {
        for (const existingPic of this.event.pics) {
          try {
            const result = await this.uploadService.patch(
              existingPic,
              {
                oldFilePath: 'pics/' + this.event.organisation + '/events',
                newFilePath: 'pics/' + this.organisation + '/events',
                newFileName: existingPic
              }
            )
            if (!result) {
              corruptFileNames.push(existingPic)
            }
          } catch (e) {
            this.createLog({ type: 'error', text: 'Move event pics: ' + e })
            corruptFileNames.push(existingPic)
          }
        }
      }
      return corruptFileNames
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac',
      'eventCategories'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('users', {
      users: 'list'
    }),
    ...mapGetters('organisations', {
      organisations: 'list',
      getOrganisation: 'get'
    }),
    computedRule () {
      const options = {
      }
      if (this.startInterval) { options.dtstart = this.$moment(this.startInterval).toDate() } else { return null }
      if (this.startInterval && this.startTime) { options.dtstart = this.$moment(this.startInterval + ' ' + this.startTime).toDate() }
      if (this.endInterval) { options.until = this.$moment(this.endInterval).toDate() } else { return null }
      if (this.endInterval && this.endTime) { options.until = this.$moment(this.endInterval + ' ' + this.endTime).toDate() } else { return null }
      if (this.freq) { options.freq = this.freq } else { return null }
      if (this.interval) { options.interval = this.interval } else { return null }
      if (this.byweekday && this.byweekday.length > 0) { options.byweekday = this.byweekday }
      if (this.bymonth && this.bymonth.length > 0) { options.bymonth = this.bymonth }
      if (this.bymonthday) { options.bymonthday = this.bymonthday }
      if (this.byyearday) { options.byyearday = this.byyearday }
      return new RRule(options)
    },
    computedStartDate () {
      return this.startDate ? this.$moment(this.startDate).format('dddd, DD.MM.YYYY') : ''
    },
    computedEndDate () {
      return this.endDate ? this.$moment(this.endDate).format('dddd, DD.MM.YYYY') : ''
    },
    computedStartInterval () {
      return this.startInterval ? this.$moment(this.startInterval).format('dddd, DD.MM.YYYY') : ''
    },
    computedEndInterval () {
      return this.endInterval ? this.$moment(this.endInterval).format('dddd, DD.MM.YYYY') : ''
    },
    computedValidation () {
      const hasPics = (this.pics.length > 0) || (this.picsToUpload.length > 0)
      if (this.isScheduled) {
        return this.mainFormValid && this.timeFormScheduledValid1 && this.timeFormScheduledValid2 && this.timeFormValid && hasPics
      } else {
        return this.mainFormValid && this.timeFormUnScheduledValid && this.timeFormValid && hasPics
      }
    },
    computedServerUri () {
      return process.env.VUE_APP_SERVER_URL
    },
    computedExistingFiles () {
      const existingFiles = []
      if (this.event) {
        for (let i = 0; i < this.pics.length; i++) {
          existingFiles.push(
            {
              name: this.pics[i].name,
              id: this.pics[i].id,
              filePath: '/pics/' + this.event.organisation + '/events/'
            }
          )
        }
      }
      return existingFiles
    }
  },
  watch: {
    event () {
      this.adapt()
    },
    computedRule () {
      if (this.computedRule) {
        if (!this.firstLoad || !this.event) {
          const sourceText = this.computedRule.toText()
          this.createTranslation({ text: sourceText })
            .then((result) => {
              this.translatedRule = result.translatedText + '.'
              this.translatedRule = this.translatedRule.charAt(0).toUpperCase() + this.translatedRule.slice(1)
            })
        }
        this.firstLoad = false
      }
    },
    showEditor () {
      this.adapt()
    },
    startDate () {
      if (this.startDate && !this.endDate) {
        this.endDate = this.startDate
      }
      this.validateDatePicker()
    },
    endDate () {
      this.validateDatePicker()
    },
    startTime () {
      this.validateDatePicker()
    },
    endTime () {
      this.validateDatePicker()
    },
    startInterval () {
      this.validateDatePicker()
    },
    endInterval () {
      this.validateDatePicker()
    },
    isScheduled () {
      this.validateDatePicker()
    },
    info () {
      if (this.info) {
        this.info = this.$sanitize(this.info)
      }
    },
    bookingInformation () {
      if (this.bookingInformation) {
        this.bookingInformation = this.$sanitize(this.bookingInformation)
      }
    }
  }
}
</script>
