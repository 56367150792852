<template>
  <div>
    <v-toolbar
      flat
      color="#f5f7fa"
    >
      <span
        class="section-title primary--text"
        cols="6"
      >
        STIMMBERECHTIGTE
      </span>
    </v-toolbar>
    <v-container fluid>
      <v-row dense>
        <v-col class="text-right">
          <v-btn
            text
            :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
            @click="showFilters = true"
          >
            FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
          </v-btn>
          <v-btn
            v-if="computedFiltersActive > 0"
            icon
            color="secondary"
            @click="resetFilters()"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Suche"
            :color="search === '' ? 'secondary' : 'primary'"
            class="text-field-search"
            hide-details
            v-model="search"
            clearable
          >
            <template slot="append">
              <v-icon
                v-if="search === ''"
                class="m-3"
                small
                color="secondary"
              >
                fa-search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div ref="votersEditorContainer">
      <VoterEditor
        v-show="
          ac &&
          user &&
          (
            ac.can(user.role).createAny('voter').granted
          )
        "
        ref="voterEditor"
        :showEditor="showEditor"
      />
    </div>
    <v-container fluid>
      <template v-for="(voter, i) in computedFilteredVoters">
      <v-row :ref="voter._id" :key="i + '_info'" dense class="mt-3">
        <v-col align-self="center" cols="12" md="5">
          <b class="mr-3">{{voter.name}}</b>
          <v-chip
            v-if="!voter.isActive"
            outlined
            color="error"
          >
            INAKTIV
          </v-chip>
          <v-btn
            icon
            color="secondary"
            @click="$router.push('/stimmberechtigte/editor/' + voter._id)"
            v-if="!$route.params.id"
            class="mb-1"
          >
            <v-icon small>fas fa-edit</v-icon>
          </v-btn>
        </v-col>
        <v-col align-self="center" cols="12" md="5">
          {{voter.email}}
        </v-col>
        <v-col align-self="center" cols="12" md="2">
          {{voter.votes}} Delegierte
        </v-col>
      </v-row>
      <v-divider class="mt-4" :key="i + '_divider'" v-if="!$vuetify.breakpoint.mdAndUp && i < voters.length - 1"></v-divider>
      </template>
    </v-container>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <v-checkbox
                dense
                color="secondary"
                label="Aktive ausblenden"
                v-model="filterActive"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-checkbox
                dense
                color="secondary"
                label="Archivierte einblenden"
                v-model="filterArchived"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VoterEditor from '@/components/VoterEditor'

export default {
  name: 'Voters',
  components: {
    VoterEditor
  },
  data () {
    return {
      showEditor: false,
      search: '',
      showFilters: false,
      filterArchived: false,
      filterActive: false
    }
  },
  mounted () {
    this.adaptParams()
    this.findVoters()
  },
  methods: {
    ...mapActions('voters', {
      findVoters: 'find'
    }),
    openEditor (voter) {
      this.showEditor = true
      if (voter) {
        this.$refs[voter._id][0].parentNode.insertBefore(this.$refs.voterEditor.$el, this.$refs[voter._id][0].nextSibling)
      }
      setTimeout(() => {
        this.$vuetify.goTo(this.$refs.voterEditor.$el, { offset: 60 })
      }, 100)
    },
    closeEditor () {
      this.showEditor = false
      this.$refs.votersEditorContainer.parentNode.insertBefore(this.$refs.voterEditor.$el, this.$refs.votersEditorContainer.nextSibling)
    },
    adaptParams () {
      if (this.$route.params.id) {
        this.openEditor(this.getVoter(this.$route.params.id))
      } else {
        this.closeEditor()
      }
    },
    resetFilters () {
      this.filterArchived = false
      this.filterActive = false
    }
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('voters', {
      voters: 'list',
      getVoter: 'get'
    }),
    computedFiltersActive () {
      let count = 0
      if (this.filterArchived) { ++count }
      if (this.filterActive) { ++count }
      return count
    },
    computedFilteredVoters () {
      return this.$sortByProperty(
        this.voters
          .filter(obj => {
            if (this.filterArchived === false && this.filterActive === false) {
              if (obj.isActive === true) {
                return true
              } else {
                return false
              }
            } else if (this.filterArchived === true && this.filterActive === false) {
              return true
            } else if (this.filterArchived === false && this.filterActive === true) {
              return false
            } else if (this.filterArchived === true && this.filterActive === true) {
              if (obj.isActive) {
                return false
              } else {
                return true
              }
            } else {
              return false
            }
          })
          .filter(obj =>
            (this.search === '' || !this.search || obj.name.toLowerCase().includes(this.search.toLowerCase()))
          ), 'name'
      )
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    user () {
      this.findVoters()
    }
  }
}
</script>

<style>
  .v-item--active {
    color: #31aec0 !important;
  }
</style>
