<template>
  <v-container
    class="bg ma-0 pa-0"
    fill-height
    fluid
    @click="state < 2 ? state++ : state"
    style="background-image:url(/img/nav_1_x2.jpg)"
  >
    <v-overlay
      :value="state === 2"
      absolute
      z-index="0"
      opacity="0.46"
      color="primary"
    >
    </v-overlay>
    <v-row
      class="discenter white--text display-4 font-weight-bold clickable"
      :class="$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-6 '"
      gradient="rgba(0,0,0,0.4)"
    >
      <v-slide-y-reverse-transition hide-on-leave>
        <v-col
          v-if="state === 0"
          cols="3"
          class="menu-intro-item"
          :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
        >
          MACH DOCH WAS DU WILLST!
        </v-col>
      </v-slide-y-reverse-transition>
      <v-slide-y-reverse-transition hide-on-leave>
        <v-col
          v-if="state === 1"
          cols="3"
          class="menu-intro-item"
          :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"

        >
          ALLES WAS DU WILLST!
        </v-col>
      </v-slide-y-reverse-transition>
      <v-slide-y-reverse-transition hide-on-leave>
        <v-row
          style="z-index: 1"
          v-if="state === 2"
          class="ma-0 pa-0 font-weight-bold"
          :class="$vuetify.breakpoint.smAndUp ? 'display-2' : 'display-1'"
        >
          <v-col>
            <v-btn
              v-if="$route.path !== '/'"
              @click="setShowNav(false)"
              absolute
              top
              right
              :class="$vuetify.breakpoint.smAndUp ? 'ma-3' : 'ma-0'"
              x-large
              dark
              icon
            >
              <v-icon :size="$vuetify.breakpoint.smAndUp ? 40 : 26">fas fa-times</v-icon>
            </v-btn>
          </v-col>
          <v-col @click="nav('/neuigkeiten')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
            >
              NEUIGKEITEN
            </span>
          </v-col>
          <v-col @click="nav('/veranstaltungen')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
            >
              VERANSTALTUNGEN
            </span>
          </v-col>
          <v-col @click="nav('/wer-wir-sind')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
            >
              WER WIR SIND
            </span>
          </v-col>
          <v-col @click="nav('/was-wir-machen')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
            >
              WAS WIR MACHEN
            </span>
          </v-col>
          <v-col @click="nav('/mitglieder')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
            >
              UNSERE MITGLIEDER
            </span>
          </v-col>
          <v-col @click="nav('/mitmachen')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
              >
              MITMACHEN
            </span>
          </v-col>
          <v-col @click="nav('/kontakt')" cols="12">
            <span
              class="menu-item font-black"
              :class="$vuetify.breakpoint.smAndUp ? '' : 'mobile'"
            >
              KONTAKT
            </span>
          </v-col>
          <v-col class="mt-12" cols="12">
            <v-footer
              v-if="state === 2"
              class="pa-0 mt-6  "
              color="transparent"
              dark
            >
              <v-row>
                <v-col cols="12" class="py-0 footer-title">
                  STADTJUGENDRING ULM E.V.
                </v-col>
                <v-col cols="12" class="footer-item info--text">
                  <span :class="!$vuetify.breakpoint.smAndUp ? 'clickable' : 'clickable mr-3'" @click="$router.replace({ path: '/neuigkeiten', query: { imprint: true }})">IMPRESSUM</span>
                  <span class="" v-if="!$vuetify.breakpoint.smAndUp">&nbsp;/&nbsp;</span>
                  <span class="clickable mr-3" @click="$router.replace({ path: '/neuigkeiten', query: { privacy: true }})">DATENSCHUTZ</span>
                  <br v-if="!$vuetify.breakpoint.smAndUp">
                  <span class="clickable mr-3" @click="$router.replace({ path: '/neuigkeiten', query: { downloads: true }})">DOWNLOADS</span>
                  <br v-if="!$vuetify.breakpoint.smAndUp">
                  <template v-if="!isAuthenticated">
                    <span class="clickable" @click="$router.replace({ path: '/neuigkeiten', query: { login: true } })" small text>LOGIN</span>
                  </template>
                  <template v-else>
                    <span class="clickable" @click="initLogout()" small text>LOGOUT</span>
                  </template>
                </v-col>
              </v-row>
            </v-footer>
          </v-col>
        </v-row>
      </v-slide-y-reverse-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Nav',
  data () {
    return {
      state: 2,
      introInterval: null
    }
  },
  mounted () {
    this.checkStartInterval()
  },
  methods: {
    ...mapMutations({
      setShowNav: 'SET_SHOW_NAV',
      setIntro: 'SET_INTRO'
    }),
    nav (route) {
      this.$router.push(route)
      setTimeout(() => {
        this.setShowNav(false)
      }, 1000)
    },
    startInterval () {
      this.state = 0
      this.introInterval = setInterval(() => {
        if (this.state < 2) {
          this.state++
        } else {
          clearInterval(this.introInterval)
          this.introInterval = null
        }
      }, 2500)
    },
    checkStartInterval () {
      if (this.showNav && this.$route.path === '/' && this.intro) {
        if (!this.introInterval) {
          this.startInterval()
        }
      } else {
        this.state = 2
      }
    }
  },
  computed: {
    ...mapGetters([
      'showNav',
      'intro'
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
      'user'
    ])
  },
  watch: {
    showNav () {
      this.checkStartInterval()
    },
    '$route.path' () {
      this.checkStartInterval()
    }
  }
}
</script>

<style scoped>
.menu-item {
  opacity: 0.8;
}
.menu-item:hover {
  opacity: 1;
}

.bg {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat
}

</style>
