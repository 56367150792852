<template>
  <div>
    <v-toolbar
      flat
      color="primary"
      v-if="
        ac &&
        user &&
        ac.can(user.role).createAny('admin').granted
      "
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="$router.push('/nutzer/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>Neuer Nutzer</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="info"
        v-if="showEditor"
        @click="$closeEditor($route, $router)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
      <v-sheet
        v-show="showEditor"
        class="pa-5"
        tile
        color="primary"
      >
        <ProfileEditor
          class="mb-5"
        />
      </v-sheet>
  </div>
</template>

<script>
import ProfileEditor from '@/components/ProfileEditor.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'UserEditor',
  props: [
    'showEditor'
  ],
  components: {
    ProfileEditor
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    })
  }
}
</script>
