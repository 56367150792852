const state = {
  s3: process.env.VUE_APP_S3_URL,
  ac: undefined,
  rules: {
    noSpecials: value => /^[a-zA-Z0-9-_.]+$/.test(value) || 'No special characters allowed',
    required: value => !!value || 'Pflichteingabe.',
    radio: value => value !== null || 'Pflichteingabe.',
    content: value => (!value || value.length < 0) || 'Pflichteingabe',
    shortText: value => (!value || value.length <= 200) || 'Maximal 200 Zeichen.',
    longText: value => (!value || value.length <= 1000) || 'Maximal 1000 Zeichen.',
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (!value || pattern.test(value)) || 'Kein gültiges Email-Format'
    },
    password: value => (value && value.length >= 8 && value.length <= 16) || 'Erlaubte Passwörter haben eine Länge von 8-16 Zeichen und keine "*"',
    minimumAge: value => (!value || (parseInt(value) >= 0)),
    maximumAge: value => (!value || (parseInt(value) <= 99)),
    positive: value => (!value || (value >= 0)) || 'Negative Eingabe ungültig.'
  },
  eventCategories: [
    { value: 'games_sports', text: 'Spiel und Sport' },
    { value: 'nature_environment', text: 'Natur und Umwelt' },
    { value: 'media_tech', text: 'Medien und Technik' },
    { value: 'language_culture', text: 'Sprache und Kultur' },
    { value: 'society_history', text: 'Gesellschaft und Geschichte' },
    { value: 'politics_religion', text: 'Politik und Religion' },
    { value: 'concerts_music', text: 'Konzerte und Musik' },
    { value: 'camps', text: 'Freizeiten und Camps' },
    { value: 'education_help', text: 'Bildungseinrichtungen und Hilfeorganisationen' },
    { value: 'social_inclusion', text: 'Soziales und Inklusion' }
  ],
  organisationCategories: [
    { value: 'games_sports', text: 'Spiel und Sport' },
    { value: 'nature_environment', text: 'Natur und Umwelt' },
    { value: 'media_tech', text: 'Medien und Technik' },
    { value: 'language_culture', text: 'Sprache und Kultur' },
    { value: 'society_history', text: 'Gesellschaft und Geschichte' },
    { value: 'politics_religion', text: 'Politik und Religion' },
    { value: 'concerts_music', text: 'Konzerte und Musik' },
    { value: 'camps', text: 'Freizeiten und Camps' },
    { value: 'education_help', text: 'Bildungseinrichtungen und Hilfeorganisationen' },
    { value: 'social_inclusion', text: 'Soziales und Inklusion' }
  ],
  tabNames: [
    { text: 'Neuigkeiten', value: 'news' },
    { text: 'Veranstaltungen', value: 'events' },
    { text: 'Team', value: 'team' },
    { text: 'Partner', value: 'partners' },
    { text: 'Freitext', value: 'custom' }
  ],
  show: false,
  showNav: false,
  showPrivacy: false,
  showImprint: false,
  showLogin: false,
  showDownloads: false,
  showErrorDialog: false,
  snackbarOpen: false,
  snackbarText: '',
  snackbarColor: '',
  init: true,
  intro: false
}

const getters = {
  tabNames: state => {
    return state.tabNames
  },
  s3: state => {
    return state.s3
  },
  rules: state => {
    return state.rules
  },
  ac: state => {
    return state.ac
  },
  showNav: state => {
    return state.showNav
  },
  showPrivacy: state => {
    return state.showPrivacy
  },
  showImprint: state => {
    return state.showImprint
  },
  showDownloads: state => {
    return state.showDownloads
  },
  showLogin: state => {
    return state.showLogin
  },
  showErrorDialog: state => {
    return state.showErrorDialog
  },
  show: state => {
    return state.show
  },
  eventCategories: state => {
    return state.eventCategories
  },
  organisationCategories: state => {
    return state.organisationCategories
  },
  snackbarOpen: state => {
    return state.snackbarOpen
  },
  snackbarText: state => {
    return state.snackbarText
  },
  snackbarColor: state => {
    return state.snackbarColor
  },
  intro: state => {
    return state.intro
  }
}

const mutations = {
  SET_INTRO: (state, msg) => {
    state.intro = msg
  },
  SET_ACCESS_CONTROL: (state, msg) => {
    state.ac = msg
  },
  SET_SHOW_NAV: (state, msg) => {
    state.showNav = msg
  },
  SET_SHOW_PRIVACY: (state, msg) => {
    state.showPrivacy = msg
  },
  SET_SHOW_IMPRINT: (state, msg) => {
    state.showImprint = msg
  },
  SET_SHOW_DOWNLOADS: (state, msg) => {
    state.showDownloads = msg
  },
  SET_SHOW_LOGIN: (state, msg) => {
    state.showLogin = msg
  },
  SET_SHOW: (state, msg) => {
    state.show = msg
  },
  SET_SHOW_ERROR_DIALOG: (state, msg) => {
    state.showErrorDialog = msg
  },
  SET_SNACKBAR_OPEN: (state, msg) => {
    state.snackbarOpen = msg
  },
  SET_SNACKBAR_TEXT: (state, msg) => {
    state.snackbarText = msg
  },
  SET_SNACKBAR_COLOR: (state, msg) => {
    state.snackbarColor = msg
  },
  SET_INIT: (state, msg) => {
    state.init = msg
  }
}

const actions = {
  showSnackbar (context, { mode, success }) {
    let snackbarText
    if (mode === 'archive') {
      snackbarText = 'Archivieren '
    } else if (mode === 'activate') {
      snackbarText = 'Aktivieren '
    } else if (mode === 'save') {
      snackbarText = 'Speichern '
    } else if (mode === 'invite') {
      snackbarText = 'Einladen '
    } else if (mode === 'delete') {
      snackbarText = 'Löschen '
    } else {
      return
    }
    if (success) {
      snackbarText += 'erfolgreich.'
      context.state.snackbarColor = 'success'
    } else {
      snackbarText += 'fehlgeschlagen.'
      context.state.snackbarColor = 'error'
    }
    context.state.snackbarText = snackbarText
    context.state.snackbarOpen = true
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
