<template>
  <div>
    <v-toolbar
      flat
      color="primary"
    >
      <v-btn
        text
        color="info"
        v-if="!showEditor"
        @click="$router.push($route.path + '/editor/neu')"
      >
        <v-icon
          small
          class="mr-3"
        >fas fa-plus</v-icon>
        <span>Neue Organisation</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="info"
        v-if="showEditor"
        @click="$closeEditor($route, $router)"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      v-show="showEditor"
      class="pa-5"
      tile
      color="primary"
    >
      <BaseEditor
        class="mb-5"
        :organisation="organisation"
        :showEditor="showEditor"
      />
      <MicrositeEditor
        v-if="organisation && (organisation.type === 'member' || organisation.type === 'institution')"
        class="mb-5"
        :organisation="organisation"
      />
      <AccountEditor
        v-if="organisation"
        class="mb-5"
        :organisation="organisation"
      />
    </v-sheet>
  </div>
</template>

<script>
import MicrositeEditor from '@/components/MicrositeEditor.vue'
import BaseEditor from '@/components/BaseEditor.vue'
import AccountEditor from '@/components/AccountEditor.vue'
export default {
  name: 'OrganisationEditor',
  props: [
    'organisation',
    'showEditor'
  ],
  components: {
    MicrositeEditor,
    BaseEditor,
    AccountEditor
  }
}
</script>
