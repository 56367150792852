import feathersClient, { makeServicePlugin, BaseModel } from '@/feathers-client'

class Rentals extends BaseModel {
}
// Required for $FeathersVuex plugin to work after production transpile.
Rentals.modelName = 'Rental'
const servicePath = 'rentals'
const servicePlugin = makeServicePlugin({
  Model: Rentals,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
