<template>
  <div>
    <v-toolbar
      flat
      color="#f5f7fa"
    >
      <span
        class="section-title primary--text"
        cols="6"
      >
        ABSTIMMUNGEN
      </span>
    </v-toolbar>
    <v-container fluid>
      <v-row dense>
        <v-col class="text-right">
          <v-btn
            text
            :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
            @click="showFilters = true"
          >
            FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
          </v-btn>
          <v-btn
            v-if="computedFiltersActive > 0"
            icon
            color="secondary"
            @click="resetFilters()"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Suche"
            :color="search === '' ? 'secondary' : 'primary'"
            class="text-field-search"
            hide-details
            v-model="search"
            clearable
          >
            <template slot="append">
              <v-icon
                v-if="search === ''"
                class="m-3"
                small
                color="secondary"
              >
                fa-search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div ref="votingsEditorContainer">
      <VotingEditor
        v-show="
          ac &&
          user &&
          (
            ac.can(user.role).createAny('voting').granted
          )
        "
        ref="votingEditor"
        :showEditor="showEditor"
      />
    </div>
    <v-container fluid>
      <v-row dense class="mt-3">
        <template>
          <v-col v-for="(voting, i) in computedFilteredVotings" :ref="voting._id" :key="i" cols="12" md="6">
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" class="pb-1 primary--text section-title">
                      {{voting.title}}
                      <v-chip
                        v-if="!voting.isActive"
                        color="error"
                        outlined
                        small
                        class="mb-1"
                      >
                        INAKTIV
                      </v-chip>
                      <v-btn
                        v-show="
                          ac &&
                          user &&
                          (
                            ac.can(user.role).createAny('voting').granted
                          )
                        "
                        icon
                        color="secondary"
                        @click="$router.push('/abstimmungen/editor/' + voting._id)"
                        v-if="!$route.params.id"
                        class="mb-1"
                      >
                        <v-icon small>fas fa-edit</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="py-0">ID {{voting._id}}</v-col>
                    <v-col cols="12" class="pt-1">Abstimmung bis einschließlich {{$moment(voting.dueDate).format('dddd, DD.MM.YYYY')}}</v-col>
                  </v-row>
                  <v-row v-if="voting.description">
                    <v-sheet
                      tile
                      class="ma-3 mb-5 pa-3 pb-0"
                      width="100%"
                      color="#eee"
                      v-html="$sanitize(voting.description)"
                    ></v-sheet>
                  </v-row>
                  <v-divider class="my-6"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <span class="mr-1 font-weight-bold">
                        Einladungen / Codes:
                      </span>
                    </v-col>
                    <v-col cols="10">
                      <v-progress-linear
                        :value="(voting.codes.filter(obj => obj.boardCode.sent).length / voting.codes.length) * 100"
                        height="30"
                        :color="voting.codes.filter(obj => obj.boardCode.sent).length === voting.codes.length ? 'success' : 'primary'"
                      >
                        <template v-slot:default="{}">
                          <span class="white--text">
                            {{voting.codes.filter(obj => obj.boardCode.sent).length}} / {{voting.codes.length}} Codes {{ $vuetify.breakpoint.smAndUp ? 'zur Delegierten-Wahl' : '' }} versendet
                          </span>
                        </template>
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="2" class="text-right">
                      <v-btn
                        icon
                        color="secondary"
                        @click="openDialog(voting, 'boardCodes')"
                      >
                        <v-icon>fa fa-info-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-progress-linear
                        :value="(sentDelegateCodes(voting) / allDelegateCodes(voting)) * 100"
                        height="30"
                        :color="sentDelegateCodes(voting) === allDelegateCodes(voting) ? 'success' : 'primary'"
                      >
                        <template v-slot:default="{}">
                          <span class="white--text">
                            {{sentDelegateCodes(voting)}} / {{allDelegateCodes(voting)}} {{ $vuetify.breakpoint.smAndUp ? 'Delegierte' : 'Deleg.' }} eingeladen
                          </span>
                        </template>
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="2" class="text-right">
                      <v-btn
                        icon
                        color="secondary"
                        @click="openDialog(voting, 'delegateCodes')"
                      >
                        <v-icon>fa fa-info-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-6"></v-divider>
                  <v-row>
                    <v-col cols="12" class="font-weight-bold">
                      Beschlussfähigkeit:
                    </v-col>
                    <v-col>
                      <v-progress-linear
                        :value="(submittedDelegateVotes(voting) / allDelegateCodes(voting)) * 100"
                        height="30"
                        :color="submittedDelegateVotes(voting) / allDelegateCodes(voting) >= 0.5 ? 'success' : 'error'"
                      >
                        <template v-slot:default="{}">
                          <span class="white--text">
                            {{submittedDelegateVotes(voting)}} / {{allDelegateCodes(voting)}} {{ $vuetify.breakpoint.smAndUp ? 'Delegierte haben abgestimmt' : 'Deleg. abgestimmt' }}
                          </span>
                        </template>
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                  <v-divider class="my-6"></v-divider>
                  <v-row>
                    <v-col cols="12" class="font-weight-bold">
                      Fragen:
                    </v-col>
                  </v-row>
                  <v-row v-for="question in voting.questions" :key="question.id">
                    <v-sheet
                      tile
                      width="100%"
                      color="#eee"
                      class="mx-3 mt-5 pa-3"
                    >
                      <v-col cols="12">{{question.title}}</v-col>
                      <v-col cols="21" v-if="question.description">{{question.description}}</v-col>
                      <template
                        v-for="(link, j) in question.links"
                      >
                        <v-col :key="j+'link'" class="body-1 py-0" align-self="center" cols="12">
                          <a target="_blank" :href="link.url">
                            <v-icon color="primary" x-small class="mr-1">fas fa-external-link-alt</v-icon>
                            {{link.text ? link.text : link.url}}
                          </a>
                        </v-col>
                      </template>
                      <v-col>
                        <v-progress-linear
                          :value="(proDelegateVotes(voting, question) / (contraDelegateVotes(voting, question) + proDelegateVotes(voting, question))) * 100"
                          height="30"
                          :color="(proDelegateVotes(voting, question) / (contraDelegateVotes(voting, question) + proDelegateVotes(voting, question))) * 100 > parseFloat(question.requirement) ? 'success' : 'red'"
                        >
                          <template v-slot:default="{}">
                            <span class="white--text">
                              <template v-if="contraDelegateVotes(voting, question) + proDelegateVotes(voting, question) > 0">
                              {{Math.round(((proDelegateVotes(voting, question) / (contraDelegateVotes(voting, question) + proDelegateVotes(voting, question)) * 100) + Number.EPSILON) * 1000) / 1000 }} von > {{parseFloat(question.requirement)}} % {{$vuetify.breakpoint.smAndUp ? 'erreicht' : ''}}
                              </template>
                              <template v-else>
                                Noch keine Stimme abgegeben
                              </template>
                            </span>
                          </template>
                        </v-progress-linear>
                      </v-col>
                    </v-sheet>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider class="mx-7 mt-3 mb-7"></v-divider>
              <template
                v-if="
                    ac &&
                    user &&
                    (
                      ac.can(user.role).createAny('excel').granted
                    )
                  "
              >
                <v-row
                  class="px-7 pb-6 pt-0"
                >
                  <v-col cols="12">
                    <v-btn
                      outlined
                      block
                      tile
                      color="secondary"
                      @click="getExcel(voting._id, 'voting')"
                    >
                      <v-icon
                        small
                        class="mr-3"
                      >
                        fas fa-download
                      </v-icon>
                      Excel-Datei
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-card>
            </v-col>
          <v-divider class="mt-4" :key="i + '_divider'" v-if="!$vuetify.breakpoint.mdAndUp && i < computedFilteredVotings.length - 1"></v-divider>
        </template>
      </v-row>
    </v-container>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-if="selectedVoting"
      v-model="selectedVoting"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="selectedVoting = undefined"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <template v-if="dialogMode === 'boardCodes'">
            <v-row>
              <v-col class="mb-4 primary--text section-title">Codes zur Delegierten-Wahl</v-col>
            </v-row>
            <v-row
              v-for="code in selectedVoting.codes"
              :key="code.code"
              dense
            >
              <v-col cols="12" md="4">
                <template v-if="selectedVoting.type === 'members'">
                  {{voters.find(obj => obj._id === code.organisation).name}} {{voters.find(obj => obj._id === code.organisation).isActive ? '' : ' (inaktiv)'}}
                </template>
                <template v-else-if="selectedVoting.type === 'custom'">
                  {{selectedVoting.custom.name}}
                </template>
              </v-col>
              <v-col cols="12" md="2">
                {{code.boardCode.code}}
              </v-col>
              <v-col cols="12" md="4">
                <template v-if="code.boardCode.sent">
                {{$moment(code.boardCode.sent).format('DD.MM.YYYY, HH:mm') + ' Uhr'}}
                </template>
                <template v-else-if="code.boardCode.error">
                  <span class="error-text">{{code.boardCode.error}}</span>
                </template>
                <template v-else>
                  Noch nicht gesendet
                </template>
              </v-col>
              <v-col :class="$vuetify.breakpoint.mdAndUp ? 'text-right': ''" cols="12" md="2">
                <v-btn
                  v-if="selectedVoting.type === 'members' ? voters.find(obj => obj._id === code.organisation).isActive : true"
                  icon
                  color="secondary"
                  @click="sendCode(code, selectedVoting)"
                  class="mb-1"
                  :disabled="sendingCode"
                  :loading="sendingCode && sendingCode === code.boardCode.code"
                >
                  <v-icon small>fa fa-paper-plane</v-icon>
                </v-btn>
                <span v-else>
                  Inaktiv
                </span>
              </v-col>
              <v-col class="py-3" v-if="!$vuetify.breakpoint.mdAndUp"></v-col>
            </v-row>
          </template>
          <template v-if="dialogMode === 'delegateCodes'">
            <v-row>
              <v-col class="mb-4 primary--text section-title">Delegierte</v-col>
            </v-row>
            <v-row
              v-for="code in selectedVoting.codes"
              :key="code._id"
              dense
            >
              <template v-for="delegate in code.delegateCodes">
                <template v-if="delegate.name && delegate.name !== ''">
                  <v-col :key="delegate.code + 'name'" cols="12" md="4">
                    {{delegate.name}}
                    <br>
                    <template v-if="selectedVoting.type === 'members'">
                      {{voters.find(obj => obj._id === code.organisation).name}}{{voters.find(obj => obj._id === code.organisation).isActive ? '' : ' (inaktiv)'}}
                    </template>
                  </v-col>
                </template>
                <template v-else>
                  <v-col :key="delegate.code + 'name'" cols="12" md="4">
                    Kein Name angegeben
                    <br>
                    <template v-if="selectedVoting.type === 'members'">
                      {{voters.find(obj => obj._id === code.organisation).name}}{{voters.find(obj => obj._id === code.organisation).isActive ? '' : ' (inaktiv)'}}
                    </template>
                  </v-col>
                </template>
                <template v-if="delegate.email && delegate.email !== ''">
                  <v-col :key="delegate.code + 'email'" cols="12" md="3">
                    {{delegate.email}}
                  </v-col>
                </template>
                <template v-else>
                  <v-col :key="delegate.code + 'email'" cols="12" md="3">
                    Keine Email-Adresse angegeben
                  </v-col>
                </template>
                <v-col :key="delegate.code + 'code'" cols="12" md="2">
                    {{delegate.code}}
                  </v-col>
                <v-col :key="delegate.code + 'date'" cols="12" md="3">
                  <template v-if="delegate.sent">
                    {{$moment(delegate.sent).format('DD.MM.YYYY, HH:mm') + ' Uhr'}}
                    <v-btn
                      icon
                      color="error"
                      @click="deleteCode(delegate.code, selectedVoting)"
                      class="mb-1"
                      :disabled="deletingCode"
                      :loading="deletingCode && deletingCode === delegate.code"
                    >
                      <v-icon small>fa fa-trash</v-icon>
                    </v-btn>
                  </template>
                  <template v-else-if="delegate.error">
                    <span class="error-text">{{delegate.error}}</span>
                  </template>
                  <template v-else>
                    Noch nicht gesendet
                  </template>
                </v-col>
                <v-col class="py-3" :key="delegate.code + 'divider'" v-if="!$vuetify.breakpoint.mdAndUp"></v-col>
              </template>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showEditorDialog"
      fullscreen
      v-if="
        ac &&
        user &&
        ac.can(user.role).createAny('voting').granted
      "
    >
      <VotingEditor
        :showEditor="showEditorDialog"
      />
    </v-dialog>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row dense>
            <v-col cols="12">
              <v-checkbox
                dense
                color="secondary"
                label="Aktive ausblenden"
                v-model="filterActive"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-checkbox
                dense
                color="secondary"
                label="Archivierte einblenden"
                v-model="filterArchived"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VotingEditor from '@/components/VotingEditor'

export default {
  name: 'Votings',
  components: {
    VotingEditor
  },
  data () {
    return {
      showEditorDialog: false,
      selectedVoting: undefined,
      sendingCode: undefined,
      dialogMode: 'boardCodes',
      deletingCode: undefined,
      filterActive: false,
      filterArchived: false,
      search: '',
      showFilters: false
    }
  },
  mounted () {
    this.adaptParams()
    this.findVotings()
  },
  methods: {
    ...mapActions('votings', {
      findVotings: 'find'
    }),
    ...mapActions('votes', {
      sendVote: 'create',
      removeVote: 'remove'
    }),
    ...mapActions('excel', {
      createExcel: 'create'
    }),
    resetFilters () {
      this.filterArchived = false
      this.filterActive = false
    },
    openDialog (voting, mode) {
      this.selectedVoting = voting
      this.dialogMode = mode
    },
    async sendCode (code, voting) {
      this.sendingCode = code.boardCode.code
      const map = {
        voting: voting._id,
        code: code.boardCode.code
      }
      if (voting.type === 'members') {
        map.email = this.voters.find(obj => obj._id === code.organisation).email
        map.name = this.voters.find(obj => obj._id === code.organisation).name
      } else if (voting.type === 'custom') {
        map.email = voting.custom.email
        map.name = voting.custom.name
      }
      try {
        await this.sendVote(map)
      } catch (e) {
        console.log(e)
      }
      await this.findVotings()
      this.sendingCode = undefined
    },
    async deleteCode (code, voting) {
      this.deletingCode = code
      try {
        await this.removeVote([voting._id, { delegateCode: code }])
      } catch (e) {
        console.log(e)
      }
      await this.findVotings()
      this.deletingCode = undefined
    },
    async getExcel (voting, type) {
      const result = await this.createExcel({ type, voting })
      const blob = new Blob(
        [result.sheet],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      )
      this.downloadBlob(blob, voting + '_' + this.$moment().format('YYYY-MM-DD_hh-mm') + '.xlsx')
    },
    downloadBlob (blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename || 'download'
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.removeEventListener('click', clickHandler)
        }, 150)
      }
      a.addEventListener('click', clickHandler, false)
      a.click()
    },
    openEditor (voting) {
      this.showEditorDialog = true
    },
    closeEditor () {
      this.showEditorDialog = false
    },
    adaptParams () {
      if (this.$route.params.id) {
        this.openEditor(this.getVoting(this.$route.params.id))
      } else {
        this.closeEditor()
      }
    },
    proDelegateVotes (voting, question) {
      let sum = 0
      for (const element of voting.codes) {
        for (const delegate of element.delegateCodes) {
          sum += delegate.vote?.values.filter(obj => obj.question === question._id && obj.value === 1).length || 0
        }
      }
      return sum
    },
    contraDelegateVotes (voting, question) {
      let sum = 0
      for (const element of voting.codes) {
        for (const delegate of element.delegateCodes) {
          sum += delegate.vote?.values.filter(obj => obj.question === question._id && obj.value === 0).length || 0
        }
      }
      return sum
    },
    submittedDelegateVotes (voting) {
      let sum = 0
      for (const element of voting.codes) {
        sum += element.delegateCodes.filter(obj => obj.vote?.date).length
      }
      return sum
    },
    sentDelegateCodes (voting) {
      let sum = 0
      for (const element of voting.codes) {
        sum += element.delegateCodes.filter(obj => obj.sent).length
      }
      return sum
    },
    allDelegateCodes (voting) {
      let sum = 0
      for (const element of voting.codes) {
        sum += element.delegateCodes.length
      }
      return sum
    }
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('votings', {
      votings: 'list',
      getVoting: 'get'
    }),
    ...mapGetters('voters', {
      voters: 'list'
    }),
    computedFiltersActive () {
      let count = 0
      if (this.filterArchived) { ++count }
      if (this.filterActive) { ++count }
      return count
    },
    computedFilteredVotings () {
      return this.$sortByProperty(
        this.votings
          .filter(obj => {
            if (this.filterArchived === false && this.filterActive === false) {
              if (obj.isActive === true) {
                return true
              } else {
                return false
              }
            } else if (this.filterArchived === true && this.filterActive === false) {
              return true
            } else if (this.filterArchived === false && this.filterActive === true) {
              return false
            } else if (this.filterArchived === true && this.filterActive === true) {
              if (obj.isActive) {
                return false
              } else {
                return true
              }
            } else {
              return false
            }
          })
          .filter(obj =>
            (this.search === '' || !this.search || obj.title.toLowerCase().includes(this.search.toLowerCase()))
          ), 'createdAt'
      ).reverse()
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    user () {
      this.findVotings()
    }
  }
}
</script>

<style>
  .v-item--active {
    color: #31aec0 !important;
  }
</style>
