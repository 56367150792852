<template>
  <v-col
    cols="12"
    sm="6"
    md="3"
    class="px-4 pb-6"
  >
    <v-row
      no-gutters
      class="justify-center pb-6"
    >
      <v-img
        :src="imageSource"
        :aspect-ratio="4/3"
      >
        <template v-slot:placeholder>
          <v-sheet
            color="lightAccent"
            tile
            class="fill-height"
          >
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-icon size="120">fas fa-user</v-icon>
            </v-row>
          </v-sheet>
        </template>
      </v-img>
    </v-row>
    <v-row
      no-gutters
    >
      <span
        class="team-item-primary team-item-name primary--text"
      >
        {{fullName}}
      </span>
    </v-row>
    <v-row
      no-gutters
      v-if="showSpecialPosition"
      class="pb-1"
    >
      <span
        class="team-item-primary team-item-position secondaryAccent--text"
      >
        {{specialPosition}}
      </span>
    </v-row>
    <v-row
      no-gutters
      v-if="showPosition"
      class="pb-4"
    >
      <span
        class="team-item-primary team-item-position secondaryAccent--text"
      >
        {{position}}
      </span>
    </v-row>
    <v-row
      no-gutters
      v-if="showBodyPrimary"
      class="pb-4"
    >
      <span
        class="team-item-secondary primary--text"
      >
        {{bodyPrimary}}
      </span>
    </v-row>
    <v-row
      no-gutters
      v-if="showBodySecondary"
      class="pb-4"
    >
      <span
        class="team-item-secondary primary--text"
      >
        {{bodySecondary}}
      </span>
    </v-row>
    <v-row
      no-gutters
      v-if="showPhone"
    >
      <span
        class="team-item-secondary primary--text"
      >
        Telefon: {{phone}}
      </span>
    </v-row>
    <v-row
      no-gutters
      v-if="showMailAddress"
    >
      <a style="text-decoration:none" :href="'mailto:' + mailAddress">
        <span
          class="team-item-secondary team-item-mail secondary--text"
        >
            {{mailAddress}}
        </span>
      </a>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'PersonCol',
  props: {
    fullName: {
      type: String,
      required: true
    },
    imageSource: {
      type: String,
      required: true
    },
    specialPosition: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    bodyPrimary: {
      type: String,
      default: ''
    },
    bodySecondary: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    mailAddress: {
      type: String,
      default: ''
    }
  },
  computed: {
    showSpecialPosition () {
      return this.specialPosition !== ''
    },
    showPosition () {
      return this.position !== ''
    },
    showBodyPrimary () {
      return this.bodyPrimary !== ''
    },
    showBodySecondary () {
      return this.bodySecondary !== ''
    },
    showPhone () {
      return this.phone !== ''
    },
    showMailAddress () {
      return this.mailAddress !== ''
    }
  }
}
</script>
